import { EntityName, MessageTypeEnum, ProviderNumberTypeEnum } from '@shared/enums';
import { Auth0Role, DropdownEntityType, FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import * as yup from 'yup';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { dropdownSchema } from '../shared/Schema/yup';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultSystemNumberTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Phone Number',
      fieldName: 'phoneNumber',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.NUMBER,
      onColumnClick: columnClickEvents['phoneNumber'],
      renderColumn: columnRenders['phoneNumber'],
    },
    {
      headerName: 'TCR Campaign Id',
      fieldName: 'tcrCampaignId',
      fieldType: FilterDataTypeEnum.STRING
    },
    {
      headerName: 'Type',
      fieldName: 'type',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Client',
      fieldName: 'clientName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Client',
      fieldName: 'clientId',
      fieldType: FilterDataTypeEnum.TYPEAHEAD,
      dropdownType: DropdownEntityType.CLIENT,
      hidden: true
    },
    {
      headerName: 'Provider',
      fieldName: 'providerName',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Is Active',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
  ];
};

export interface ISystemNumberForm {
  phoneNumber?: number;
  providerId: string;
  client: IDropdownValue;
}

export const systemNumberFormSchema: yup.SchemaOf<ISystemNumberForm> = yup.object().shape({
  phoneNumber: yup
    .number()
    .test('len', 'Number must be 10 digits long', (num) => num?.toString().length === 10)
    .defined('Required')
    .typeError('Required'),
  providerId: yup.string().defined('Required'),
  client: dropdownSchema,
});

const skipFormattingEnumValues = ['GOTV'];

export const systemNumberTypeDropdownValues: IDropdownValue[] = Object.values(ProviderNumberTypeEnum)
  .filter((val) => typeof val === 'string')
  .map((val) => ({
    label: skipFormattingEnumValues.includes(val)
      ? val
      : val
        .split('_')
        .map((fragment) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
        .join(' '),
    value: val,
  }));

export const userTypeDropdownValues: IDropdownValue[] = Object.values(Auth0Role)
  .filter((val) => typeof val === 'string')
  .map((val) => ({
    label: skipFormattingEnumValues.includes(val)
      ? val
      : val
        .split('_')
        .map((fragment) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
        .join(' '),
    value: val,
  }));

export const messageTypeDropdownValues: IDropdownValue[] = Object.values(MessageTypeEnum)
  .filter((val) => typeof val === 'string')
  .map((val) => ({
    label: val,
    value: val,
  }));

export const systemNumberDropdownOptions: IFilterDropdownOptions = {
  type: systemNumberTypeDropdownValues,
  messageType: messageTypeDropdownValues,
  clientName: [],
  providerName: [],
};

export const defaultAreaCodeOptions: ISearchRequest = {
  entity: EntityName.AREA_CODES,
  filters: [],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'areaCode',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};
