import { ICampaignsListView, IGetAllCampaignsRequest, IHttpPaginatedResponse, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';

// TODO: Make hook for 'useEntitySearch', and keep hooks for one-offs
export default function useCampaigns(request: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<ICampaignsListView>> {
  const clientId = request?.clientId ?? getFilterValue(request, 'clientName');

  const params: IGetAllCampaignsRequest = {
    clientId,
    skip: request?.pagination?.skip ?? 0,
    take: request?.pagination?.take ?? 25,
    messageType: getFilterValue(request, 'messageType'),
    campaignStatus: getFilterValue(request, 'status'),
    name: getFilterValue(request, 'name')
  };

  return useAxios<IHttpPaginatedResponse<ICampaignsListView>>({
    url: `campaigns`,
    method: 'GET',
    params
  });
}
