import { FieldTypeEnum } from '@shared/enums';
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import * as yup from 'yup';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const getColumns = (columnRenders: any, columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Data Type',
      fieldName: 'fieldtype',
      fieldType: FilterDataTypeEnum.ENUM,
      renderColumn: columnRenders['fieldType'],
    },
    {
      headerName: 'Personalized',
      fieldName: 'personalized',
      fieldType: FilterDataTypeEnum.BOOLEAN
    },
    {
      headerName: 'Default Value',
      fieldName: 'defaultValue',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['defaultValue']
    }
  ];
};

export interface ICustomDataPointForm {
  name: string;
  fieldType: FieldTypeEnum;
  personalized: boolean;
  defaultValue?: string | null;
  isActive: boolean;
}

export const customDataPointFormSchema: yup.SchemaOf<ICustomDataPointForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  fieldType: yup.mixed<FieldTypeEnum>().oneOf(Object.values(FieldTypeEnum)).required('Required'),
  personalized: yup.boolean().required(),
  defaultValue: yup.string().when('personalized', {
    is: true,
    then: yup.string().required('Required'),
    otherwise: yup.string().nullable(),
  }),
  isActive: yup.boolean().required('Required'),
});

export const defaultCustomDataPointsTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};
