import { CampaignStatusEnum, MessageTypeEnum, S3BucketEnum } from '@shared/enums';
import { DropdownEntityType, FilterDataTypeEnum, ICampaignDetails, ICustomDataPointEntity, IHttpResponse, ISearchRequest, ISignedUrlRequest, SearchSortDirectionEnum } from '@shared/models';
import { extendObject, getCampaignEndDateForStartDate, getCampaignStartDate, getFileMimeType, getLocalDate } from '@shared/services';
import { getHours, getMinutes, getSeconds, setHours, setMinutes, setSeconds } from 'date-fns';
import { cloneDeep } from 'lodash';
import { RuleGroupTypeIC } from 'react-querybuilder';
import * as yup from 'yup';
import { axiosGet, getQueryParamString } from '../../authAxios';
import { isFileLocalSize, isFileMediaType } from '../../providers/validation.provider';
import { messageTypeDropdownValues } from '../SystemNumbers/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { IRadioButtonOption } from '../shared/Form/RadioButton';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultCampaignsTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export function getMessageTypeEnumOptions(): IDropdownValue[] {
  return Object.values(MessageTypeEnum)
    .map((status) => ({
      label: status,
      value: status,
    }));
}

export function getCampaignStatusEnumOptions(): IDropdownValue[] {
  return Object.values(CampaignStatusEnum)
    .map((status) => ({
      label: getCampaignStatusLabel(status),
      value: status,
    }));
}

function getCampaignStatusLabel(status: CampaignStatusEnum) {
  switch (status) {
    case CampaignStatusEnum.SCHEDULING:
      return 'Scheduling';
    case CampaignStatusEnum.SCHEDULING_FAILED:
      return 'Scheduling Failed';
    case CampaignStatusEnum.SCHEDULED:
      return 'Scheduled';
    case CampaignStatusEnum.SENDING:
      return 'Sending';
    case CampaignStatusEnum.SENT:
      return 'Sent';
    case CampaignStatusEnum.FAILED:
      return 'Failed';
    case CampaignStatusEnum.DELIVERED:
      return 'Delivered';
  }
}

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Title',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Message Type',
      fieldName: 'messageType',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Total Messages Scheduled',
      fieldName: 'totalMessagesScheduled',
      fieldType: FilterDataTypeEnum.NUMBER
    },
    {
      headerName: 'Health Check',
      fieldName: 'healthCheck',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['healthCheck'],
    },
    {
      headerName: 'Delivery Progress',
      fieldName: 'deliveredProgress',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['deliveredProgress'],
      description: 'Sent / Delivered / Failed',
    },
    {
      headerName: 'Start Date',
      fieldName: 'startsAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];
};

export const getAllColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Client',
      fieldName: 'clientName',
      fieldType: FilterDataTypeEnum.TYPEAHEAD,
      dropdownType: DropdownEntityType.CLIENT,
      isRowHeader: true
    },
    ...getColumns(columnClickEvents, columnRenders)
  ];
};


export const messageTypeOptions: IRadioButtonOption[] = [
  {
    label: 'SMS',
    value: MessageTypeEnum.SMS,
    description: 'Short Message Service.',
  },
  {
    label: 'MMS',
    value: MessageTypeEnum.MMS,
    description: 'Multimedia Messaging Service. This requires a media file upload.',
  },
];

export const clickTrackingOptions: IRadioButtonOption[] = [
  {
    label: 'Enabled',
    value: true,
    description: 'Enable click tracking.',
  },
  {
    label: 'Disabled',
    value: false,
    description: 'Disable click tracking.',
  },
];

export const getErrorColumns = (showColumn: boolean): IColumn[] => {
  const columns = [
    {
      headerName: 'Code',
      fieldName: 'code',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
    },
    {
      headerName: 'Count',
      fieldName: 'count',
      fieldType: FilterDataTypeEnum.STRING,
    },
    {
      headerName: 'Error Type',
      fieldName: 'errorType',
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];

  if (showColumn) {
    columns.push({
      headerName: 'Billable',
      fieldName: 'billing',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    });
  }

  return columns;
};

export const getTestMessageColumns = (request?: { showProviderErrors?: boolean; }): IColumn[] => {
  const columns = [
    {
      headerName: 'Number',
      fieldName: 'toNumber',
      fieldType: FilterDataTypeEnum.NUMBER,
      isRowHeader: true,
    },
    {
      headerName: 'Carrier',
      fieldName: 'carrierName',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    },
    {
      headerName: 'Status',
      fieldName: 'status',
      fieldType: FilterDataTypeEnum.ENUM,
      isRowHeader: false,
    },
    {
      headerName: 'Error Code',
      fieldName: 'errorCode',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    },
    {
      headerName: 'Error Type',
      fieldName: 'errorType',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    },
  ];

  if (request?.showProviderErrors) {
    columns.push({
      headerName: 'Provider Error Code',
      fieldName: 'providerErrorCode',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    });

    columns.push({
      headerName: 'Provider Error Type',
      fieldName: 'providerErrorType',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: false,
    });
  }

  return columns;
};

export interface ICampaignForm {
  name: string;
  message: string;
  messageType?: IRadioButtonOption;
  startsAt: Date;
  endsAt: Date;
  externalId?: string;
  mediaFile?: any;
  mediaName?: string;
  isMMSVideo?: boolean;
  mediaUrl?: string;
  sendTestMessages?: boolean;
  clickTrackingEnabled?: IRadioButtonOption;
  url?: string;
  domain?: string;
  audiences: IDropdownValue[];
  suppressions: IDropdownValue[];
  frontendInlineSuppression: RuleGroupTypeIC;
  backendInlineSuppression: any;
}

export function getCampaignFormSchema(customDataPoints: ICustomDataPointEntity[]): yup.SchemaOf<ICampaignForm> {
  return yup.object().shape({
    name: yup.string().defined('Required'),
    message: yup
      .string()
      .test('notContainsPipe', 'Message must not contain "|" symbol', (message) => !(message && message.includes('|')))
      .when('messageType', {
        is: (messageType: IDropdownValue) => messageType.value === MessageTypeEnum.MMS,
        then: yup.string().max(1600, 'Message cannot be longer than 1600 characters'),
        otherwise: yup.string().max(1000, 'Message cannot be longer than 1000 characters'),
      })
      .test('containsLink', 'Message must contain {{link}}', (message: string | undefined, context): boolean => {
        return context.parent.clickTrackingEnabled.value === 'true' && message ? message?.toLowerCase()?.trim()?.includes('{{link}}') : true;
      })
      .test('notContainsLink', 'Message must not contain {{link}}', (message: string | undefined, context): boolean => {
        return context.parent.clickTrackingEnabled.value === 'false' && message ? !message?.toLowerCase()?.trim()?.includes('{{link}}') : true;
      })
      .test('badCustomDataPoint', 'Message must not contain non-personalized custom data points', (message: string | undefined, context): boolean => {
        const validCustomDataPoints = getValidCustomDataPoints(customDataPoints);
        const messageCustomDataPoints = getMessageCustomDataPoints(message);
        const hasInvalidCustomDataPoint = !!messageCustomDataPoints?.find(messageCdp => !validCustomDataPoints?.includes(messageCdp));

        return !hasInvalidCustomDataPoint;
      })
      .defined('Required'),
    audiences: yup
      .array()
      .of(
        yup.object().shape({
          label: yup.string().defined('Required'),
          value: yup.mixed().defined('Required'),
          additionalData: yup.mixed(),
        })
      )
      .min(1, 'Pick at least 1 audience'),
    suppressions: yup.array().of(
      yup.object().shape({
        label: yup.string().defined(),
        value: yup.mixed().required('Required'),
        additionalData: yup.mixed(),
      })
    ),
    messageType: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string().required('Required'),
      })
      .nullable(),
    startsAt: yup
      .date()
      .typeError('Invalid Date')
      .defined('Required')
      .min(new Date(), 'Campaign cannot start in the past'),
    endsAt: yup
      .date()
      .typeError('Invalid Date')
      .defined('Required')
      .min(yup.ref('startsAt'), 'End date cannot be before Start date'),
    externalId: yup.string().required('Required'),
    mediaUrl: yup.string().when('messageType', {
      is: (messageType: IDropdownValue) => messageType.value === MessageTypeEnum.MMS,
      then: yup.string().defined('Required'),
    }),
    mediaName: yup.string(),
    isMMSVideo: yup.boolean(),
    mediaFile: yup.mixed().when('messageType', {
      is: (messageType: IDropdownValue) => messageType.value === MessageTypeEnum.MMS,
      then: yup
        .mixed()
        .required('Required')
        .test('fileTypeValidate', 'Uploaded file must be image or video', (file: File): boolean => isFileMediaType(file))
        .test('fileSize', 'File is too large. File must be smaller than 1mb', (file: File): boolean =>
          isFileLocalSize(file)
        ),
    }),
    sendTestMessages: yup.boolean(),
    clickTrackingEnabled: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string().required('Required'),
      })
      .nullable(),
    url: yup.string().when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: IRadioButtonOption) => clickTrackingEnabled.value === 'true',
      then: yup.string().url('Invalid URL format').defined('Required'),
    }),
    domain: yup.string().when('clickTrackingEnabled', {
      is: (clickTrackingEnabled: IRadioButtonOption) => clickTrackingEnabled.value === 'true',
      then: yup.string().defined('Required'),
    }),
    frontendInlineSuppression: yup.object(),
    backendInlineSuppression: yup.object(),
  });
}

function getValidCustomDataPoints(customDataPoints: ICustomDataPointEntity[]) {
  const result = [...new Set(customDataPoints?.filter(cdp => !!cdp?.name)?.map(cdp => cdp?.name?.toLowerCase()?.trim()) ?? [])];
  result.push('link');
  return result;
}

export function getMessageCustomDataPoints(message?: string) {
  const cdps: string[] = [];
  const results = [...message?.matchAll(new RegExp(`({{.*?}})`, 'gmi')) ?? []];

  for (const result of results) {
    const [match, ...groups] = result;
    cdps.push(...groups);
  }

  return [...new Set(cdps?.map(cdp => removeTemplate(cdp)) ?? [])];
}

function removeTemplate(value: string) {
  return toLowerCase(value.replace('{{', '').replace('}}', ''));
}


function toLowerCase(value: string) {
  return value?.toLowerCase()?.trim() ?? '';
}

export const campaignStatusDropdownValues: IDropdownValue[] = Object.keys(CampaignStatusEnum)
  .filter((val) => typeof val === 'string')
  .map((val) => ({
    label: (CampaignStatusEnum as any)[val]
      .split('_')
      .map((fragment: string) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
      .join(' '),
    value: val,
  }));

export const campaignFilterDropdownOptions: IFilterDropdownOptions = {
  messageType: messageTypeDropdownValues,
  status: campaignStatusDropdownValues,
  clientName: [],
};

export async function getClonedItem(item: ICampaignDetails): Promise<ICampaignDetails> {
  const copy = cloneDeep(item);
  const startsAt = getClonedStartsAt(copy);
  const endsAt = getCampaignEndDateForStartDate(getLocalDate(), startsAt);

  return extendObject(copy, {
    id: undefined,
    name: '',
    externalId: '',
    startsAt,
    endsAt,
    mediaUrl: copy.messageType === MessageTypeEnum.MMS ? (await regenerateS3Url(copy.mediaUrl, copy.mediaName)) : undefined,
    audiences: [],
    suppressions: [],
    frontendInlineSuppression: {
      rules: [],
      disabled: false,
    },
    backendInlineSuppression: {
      rules: [],
      disabled: false,
    },
  });
}

async function regenerateS3Url(mediaUrl: string, mediaName: string) {
  // if existing media url includes s3 mms bucket upload then it is an internal s3 url that needs to be regenerated
  if (mediaUrl.includes(S3BucketEnum.MMS)) {
    // derive uuid from original s
    const mediaFileKey = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}/.exec(mediaUrl)?.[0] ?? '';
    // determine file type from mediaName
    const fileType = getFileMimeType(mediaName);
    // get new signed url
    const s3MediaUrlRequest: ISignedUrlRequest = {
      bucket: S3BucketEnum.MMS,
      key: mediaFileKey,
      action: 'getObject',
      contentType: fileType,
      expiresIn: 31536000, // 1 Year
    };
    const {
      data: newMediaUrl,
    } = await axiosGet<IHttpResponse<string>>(`/signed-s3-url?${getQueryParamString(s3MediaUrlRequest)}`);

    return newMediaUrl;
  }
  // if media url is an external url then we leave it as is
  else {
    return mediaUrl;
  }
}

function getClonedStartsAt(item: ICampaignDetails) {
  // If we passed the campaign's startsAt
  // Then update the date to today but keep the same time

  const originalStartsAt = item?.startsAt;
  let startsAt = getLocalDate().toJSDate();

  if (isPast(originalStartsAt)) {
    startsAt = setHours(startsAt, getHours(originalStartsAt));
    startsAt = setMinutes(startsAt, getMinutes(originalStartsAt));
    startsAt = setSeconds(startsAt, getSeconds(originalStartsAt));
  }

  // If we're still in the past
  // Then use the current time

  return isPast(startsAt) ? getCampaignStartDate() : startsAt;
};

function isPast(date: Date) {
  return date < getLocalDate().toJSDate();
};

// If we ever need to download the file locally:
// async function getImageFile(item: ICampaignDetails) {
//   const response = await axiosGet<Blob>(item?.mediaUrl, { removeToken: true, config: { responseType: 'blob' } });
//   const type = response?.headers?.['content-type'];
//   return (response?.data && type) ? new File([], 'Media File', { type }) : null;
// }