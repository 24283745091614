
import { Transition } from '@headlessui/react';
import { Auth0Role, ICampaignDetails, IHttpResponse, ITestMessageResponse } from '@shared/models';
import useAxios from 'axios-hooks';
import { Fragment, useState } from 'react';
import useRoles from '../../hooks/useRoles';
import { Table } from '../shared/Table/Table';
import { defaultTableOptions } from '../shared/Table/types';
import { getTestMessageColumns } from './types';

interface IHealthCheckSummaryProps {
  campaign: ICampaignDetails;
}

const HealthCheckSummary = ({ campaign }: IHealthCheckSummaryProps) => {
  const [collapseHealthCheck, setCollapseHealthCheck] = useState(false);
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);

  const [{ data: campaignTestMessageData }] = useAxios<IHttpResponse<ITestMessageResponse[]>>({
    url: `campaigns-test-messages/${campaign?.id}`,
    method: 'GET',
  });

  const testMessages: ITestMessageResponse[] = campaignTestMessageData ?? [];

  return (
    <div className="mt-4 mb-2 overflow-hidden bg-white shadow sm:rounded-lg">
      <div
        className="px-4 py-3 sm:px-6"
        onClick={() => {
          setCollapseHealthCheck(!collapseHealthCheck);
        }}
      >
        <h3 className="text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer">
          Health Check Summary
        </h3>
        <p className="max-w-2xl mt-1 text-sm text-gray-500">Status of test messages</p>
      </div>
      <Transition
        show={collapseHealthCheck}
        as={Fragment}
        enter="ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="border-t border-gray-200">
          <Table
            columns={getTestMessageColumns({ showProviderErrors: isSuperAdmin })}
            tableSearchOptions={{
              ...defaultTableOptions,
              pagination: {
                skip: 0,
                take: 1000,
              },
            }}
            items={testMessages}
            shimmer={false}
            height={'20vh'}
          />
        </div>
      </Transition>
    </div>
  );
};

export default HealthCheckSummary;
