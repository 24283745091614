import { Auth0MembershipWithMeta } from '@shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';

interface IMembershipsContext {
  membership: Auth0MembershipWithMeta | undefined;
  isLoaded: boolean;
  setMembership: Dispatch<SetStateAction<Auth0MembershipWithMeta | undefined>>;
}

const MembershipsContext = React.createContext<IMembershipsContext>({
  membership: undefined,
  isLoaded: false,
  setMembership: () => { },
});

const MembershipsContextProvider = MembershipsContext.Provider;
const MembershipsContextConsumer = MembershipsContext.Consumer;
const useMembership = () => useContext(MembershipsContext);
export { MembershipsContext, MembershipsContextConsumer, MembershipsContextProvider, useMembership };

