import { FieldTypeEnum } from '@shared/enums';
import {
  Auth0Role,
  ICreateCustomDataPointRequest,
  ICustomDataPointEntity,
  IUpdateCustomDataPointRequest,
} from '@shared/models';
import { useState } from 'react';
import { axiosDelete, axiosPost, axiosPut } from '../../authAxios';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import useRoles from '../../hooks/useRoles';
import { convertEnumToReadableString } from '../../providers/utility.provider';
import { Button } from '../shared/Buttons/Button';
import { Table } from '../shared/Table/Table';
import { CustomDataPointDetailPanel } from './CDPDetailsPanel';
import { ICustomDataPointForm, defaultCustomDataPointsTableOptions, getColumns } from './types';

export const CustomDataPoints = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICustomDataPointEntity>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultCustomDataPointsTableOptions);
  const addAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const editAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const [{ data, loading }, refetch] = useCustomDataPoints({
    skip: tableOptions?.pagination?.skip,
    take: tableOptions?.pagination?.take
  });

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const handleSubmit = async (formData: ICustomDataPointForm) => {
    setLoadingSubmit(true);
    try {
      const response = await upsertCustomDataPoint(formData);

      if (response?.status === 200 && response?.data) {
        closeDetailsPanel();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSubmit(false);
      await refetch();
    }
  };

  async function upsertCustomDataPoint(formData: ICustomDataPointForm) {
    let response: any;

    if (!!selectedItem) {
      const updateRequest: IUpdateCustomDataPointRequest = {
        id: selectedItem?.id,
        name: formData.name,
        fieldType: formData.fieldType,
        personalized: formData.personalized,
        defaultValue: formData?.defaultValue ?? undefined,
        isActive: !!formData?.isActive ?? false,
      };

      response = await axiosPut(`/custom-data-points/${selectedItem?.id}`, updateRequest);
    } else {
      const createRequest: ICreateCustomDataPointRequest = {
        name: formData.name,
        fieldType: formData.fieldType,
        personalized: formData.personalized,
        defaultValue: formData?.defaultValue ?? undefined,
        isActive: !!formData?.isActive ?? false,
      };

      response = await axiosPost(`/custom-data-points`, createRequest);
    }

    return response;
  }

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await axiosDelete(`/custom-data-points/${selectedItem?.id}`);
      if (response?.status === 200 && response?.data) {
        closeDetailsPanel();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(false);
      refetch();
    }
  };

  const columns = getColumns(
    {
      filters: (item: any) => JSON.stringify(item.filters),
      fieldType: (item?: ICustomDataPointEntity) => {
        if (item?.fieldType === FieldTypeEnum.STRING) {
          return <>Text</>;
        } else {
          return <>{convertEnumToReadableString(item?.fieldType)}</>;
        }
      },
      defaultValue: (item) => item?.defaultValue ? item?.defaultValue : '-'
    },
    {
      name: (item: ICustomDataPointEntity) => {
        setSelectedItem(item);
        setShowDetailsPanel(true);
      },
    }
  );

  const handleRefetch = async () => {
    try {
      refetch();
    } catch (error) { }
  };

  const getRecords = (records: ICustomDataPointEntity[] = []) => {
    return records?.map(record => ({
      ...record,
      name: record?.name
    })) ?? [];
  };

  return (
    <>
      <h2 className="header-page">
        Custom Data Points
        {addAllowed && <Button onClick={() => setShowDetailsPanel(true)}>Add Custom Data Point</Button>}
      </h2>
      <Table
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        items={getRecords(data?.records)}
        count={data?.totalCount}
        columns={columns}
        loading={loading} paginate onRefresh={handleRefetch}></Table>
      <CustomDataPointDetailPanel
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        deleteLoading={deleteLoading}
        loading={loadingSubmit}
        readOnly={!editAllowed}
      />
    </>
  );
};
