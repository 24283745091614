import { ISuppressionEntity } from '@shared/models';
import { FastField, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Field, RuleType } from 'react-querybuilder';
import { getCustomDataPointFields } from '../../providers/custom-data-point.provider';
import { ErrorMessage } from '../shared/Form/ErrorMessage';
import QueryBuilder from '../shared/Form/QueryBuilder/QueryBuilder';
import { TextInput } from '../shared/Form/TextInput';
import { ISuppressionForm, suppressionFormSchema } from './types';

interface ISuppressionFormProps {
  selectedItem: ISuppressionEntity | undefined;
  handleSubmit: (values: ISuppressionForm) => void;
}

export const SuppressionForm = ({ selectedItem, handleSubmit }: ISuppressionFormProps) => {
  const [fields, setFields] = useState<Field[]>([]);
  const initialValues: ISuppressionForm = {
    name: selectedItem?.name ?? '',
    frontendFilter: selectedItem?.frontendFilter ?? { rules: [] },
  };

  useEffect(() => {
    const getFields = async () => {
      const fields = await getCustomDataPointFields({
        rules: selectedItem?.frontendFilter?.rules as RuleType[],
        isActive: true
      });

      setFields(fields);
    };
    getFields();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmit && handleSubmit(values);
      }}
      validationSchema={suppressionFormSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
        <form id="suppression-form" className="h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col h-full px-1 pb-4 space-y-8 overflow-y-scroll divide-y dark:divide-slate-800">
            <div className="space-y-4">
              <FastField
                component={TextInput}
                id="name"
                name="name"
                label="Name"
                value={values.name}
                error={touched.name ? errors.name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <QueryBuilder
                query={selectedItem?.readOnly ? { rules: [], disabled: true } : values.frontendFilter}
                setQuery={(query) => {
                  setFieldValue('frontendFilter', query);
                }}
                fields={fields}
              />
              <ErrorMessage
                show={(touched.frontendFilter?.rules ?? false) && !!errors.frontendFilter}
                message={errors.frontendFilter?.rules?.toString()}
              />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
