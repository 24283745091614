import { FocusEventHandler, useEffect, useState } from 'react';
import { PaperClipIcon } from '@heroicons/react/solid';
import { PencilIcon } from '@heroicons/react/outline';
import { Button } from '../Buttons/Button';
import { ButtonVariantEnum } from '../Buttons/types';
import { ErrorMessage } from './ErrorMessage';
interface ITextAreaProps {
  id?: string;
  name: string;
  value: any;
  maxLength?: number;
  rows?: number;
  className?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  disabled?: boolean;
  description?: JSX.Element;
  onEditButtonClick?: (isDisabled: boolean) => void; // Passing in this function will show the button
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
}

const TextArea = ({
  id,
  name,
  value,
  maxLength,
  className,
  rows,
  label,
  placeholder,
  error,
  optional,
  disabled,
  description,
  onEditButtonClick,
  onChange,
  onBlur,
}: ITextAreaProps): JSX.Element => {
  // TODO: Should let the parent manage the disabled state
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled ?? false);
  const [previousError, setPreviousError] = useState(error);

  // Used to fix the animation by not removing the text as we're transitioning out
  useEffect(() => {
    if (error) {
      setPreviousError(error);
    }
  }, [error]);

  const onEditClick = () => {
    setIsDisabled(!isDisabled);
    onEditButtonClick && onEditButtonClick(!isDisabled);
  };

  return (
    <div className={`${className} flex items-start space-x-4`}>
      <div className="flex-1 min-w-0">
        <div className="flex justify-between">
          <label htmlFor={id} className="block text-sm font-medium text-gray-700 dark:text-slate-400">
            {label}
          </label>
          {optional && (
            <span className="text-sm text-gray-500" id="optional">
              Optional
            </span>
          )}
        </div>
        <div className="relative">
          <div className="overflow-hidden border border-gray-300 rounded-lg shadow-sm dark:border-slate-800 focus-within:border-sky-500 focus-within:ring-1 focus-within:ring-sky-500">
            <textarea
              id={id}
              name={name}
              rows={rows ?? 6}
              value={value}
              // maxLength is disabled since safari doesn't handle it properly
              disabled={isDisabled}
              placeholder={placeholder}
              onChange={(e) => {
                onChange && onChange(e);
              }}
              onBlur={(e) => {
                onBlur && onBlur(e);
              }}
              className={`disabled:bg-gray-100 dark:disabled:bg-slate-900 block w-full dark:text-slate-400 dark:bg-slate-700 py-3 border-0 resize-none focus:ring-0 sm:text-sm`}
            />

            {/* Spacer element to match the height of the toolbar */}
            {/* Matches height of button in toolbar (1px border + 36px content height) */}
            {onEditButtonClick && (
              // <div className={`py-2 ${isDisabled && 'bg-gray-100'}`} aria-hidden="true">
              <div className={`py-2 bg-gray-200}`} aria-hidden="true">
                <div className="py-px">
                  <div className="h-9" />
                </div>
              </div>
            )}
          </div>

          {onEditButtonClick && (
            <div className="absolute inset-x-0 flex justify-between py-2 pl-3 pr-2 select-none sm:bottom-7 bottom-12">
              <div className="ml-auto">
                <Button
                  leadingIcon={<PencilIcon className="w-5 h-5" />}
                  variant={ButtonVariantEnum.DEFAULT}
                  onClick={onEditClick}
                >
                  {isDisabled ? 'Edit' : 'Save'}
                </Button>
              </div>
            </div>
          )}
          <div className="flex select-none">
            <ErrorMessage show={!!error} message={previousError} />
            {description ? description : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};

export { TextArea };
