export const SendMMSIcon = () => <svg xmlns="http://www.w3.org/2000/svg" width="33" height="31" viewBox="0 0 33 31" fill="none">
  <g clipPath="url(#clip0_245_7914)">
    <path d="M29.4722 2.80305H24.1166C23.4717 2.80305 22.8859 2.45337 22.5921 1.89239C21.9873 0.72554 20.7815 0 19.4478 0H13.5598C12.2243 0 11.0204 0.72554 10.4155 1.89239C10.1217 2.45898 9.53596 2.8124 8.88916 2.81427H3.53544C1.59314 2.8124 0.00953978 4.35885 0.00381591 6.26059L0 20.4741C0 22.3814 1.5817 23.9316 3.52781 23.9316H20.3789L28.8445 29.761C29.0066 29.8937 29.2127 29.9667 29.4245 29.9667C29.6954 29.9667 29.9492 29.8507 30.1209 29.6469C30.2583 29.4861 30.3327 29.2841 30.3308 29.0747V23.7913C31.8877 23.408 33.0019 22.0205 33 20.4367V6.24563C32.9924 4.34763 31.4088 2.80305 29.4722 2.80305ZM29.4207 22.1421C28.9208 22.1421 28.5144 22.5404 28.5144 23.0303V27.2048L21.2794 22.3384C21.1173 22.2075 20.9131 22.1364 20.7051 22.1364H3.51827C2.57192 22.1364 1.80302 21.3829 1.80302 20.4554V20.2422H4.47225C5.76393 20.2422 6.81331 19.2137 6.81331 17.9478V9.06925C6.81331 7.80329 5.76393 6.77482 4.47225 6.77482H1.80302V6.26059C1.80302 5.33309 2.57192 4.57763 3.51827 4.57763H8.88726C10.2228 4.57763 11.4267 3.85209 12.0316 2.68525C12.3254 2.11678 12.9111 1.76523 13.5637 1.76523H19.4478C20.0984 1.76523 20.6841 2.11865 20.978 2.68712C21.5828 3.85396 22.7886 4.5795 24.1223 4.5795H29.4703C30.4166 4.5795 31.1855 5.33496 31.1855 6.26246L31.1817 20.461C31.1817 20.9098 31.0043 21.3324 30.6799 21.6503C30.3556 21.9682 29.9244 22.1439 29.4665 22.1439H29.4188L29.4207 22.1421ZM4.4856 18.4713H1.81637V8.55688H4.4856C4.77752 8.55688 5.01411 8.78876 5.01411 9.07486V17.9534C5.01411 18.2395 4.77752 18.4713 4.4856 18.4713Z" fill="#2B70EE" />
    <path d="M16.5041 7.0011C12.8465 7.0011 9.87012 9.91822 9.87012 13.5029C9.87012 17.0876 12.8465 20.0047 16.5041 20.0047C20.1616 20.0047 23.138 17.0876 23.138 13.5029C23.138 9.91822 20.1616 7.0011 16.5041 7.0011ZM16.6949 18.2844V18.2788H16.5041C13.8177 18.2788 11.6312 16.1358 11.6312 13.5029C11.6312 10.87 13.8177 8.72706 16.5041 8.72706C19.1905 8.72706 21.377 10.87 21.377 13.5029C21.3789 14.7782 20.8752 15.9787 19.9556 16.8819C19.0817 17.7421 17.9331 18.2339 16.6949 18.2825V18.2844Z" fill="#2B70EE" />
    <path d="M19.3578 3.875H13.6416V5.65145H19.3578V3.875Z" fill="#2B70EE" />
    <path d="M16.4637 9.66203C14.3229 9.68634 12.5791 11.4123 12.5771 13.5085V13.6918L14.3229 13.7366L14.3306 13.5683L14.3382 13.5403V13.5141C14.3401 12.3585 15.3036 11.4067 16.4827 11.3936H16.6735L16.6545 9.66016H16.4637V9.66203Z" fill="#2B70EE" />
  </g>
  <defs>
    <clipPath id="clip0_245_7914">
      <rect width="33" height="31" fill="white" />
    </clipPath>
  </defs>
</svg>;