import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ISystemNumberUpload10DLCRequest, ISystemNumberUploadRecord } from '@shared/models';
import { Fragment, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useProviders from '../../hooks/useProviders';
import { getCsvRecords } from '../../providers/utility.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import UploadSystemNumbersForm, { ISystemNumbersUploadForm } from './UploadSystemNumbersForm';

interface IUploadSystemNumbersModalProps {
  show: boolean;
  setShow: any;
  onUpload: () => Promise<void>;
}

const UploadSystemNumbersModal = ({ show, setShow, onUpload }: IUploadSystemNumbersModalProps) => {
  const { addNotification } = useNotifications();
  const [uploadingFile, setUploadingFile] = useState(false);
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const providers: IDropdownValue[] =
    providerData?.map((provider) => ({
      label: provider.name ?? '',
      value: provider.id ?? '',
    })) ?? [];

  const uploadSystemNumbers = async (formData: ISystemNumbersUploadForm) => {
    try {
      setUploadingFile(true);

      // Pull all values from file and send as request
      const rawCsv: string[] = await getCsvRecords(formData.systemNumberList);

      const records: ISystemNumberUploadRecord[] = rawCsv.map((record) => {
        const values = record.split(',');

        return {
          phoneNumber: parseInt(values[0]),
          tcrCampaignId: values[1],
          isActive: values[2].toLocaleLowerCase() === 'active',
        } as ISystemNumberUploadRecord;
      });

      const request: ISystemNumberUpload10DLCRequest = {
        clientId: formData.client?.value,
        providerId: formData.providerId,
        records: records,
      };

      await axiosPost('/system-numbers-upload-10dlc', request);

      setShow(false);

      addNotification({
        header: 'System Numbers uploaded successfully!',
      });

      await onUpload();
    } catch (error) {
      console.error(error);
      addNotification({
        header: 'Error',
        content: 'System Numbers failed to upload. Please verify all fields match up exactly.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setUploadingFile(false);
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:text-slate-400" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                onClick={() => {
                  setShow(false);
                }}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optouts-modal-title">Upload System Numbers</span>
              </Dialog.Title>
              <div className="mt-2">
                <UploadSystemNumbersForm onSubmit={uploadSystemNumbers} loading={uploadingFile} providers={providers} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadSystemNumbersModal;
