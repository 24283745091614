import LoadingIndicator from '../LoadingIndicator';
import { ButtonVariantEnum } from './types';

interface IButtonProps {
  id?: string;
  text?: string;
  title?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  leadingIcon?: JSX.Element;
  formId?: string;
  variant?: ButtonVariantEnum;
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement>;
  onTouchEnd?: React.TouchEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseUp?: React.MouseEventHandler<HTMLButtonElement>;
}

const Button = ({
  id,
  text,
  title,
  children,
  disabled,
  isLoading,
  leadingIcon,
  formId,
  variant,
  type,
  className,
  onClick,
  onMouseDown,
  onMouseUp,
  onTouchStart,
  onTouchEnd,
}: IButtonProps) => {
  let classes;
  switch (variant) {
    case ButtonVariantEnum.SECONDARY:
      classes = 'btn-secondary';
      break;
    case ButtonVariantEnum.DEFAULT:
      classes = 'btn-default';
      break;
    case ButtonVariantEnum.TEXT_PRIMARY:
      classes = 'btn-text-primary';
      break;
    case ButtonVariantEnum.TEXT_DEFAULT:
      classes = 'btn-text-default';
      break;
    case ButtonVariantEnum.DELETE:
      classes = 'btn-delete';
      break;
    case ButtonVariantEnum.SUCCESS:
      classes = 'btn-success';
      break;
    case ButtonVariantEnum.TAG:
      classes = 'btn-tag';
      break;
    case ButtonVariantEnum.LINK_TAG:
      classes = 'btn-link-tag';
      break;
    default:
      classes = 'btn-primary';
      break;
  }
  return (
    <>
      <button
        id={id}
        onClick={onClick}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        type={type}
        title={title}
        disabled={disabled}
        form={formId}
        className={`${classes} disabled:opacity-25 select-none ${disabled && 'cursor-default'} ${className ?? ''}`}
      >
        {leadingIcon && (
          <div className={`${text || children ? 'mr-1' : ''}`} aria-hidden="true">
            {leadingIcon}
          </div>
        )}

        {isLoading ? (
          <LoadingIndicator
            position="CENTER"
            className={variant === ButtonVariantEnum.DELETE ? 'border-t-red-500' : 'border-t-sky-500'}
          />
        ) : children ? (
          children
        ) : (
          text
        )}
      </button>
    </>
  );
};

export { Button };
