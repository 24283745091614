import { ClientContactStatusEnum } from '@shared/enums/client-contact-status.enum';
import { ContactStatusEnum } from '@shared/enums/contact-status.enum';
import { FilterDataTypeEnum, FilterOperatorEnum, ISearchFilter, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn } from '../shared/Table/types';

export const defaultContactsTableOptions: ISearchRequest = {
  filters: <ISearchFilter[]>[
    {
      fieldName: 'globalStatus',
      dataType: FilterDataTypeEnum.ENUM,
      operator: FilterOperatorEnum.EQUALS,
      value: ContactStatusEnum.CLASSIFIED
    },
    {
      fieldName: 'clientStatus',
      dataType: FilterDataTypeEnum.ENUM,
      operator: FilterOperatorEnum.EQUALS,
      value: ClientContactStatusEnum.OPTED_IN
    }
  ],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const optoutFilterDropdownOptions: IFilterDropdownOptions = {
  globalStatus: getContactStatusEnum(),
  clientStatus: getClientContactStatusEnum(),
};

export const getColumns = (): IColumn[] => {
  return [
    {
      headerName: 'Phone Number',
      fieldName: 'phoneNumber',
      fieldType: FilterDataTypeEnum.NUMBER,
    },
    {
      headerName: 'Global Status',
      fieldName: 'globalStatus',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Client Status',
      fieldName: 'clientStatus',
      fieldType: FilterDataTypeEnum.ENUM,
    },
    {
      headerName: 'Show Global Optouts',
      fieldName: 'showGlobalOptouts',
      fieldType: FilterDataTypeEnum.BOOLEAN,
      hidden: true,
    },
  ];
};

function getContactStatusEnum(): IDropdownValue[] {
  return Object.values(ContactStatusEnum)
    .filter((status) => !Number.isInteger(status))
    .map((status) => ({
      label: getContactStatusLabel(status),
      value: status,
    }));
}

function getClientContactStatusEnum(): IDropdownValue[] {
  return Object.values(ClientContactStatusEnum)
    .filter((status) => !Number.isInteger(status))
    .map((status) => ({
      label: getClientContactStatusLabel(status),
      value: status,
    }));
}

function getContactStatusLabel(status: ContactStatusEnum) {
  switch (status) {
    case ContactStatusEnum.UNCLASSIFIED:
      return 'Unclassified';
    case ContactStatusEnum.CLASSIFIED:
      return 'Classified';
    case ContactStatusEnum.OPTED_OUT:
      return 'Opted Out';
    case ContactStatusEnum.UNKNOWN:
      return 'Pending';
  }
}

function getClientContactStatusLabel(status: ClientContactStatusEnum) {
  switch (status) {
    case ClientContactStatusEnum.NEW:
      return 'New';
    case ClientContactStatusEnum.WELCOME_SENT:
      return 'Welcome Sent';
    case ClientContactStatusEnum.WELCOME_FAIL:
      return 'Welcome Fail';
    case ClientContactStatusEnum.OPTED_IN:
      return 'Opted In';
    case ClientContactStatusEnum.OPTED_OUT:
      return 'Opted Out';
    case ClientContactStatusEnum.UNKNOWN:
      return 'Pending';
  }
}