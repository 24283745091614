import useProviders from 'apps/a2p-portal/src/hooks/useProviders';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IClientDropdownProps {
  value: string;
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const ProviderDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IClientDropdownProps): JSX.Element => {
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const providers: IDropdownValue[] =
    providerData?.map((provider) => ({
      label: provider.name ?? '',
      value: provider.id ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="Provider"
      value={providers.find((provider) => provider.value === value)}
      options={providers}
      onChange={onChange}
      onBlur={onBlur}
      loading={providerLoading}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default ProviderDropdown;
