import { Auth0User, IHttpPaginatedResponse, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

const useUsers = (request: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<Auth0User>> => {
  return useAxios<IHttpPaginatedResponse<Auth0User>>({
    url: 'auth0-get-users',
    method: 'POST',
    data: request
  });
};

export default useUsers;
