import { FieldTypeEnum } from '@shared/enums';
import { Operator } from 'react-querybuilder';

export type QueryBuilderOperator =
  | '='
  | '!='
  | '<'
  | '>'
  | '<='
  | '>='
  | 'contains'
  | 'beginsWith'
  | 'endsWith'
  | 'doesNotContain'
  | 'doesNotBeginWith'
  | 'doesNotEndWith'
  | 'null'
  | 'notNull'
  | 'in'
  | 'notIn'
  | 'between'
  | 'notBetween'
  | 'gteDaysAgo'
  | 'lteDaysAgo'
  | 'gteDaysFromNow'
  | 'lteDaysFromNow'
  ;

export interface QueryBuilderOperatorOption extends Omit<Operator, 'name'> {
  name: QueryBuilderOperator;
  label: string;
}

export type QueryBuilderOperatorMap = Map<FieldTypeEnum, QueryBuilderOperatorOption[]>;

const eq: QueryBuilderOperatorOption = { name: '=', label: '=' };
const neq: QueryBuilderOperatorOption = { name: '!=', label: '!=' };
const gte: QueryBuilderOperatorOption = { name: '>=', label: '>=' };
const lte: QueryBuilderOperatorOption = { name: '<=', label: '<=' };
const between: QueryBuilderOperatorOption = { name: 'between', label: 'between' };
const beginsWith: QueryBuilderOperatorOption = { name: 'beginsWith', label: 'starts with' };
const contains: QueryBuilderOperatorOption = { name: 'in', label: 'contains' };
const gteDaysAgo: QueryBuilderOperatorOption = { name: 'gteDaysAgo', label: '>= days ago' };
const lteDaysAgo: QueryBuilderOperatorOption = { name: 'lteDaysAgo', label: '<= days ago' };
const gteDaysFromNow: QueryBuilderOperatorOption = { name: 'gteDaysFromNow', label: '>= days from now' };
const lteDaysFromNow: QueryBuilderOperatorOption = { name: 'lteDaysFromNow', label: '<= days from now' };

export const fieldTypeOperatorMap = <QueryBuilderOperatorMap>new Map(Object.entries({
  [FieldTypeEnum.STRING]: [eq, beginsWith, contains],
  [FieldTypeEnum.NUMBER]: [eq, neq, gte, lte, between],
  [FieldTypeEnum.DATE]: [eq, neq, gte, lte, between, gteDaysAgo, lteDaysAgo, gteDaysFromNow, lteDaysFromNow],
  [FieldTypeEnum.BOOLEAN]: [eq, neq],
  [FieldTypeEnum.ENUM]: [],
  [FieldTypeEnum.TYPEAHEAD]: [],
}));
