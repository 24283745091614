import { ICustomDataPointEntity, IHttpResponse, TenantUserPrefsResp } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export function useUserPrefs(userId: string): UseAxiosResult<IHttpResponse<TenantUserPrefsResp>> {
  return useAxios<IHttpResponse<TenantUserPrefsResp>>({
    method: 'GET',
    url: `custom-data-points-prefs`,
    params: {
      userId
    }
  });
}

export function useUserPrefsCustomDataPoints(userId: string, customDataPointIds: string[]): UseAxiosResult<IHttpResponse<ICustomDataPointEntity[]>> {
  return useAxios<IHttpResponse<ICustomDataPointEntity[]>>({
    method: 'POST',
    url: `custom-data-points-prefs`,
    data: {
      userId,
      customDataPointIds
    }
  });
}
