import { FieldTypeEnum } from '@shared/enums';
import { Auth0Role, ICustomDataPointEntity } from '@shared/models';
import { FastField, Formik } from 'formik';
import { isNull } from 'lodash';
import useRoles from '../../hooks/useRoles';
import Checkbox from '../shared/Form/Checkbox';
import DateTimePicker from '../shared/Form/DateTimePicker';
import { Dropdown, IDropdownValue } from '../shared/Form/Dropdown';
import FieldTypeDropdown from '../shared/Form/Dropdowns/FieldTypeDropdown';
import { TextInput } from '../shared/Form/TextInput';
import { Slider } from '../shared/Slider';
import { ICustomDataPointForm, customDataPointFormSchema } from './types';

interface ICustomDataPointFormProps {
  selectedItem: ICustomDataPointEntity | undefined;
  handleSubmit: (values: ICustomDataPointForm) => void;
  readOnly: boolean;
}

export const CustomDataPointForm = ({ selectedItem, handleSubmit, readOnly }: ICustomDataPointFormProps) => {
  const allowEnableDisable = useRoles([Auth0Role.A2P_ADMIN]);

  const initialValues: ICustomDataPointForm = {
    name: !!selectedItem ? selectedItem.name : '',
    fieldType: !!selectedItem ? selectedItem.fieldType : FieldTypeEnum.STRING,
    personalized: !!selectedItem ? selectedItem.personalized : false,
    defaultValue: (!!selectedItem && !isNull(selectedItem.defaultValue)) ? selectedItem.defaultValue : undefined,
    isActive: !!selectedItem ? !!selectedItem?.isActive ?? false : true
  };

  const dropdownOptions = [
    {
      label: 'True',
      value: 'true', // Needs to be string or big headache
    },
    {
      label: 'False',
      value: 'false', // Needs to be string or big headache
    },
  ] as IDropdownValue[];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        handleSubmit && handleSubmit(values);
      }}
      validationSchema={customDataPointFormSchema}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
        <form id="custom-data-point-form" className="h-full" onSubmit={handleSubmit}>
          <div className="flex flex-col h-full px-1 pb-4 space-y-8 overflow-y-scroll divide-y dark:divide-slate-800">
            <div className="space-y-4 divide-y">
              <div className="space-y-4">
                <FastField
                  component={TextInput}
                  id="name"
                  name="name"
                  label="Name"
                  value={values.name}
                  error={touched.name ? errors.name : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={readOnly}
                />
                <FieldTypeDropdown
                  value={values.fieldType}
                  onChange={(newValue) => {
                    setFieldValue('fieldType', newValue?.value ?? '');
                  }}
                  disabled={readOnly}
                />
              </div>
              <div className="pt-4 space-y-4">
                <Checkbox
                  id="personalized"
                  name="personalized"
                  label="Personalized"
                  checked={values.personalized}
                  onChange={handleChange}
                  disabled={readOnly}
                />
                {[FieldTypeEnum.NUMBER, FieldTypeEnum.STRING].includes(
                  FieldTypeEnum[values.fieldType as FieldTypeEnum]
                ) ? (
                  <FastField
                    component={TextInput}
                    type={values.fieldType === FieldTypeEnum.NUMBER ? 'number' : ''}
                    disabled={!values.personalized || readOnly}
                    id="defaultValue"
                    label="Default Value"
                    value={values.defaultValue}
                    error={touched.defaultValue ? errors.defaultValue : ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : values.fieldType === FieldTypeEnum.DATE ? (
                  <FastField
                    component={DateTimePicker}
                    disabled={!values.personalized || readOnly}
                    id="defaultValue"
                    label="Default Value"
                    value={values.defaultValue}
                    error={touched.defaultValue ? errors.defaultValue : ''}
                    onChange={(newValue: Date) => {
                      setFieldTouched('defaultValue');
                      setFieldValue('defaultValue', newValue);
                    }}
                    onBlur={handleBlur}
                  />
                ) : (
                  <Dropdown
                    onChange={(ev: IDropdownValue) => {
                      setFieldTouched('defaultValue');
                      setFieldValue('defaultValue', ev.value);
                    }}
                    value={dropdownOptions.find((x) => x.value === values.defaultValue)}
                    options={dropdownOptions}
                    disabled={!values.personalized || readOnly}
                    error={touched.defaultValue ? errors.defaultValue : ''}
                  />
                )}
              </div>
              <div className="pt-4 space-y-4">
                <FastField
                  component={Slider}
                  id="isActive"
                  name="isActive"
                  label="Active"
                  disabled={readOnly || !allowEnableDisable}
                  value={values.isActive}
                  checked={values.isActive}
                  error={touched.isActive ? errors.isActive : ''}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};
