import { IAudienceEntity, IHttpPaginatedResponse, ISearchRequest } from '@shared/models';
import useAxios from 'axios-hooks';
import { useClientContext } from '../contexts/ClientContext';

export function useAudiences(request?: ISearchRequest) {
  const { selectedClientId } = useClientContext();

  return useAxios<IHttpPaginatedResponse<IAudienceEntity>>({
    url: `audiences`,
    method: 'GET',
    params: {
      clientId: selectedClientId,
      skip: request?.pagination?.skip ?? 0,
      take: request?.pagination?.take ?? 1000
    }
  });
}
