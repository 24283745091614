import { FilterDataTypeEnum } from '@shared/models';
import * as yup from 'yup';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { DecisionIcon } from '../shared/Icons/DecisionIcon';
import { EndJourney } from '../shared/Icons/EndJourneyIcon';
import { HourglassIcon } from '../shared/Icons/HourglassIcon';
import { PauseIcon } from '../shared/Icons/PauseIcon';
import { SendMMSIcon } from '../shared/Icons/SendMMSIcon';
import { SendSMSIcon } from '../shared/Icons/SendSMSIcon';
import { SplitIcon } from '../shared/Icons/SplitIcon';
import { IColumnClickConfig } from '../shared/Table/types';

export enum NodeTypeEnum {
  AUDIENCE
}

export const getColumns = (columnClickEvents: IColumnClickConfig) => [
  {
    headerName: 'TITLE',
    fieldName: 'title',
    fieldType: FilterDataTypeEnum.STRING,
    isRowHeader: true,
    onColumnClick: columnClickEvents['title'],
  },
  {
    headerName: '# OF CONTACTS',
    fieldName: 'contactCount',
    fieldType: FilterDataTypeEnum.NUMBER,
  },
  {
    headerName: 'START DATE',
    fieldName: 'startsAt',
    fieldType: FilterDataTypeEnum.DATE,
  },
  {
    headerName: 'END DATE',
    fieldName: 'endsAt',
    fieldType: FilterDataTypeEnum.DATE,
  },
  {
    headerName: '',
    fieldName: 'actions',
    fieldType: FilterDataTypeEnum.ACTIONS,
  }
];

export const initialJourneysPage = {
  skip: 0,
  take: 5
};
export interface IJourneyForm {
  title: string;
  audience: IDropdownValue;
  startDate: Date;
  endDate: Date;
}

export const journeyFormSchema: yup.SchemaOf<IJourneyForm> = yup.object().shape({
  title: yup.string().defined('Required'),
  audience: yup.object().shape({
    label: yup.string().defined('Required'),
    value: yup.string().defined('Required'),
    additionalData: yup.string()
  }),
  startDate: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required')
    .min(new Date(), 'Journey cannot start in the past'),
  endDate: yup
    .date()
    .typeError('Invalid Date')
    .defined('Required')
    .min(yup.ref('startDate'), 'End date cannot be before Start date'),
});

export const rulesCards = [
  {
    icon: HourglassIcon,
    text: 'Time delay'
  },
  {
    icon: DecisionIcon,
    text: 'If/Else'
  },
  {
    icon: SplitIcon,
    text: '50/50 Split'
  },
  {
    icon: PauseIcon,
    text: 'Wait for Trigger'
  }
];

export const actionsCards = [
  {
    icon: SendSMSIcon,
    text: 'Send SMS'
  },
  {
    icon: SendMMSIcon,
    text: 'Send MMS'
  },
  {
    icon: EndJourney,
    text: 'End Journey'
  }
];