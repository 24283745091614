import { IClientEntity, IGetAssignedClientsRequest, IHttpResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useAssignedClients(request: IGetAssignedClientsRequest): UseAxiosResult<IHttpResponse<IClientEntity[]>> {
  return useAxios<IHttpResponse<IClientEntity[]>>({
    url: `assigned-clients`,
    method: 'GET',
    params: request,
  }, {
    manual: true,
    useCache: false
  });
}
