import { EntityName } from '@shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  IGetClientsRequest,
  ISearchRequest,
  SearchSortDirectionEnum,
} from '@shared/models';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';
import { KeywordTypeEnum } from '@shared/enums/keyword-type-enum';

export const defaultClientTableOptions: IGetClientsRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const allEnabledClientsSearchOptions: ISearchRequest = {
  entity: EntityName.CLIENTS,
  filters: [
    {
      dataType: FilterDataTypeEnum.BOOLEAN,
      fieldName: 'isActive',
      operator: FilterOperatorEnum.EQUALS,
      value: true,
    },
  ],
  pagination: {
    skip: 0,
    take: 10,
  },
  sort: {
    fieldName: 'name',
    sortDirection: SearchSortDirectionEnum.ASC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  const columns = [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Status',
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
    {
      headerName: 'Created Date',
      fieldName: 'createdAt',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];

  return columns;
};

export interface IKeyword {
  id: string;
  word: string;
  reply: string;
  type: KeywordTypeEnum;
  isDefault: boolean;
  existing?: boolean;
}
