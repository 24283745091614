import { IHttpPaginatedResponse, IHttpResponse, IJourney, IJourneysRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { useClientContext } from '../contexts/ClientContext';

export default function useJourneys(request: Partial<IJourneysRequest>): UseAxiosResult<IHttpResponse<IHttpPaginatedResponse<IJourney[]>>> {
  const { selectedClientId } = useClientContext();
  return useAxios<IHttpResponse<IHttpPaginatedResponse<IJourney[]>>>({
    url: 'journeys',
    method: 'GET',
    params: {
      clientId: selectedClientId,
      name: !!request.name ? request.name : undefined,
      skip: request.skip,
      take: request.take
    } as IJourneysRequest
  },
    {
      manual: true
    }
  );
}
