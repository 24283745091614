import {
  IHttpResponse,
  ITCRCampaignDetails,
  IToggleSystemNumberRequest
} from '@shared/models';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import useSystemNumbers from '../../hooks/useSystemNumbers';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Card from '../shared/DetailsPanel/DetailsPanelCard';
import { IDropdownValue } from '../shared/Form/Dropdown';
import SystemNumbersDropdown from '../shared/Form/Dropdowns/SystemNumbersDropdown';
import { defaultSystemNumberDropdownOptions } from './types';

interface IActiveNumbersCardProps {
  selectedItem?: ITCRCampaignDetails;
}

const ActiveNumbersCard = ({ selectedItem }: IActiveNumbersCardProps) => {
  const [numbers, setNumbers] = useState([] as IDropdownValue[]);

  const [{ loading, error }, deactivateNumbers] = useAxios<IHttpResponse<string>>(
    {
      url: '/system-numbers-toggle',
      method: 'POST',
    },
    { manual: true }
  );

  const [{ data: numbersData, loading: numbersLoading, error: numbersError }, refreshNumbers] = useSystemNumbers(
    defaultSystemNumberDropdownOptions(selectedItem)
  );

  const formatPhoneNumber = (phoneNumberString: string) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ')-', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  const numbersDropdownValues: IDropdownValue[] =
    numbersData?.records.map((number) => ({
      label: formatPhoneNumber(String(number.phoneNumber)),
      value: number.id,
      additionalData: number.phoneNumber,
    })) ?? [];

  const handleDeactivate = async () => {
    try {
      const request: IToggleSystemNumberRequest = {
        tcrCampaignId: selectedItem?.tcrCampaignId ?? '',
        isActive: false,
        providerId: selectedItem?.providerId ?? '',
        phoneNumbers: numbers.map((x) => Number(x.additionalData)),
      };
      await deactivateNumbers({ data: request });
      await refreshNumbers();
      setNumbers([]);
    } catch (error: any) {
      console.error(error);
    }
  };
  return (
    <Card title={'Deactivate Numbers'} subtitle={`Select numbers to deactivate`}>
      <SystemNumbersDropdown
        value={numbers?.map((val) => {
          return {
            label: formatPhoneNumber(val.label),
            value: val.value,
            additionalData: val.additionalData,
          } as IDropdownValue;
        })}
        onChange={(newValue) => {
          setNumbers(newValue as IDropdownValue[]);
        }}
        options={numbersDropdownValues}
        showError={false}
      />
      <Button
        variant={ButtonVariantEnum.DELETE}
        text={'Deactivate Numbers'}
        className="ml-0"
        disabled={numbers.length <= 0}
        onClick={handleDeactivate}
        isLoading={loading}
      />
    </Card>
  );
};

export default ActiveNumbersCard;
