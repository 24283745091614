import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { FileUpload } from '../shared/Form/FileUpload';
import LoadingIndicator from '../shared/LoadingIndicator';

const validateHeaders = async (file: File) => {
  try {
    const headers = (await getCsvHeaders(file)).map((header) => header.toLowerCase());
    return headers?.some(header => header?.toLowerCase() === 'phonenumber');
  } catch (error) {
    console.error(error);
    return false;
  }
};

const schema: yup.SchemaOf<IContactListUploadForm> = yup.object().shape({
  file: yup
    .mixed()
    .required('Required')
    .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
      return file ? file.name?.endsWith('.csv') : false;
    })
    .test('csvHeaderValidate', 'CSV does not have a PhoneNumber header', async (file: File): Promise<boolean> => {
      return file ? await validateHeaders(file) : false;
    }),
});

export interface IContactListUploadForm {
  file?: any;
}

interface IContactListFormProps {
  onSubmit?: (formValues: IContactListUploadForm) => void;
  loading: boolean;
}

const ContactsUploadForm = ({ onSubmit, loading }: IContactListFormProps): JSX.Element => {
  const initialFormState: IContactListUploadForm = {
    file: undefined,
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <form id="contact-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4">
                <FileUpload
                  id="contactList"
                  name="contactList"
                  label="Add Contacts"
                  accepts=".csv"
                  value={values.file}
                  error={touched.file && (errors.file as any)}
                  onBlur={handleBlur}
                  placeholder={'Select a .csv'}
                  onChange={(fileUploadEvent: any) => {
                    const file = fileUploadEvent.target.files[0];
                    setFieldTouched('file');
                    setFieldValue('file', file);
                  }}
                />

                <div className="mt-2">
                  <p className="text-sm text-gray-500">Each row must have a number and contact name.</p>
                  <p className="text-sm text-gray-500">
                    Download the template{' '}
                    <Link
                      className="font-semibold text-sky-500 hover:underline"
                      to="/sample_upload_contacts.csv"
                      target="_blank"
                      download
                    >
                      here
                    </Link>
                  </p>
                </div>
                <div className="flex justify-center mt-4"></div>
              </div>
              <Button
                disabled={loading}
                id="contact-form"
                className="flex ml-auto"
                type="submit"
                variant={ButtonVariantEnum.SECONDARY}
              >
                {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
              </Button>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ContactsUploadForm;
