import { Auth0Role, IClientEntity, IHttpResponse, IUpdateClientRequest } from '@shared/models';
import { useEffect, useState } from 'react';
import { axiosDelete, axiosPost, axiosPut } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import useClients from '../../hooks/useClients';
import useKeywords from '../../hooks/useKeywords';
import { Button } from '../shared/Buttons/Button';
import Roles from '../shared/Roles';
import { Table } from '../shared/Table/Table';
import ClientDetailsPanel from './ClientDetailsPanel';
import { IClientForm } from './ClientForm';
import { defaultClientTableOptions, getColumns } from './types';

export const Clients = () => {
  const [selectedItem, setSelectedItem] = useState<IClientEntity>();
  const [{ data: keywordData }, refetchKeywords] = useKeywords();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultClientTableOptions);
  const [{ data, loading: tableLoading, error }, refetch] = useClients(tableOptions);
  const { refreshClients } = useClientContext();

  const openDetailsPanel = async (item?: IClientEntity) => {
    setSelectedItem(item);
    await refetchKeywords({
      url: `client-keywords/${item?.id}`,
    });
    setShowDetailsPanel(true);
  };
  const closeDetailsPanel = async () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const columns = getColumns({
    name: (item: IClientEntity) => {
      openDetailsPanel(item);
    },
  });

  const upsertClient = async (formData: IClientForm, update: boolean): Promise<void> => {
    setLoading(true);
    const request: IUpdateClientRequest = {
      name: formData.name,
      externalId: formData.externalId,
      isActive: formData.isActive!.value,
      tcrBrandId: formData.tcrBrandId,
      senderIdentifier: formData.senderIdentifier,
      messageFlow: formData.messageFlow,
      keywords: [
        ...(formData?.stopKeywords ?? []),
        ...(formData?.joinKeywords ?? []),
        ...(formData?.helpKeywords ?? []),
      ].map((keyword) => {
        return {
          id: keyword.id,
          type: keyword.type,
          word: keyword.word,
          reply: keyword.reply,
        };
      })
    };
    try {
      update
        ? await axiosPut<IHttpResponse<string>>(`/clients/${selectedItem?.id}`, request)
        : await axiosPost<IHttpResponse<string>>('/clients', request);
    } catch (e) {
    } finally {
      setLoading(false);
      closeDetailsPanel();
      refetch();
      refreshClients();
    }
  };

  const deleteClient = async (id: string): Promise<void> => {
    try {
      setDeleteLoading(true);
      await axiosDelete<IHttpResponse<string>>(`/clients/${selectedItem?.id}`);
    } catch (e) {
    } finally {
      closeDetailsPanel();
      refetch();
      refreshClients();
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [tableOptions]);

  const handleRefetch = async () => {
    try {
      await refetch();
      refreshClients();
    } catch (error) { }
  };

  return (
    <>
      <h2 className="header-page">
        Clients
        <Roles roles={[Auth0Role.A2P_ADMIN]}>
          <Button onClick={() => setShowDetailsPanel(true)}>Add Client</Button>
        </Roles>
      </h2>
      <Table
        items={data?.records}
        loading={tableLoading}
        columns={columns}
        booleanLabels={[{ fieldName: 'isActive', displayValues: { trueLabel: 'Enabled', falseLabel: 'Disabled' } }]}
        paginate
        count={data?.totalCount}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        onRefresh={handleRefetch}
      />
      <ClientDetailsPanel
        loading={loading}
        deleteLoading={deleteLoading}
        handleSubmit={(formData) => upsertClient(formData, !!selectedItem)}
        handleDelete={deleteClient}
        selectedItem={selectedItem}
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        keywords={keywordData ?? []}
      />
    </>
  );
};
