import { ActionWithRulesAndAddersProps, RuleOrGroupArray } from 'react-querybuilder';
import { Button } from '../../Buttons/Button';
import { ButtonVariantEnum } from '../../Buttons/types';

export interface ICustomAddRuleButtonProps extends ActionWithRulesAndAddersProps {
  maxRules?: number;
  rules?: RuleOrGroupArray;
  disabled?: boolean;
  handleOnClick(e: any, context?: any): void;
}

export const CustomAddRuleButton = (props: ICustomAddRuleButtonProps) => {
  return (
    <Button
      disabled={(props.rules?.length ?? 0) >= (props.maxRules ?? 9) || props.disabled}
      variant={ButtonVariantEnum.PRIMARY}
      onClick={props.handleOnClick}
    >
      Add Rule
    </Button>
  );
};
