import { GetAllClientsRequest, IClientEntity, IHttpPaginatedResponse, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClientsManual(request?: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<IClientEntity>> {
  const params: GetAllClientsRequest = {
    isActive: true,
    skip: 0,
    take: 1000
  };

  return useAxios<IHttpPaginatedResponse<IClientEntity>>({
    url: 'clients',
    method: 'GET',
    params
  }, {
    manual: true
  });
}
