import { EntityName } from '@shared/enums';
import { DropdownEntityType, FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import * as yup from 'yup';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { dropdownSchema } from '../shared/Schema/yup';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultTestNumberTableOptions: ISearchRequest = {
  filters: [],
  pagination: {
    skip: 0,
    take: 100000,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const defaultCarrierSearchOptions: ISearchRequest = {
  entity: EntityName.CARRIERS,
  filters: [],
  pagination: {
    skip: 0,
    take: 100000,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.STRING,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Number',
      fieldName: 'number',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['number'],
    },
    {
      headerName: 'Carrier',
      fieldName: 'carrierName',
      isRowHeader: false,
      fieldType: FilterDataTypeEnum.STRING,
    },
  ];
};

export interface IHealthCheckNumberForm {
  name: string;
  number?: number;
}

export const testNumberFormSchema: yup.SchemaOf<IHealthCheckNumberForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  number: yup
    .number()
    .test('len', 'Number must be 10 digits long', (num) => num?.toString().length === 10)
    .defined('Required')
    .typeError('Required'), // TODO: Give up and use a string instead?
});
