import { ICustomDataPointEntity } from '@shared/models';
import { useCustomDataPoints } from 'apps/a2p-portal/src/hooks/useCustomDataPoints';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface ICustomDataPointsDropdownProps {
  value: IDropdownValue[];
  onChange: (newValue: IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

function mapToDropdownValue(cdp: ICustomDataPointEntity): IDropdownValue {
  return {
    label: cdp.name ?? '',
    value: cdp.id ?? ''
  };
}

const CustomDataPointsDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: ICustomDataPointsDropdownProps): JSX.Element => {

  // load all CDP for tenant
  const [{ data, loading: loading }] = useCustomDataPoints({ skip: 0, take: 9999999, isActive: true });
  const options: IDropdownValue[] = data?.records.map((cdp) => mapToDropdownValue(cdp)) ?? [];

  return (
    <Autocomplete
      label=""
      value={value}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      loading={loading}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      multiple
    />
  );
};

export default CustomDataPointsDropdown;
