import { ITestNumbersViewRecord } from '@shared/models';
import { FastField, Formik } from 'formik';
import { TextInput } from '../shared/Form/TextInput';
import { IHealthCheckNumberForm, testNumberFormSchema } from './types';

interface ITestNumberFormProps {
  item?: ITestNumbersViewRecord;
  onSubmit: (values: any) => void;
}

const HealthCheckNumberForm = ({ item, onSubmit }: ITestNumberFormProps): JSX.Element => {
  const initialFormState: IHealthCheckNumberForm = {
    name: item?.name ?? '',
    number: item?.phoneNumber ?? undefined,
  };

  const isReadOnly: boolean = item !== null && item !== undefined;

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={testNumberFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="test-number-form" onSubmit={handleSubmit} className="space-y-2">
              <FastField
                component={TextInput}
                id="name"
                name="name"
                label="Name"
                disabled={isReadOnly}
                value={values.name}
                error={touched.name ? errors.name : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <FastField
                component={TextInput}
                id="number"
                name="number"
                label="Number"
                type="number"
                disabled={isReadOnly}
                value={values.number}
                error={touched.number ? errors.number : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default HealthCheckNumberForm;
