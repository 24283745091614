import { Auth0Role, IClientEntity, IKeywordEntity } from '@shared/models';
import useRoles from '../../hooks/useRoles';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize } from '../shared/DetailsPanel/types';
import ClientsForm, { IClientForm } from './ClientForm';

interface IClientPanelProps {
  show: boolean;
  loading?: boolean;
  deleteLoading?: boolean;
  selectedItem?: IClientEntity;
  onClosePanel: () => void;
  handleSubmit: (formData: IClientForm) => Promise<void>;
  handleDelete: (id: string) => Promise<void>;
  keywords: IKeywordEntity[];
}

const ClientDetailsPanel = ({
  show,
  loading,
  deleteLoading,
  selectedItem,
  onClosePanel,
  handleSubmit,
  handleDelete,
  keywords,
}: IClientPanelProps): JSX.Element => {
  const allowDelete = !!selectedItem?.id && useRoles([Auth0Role.A2P_ADMIN]);
  const allowEdit = useRoles([Auth0Role.A2P_ADMIN]);

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'Save Client' : 'Add Client'}
      formId="client-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      leftPanelButtonOptions={[
        {
          text: 'Delete',
          onClick: () => handleDelete(selectedItem?.id ?? ''),
          visible: allowDelete,
          loading: deleteLoading
        },
      ]}
      isReadOnly={!allowEdit}
    >
      <>
        <ClientsForm keywords={keywords} item={selectedItem} onSubmit={handleSubmit} readOnly={!allowEdit} />
      </>
    </DetailsPanel>
  );
};

export default ClientDetailsPanel;
