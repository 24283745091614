import { Menu, Transition } from '@headlessui/react';
import { uuid } from '@shared/services';
import { Fragment } from 'react';
import { More } from '../Icons/More';

export interface IMenuItem {
  label: string;
  action: (row?: any) => void;
}

interface IActionsProps {
  row: any;
  items: IMenuItem[];
}

export const Actions = ({ row, items }: IActionsProps) => {
  return <Menu as="div" className="relative flex pr-1">
    <div className='flex ml-auto'>
      <Menu.Button className={"px-2"}>
        <More />
      </Menu.Button>
    </div>
    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="absolute right-0 z-10 w-56 mr-6 -mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">

        {items.map((item, index) => {
          return <div className="" key={uuid()}>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${active ? 'bg-slate-700 text-white' : 'text-gray-900'
                    } group flex w-full items-center rounded-md p-2.5 text-sm`}
                  onClick={() => item.action(row)}
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          </div>;
        })}
      </Menu.Items>
    </Transition>
  </Menu>;
};