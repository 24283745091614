import { IGetKeywordsRequest, IHttpResponse, IKeywordEntity } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useKeywords(request?: IGetKeywordsRequest): UseAxiosResult<IHttpResponse<IKeywordEntity[]>> {
  return useAxios<IHttpResponse<IKeywordEntity[]>>({
    url: `clients/${request?.clientId}/keywords`,
    method: 'GET',
  }, {
    manual: true,
  });
}
