import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { IHttpResponse, IOptoutsGetUploadUrlRequest } from '@shared/models';
import axios from 'axios';
import { Fragment, useState } from 'react';
import { axiosGet } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import UploadOptOutForm, { IOptOutUploadForm } from './UploadOptOutForm';

interface IUploadOptOutModalProps {
  show: boolean;
  setShow: any;
}

const UploadOptOutModal = ({ show, setShow }: IUploadOptOutModalProps) => {
  const { addNotification } = useNotifications();
  const [uploadingFile, setUploadingFile] = useState(false);
  const { selectedClientId } = useClientContext();

  const uploadOptOuts = async (formData: IOptOutUploadForm) => {
    try {
      setUploadingFile(true);
      const file = formData.optouts as File;

      const request: IOptoutsGetUploadUrlRequest = {
        originalFileName: file?.name,
        clientId: selectedClientId ?? '',
        isGlobal: formData?.isGlobal ?? false
      };

      const response = await axiosGet<IHttpResponse<string>>('/optouts-upload-url', { params: request });
      const uploadUrl = response?.data;
      await axios.put(uploadUrl, file, { headers: { 'Content-Type': 'text/csv' }, });

      setShow(false);

      addNotification({
        header: 'Opt-outs uploaded successfully!',
        content: `You will receive an email once the opt-outs have been added to the system.`,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setUploadingFile(false);
      setShow(false);
    }
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          {/* <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span> */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                disabled={uploadingFile}
                onClick={() => setShow(false)}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optouts-modal-title">Upload Opt-outs</span>
              </Dialog.Title>
              <div className="mt-2">
                <UploadOptOutForm onSubmit={uploadOptOuts} loading={uploadingFile} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UploadOptOutModal;
