import { ICreateJourneyRequest, IHttpResponse } from '@shared/models';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { axiosGet, axiosPost } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { NewButton } from '../shared/Buttons/NewButton';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { InformationIcon } from '../shared/Icons/InformationIcon';
import JourneyCanvas from './JourneyCanvas';
import { JourneyCard } from './JourneyCard';
import JourneyForm from './JourneyForm';
import { IJourneyForm, actionsCards, rulesCards } from './types';

const JourneyDetails = () => {
  const [journey, setJourney] = useState<any>();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingJourney, setLoadingJourney] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { selectedClientId } = useClientContext();

  const getJourney = async () => {
    const response = await axiosGet<IHttpResponse<any>>(`/journeys/${id}`);
    if (response?.data) {
      setJourney(response.data);
    }
  };

  useEffect(() => {
    if (id && id !== 'add') {
      getJourney();
    }
  }, [id]);

  const handleSave = async () => {
    // DO SAVE
    navigate('..', { relative: 'path' });
  };

  const handlePublish = async () => {
    // DO PUBLISH
  };

  const handleSubmit = async (formData: IJourneyForm) => {
    setLoadingSubmit(true);
    try {
      const request: ICreateJourneyRequest = {
        clientId: selectedClientId ?? '',
        title: formData.title ?? '',
        audienceId: formData.audience?.value ?? '',
        startDate: formData.startDate.toISOString(),
        endDate: formData.endDate.toISOString()
      };
      const response = await axiosPost<IHttpResponse<string>>('/journeys', request);
      if (response && response?.data) {
        setJourney({
          id: response,
          name: request.title,
          ...request
        });
        navigate(`/journeys/${response?.data}`);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoadingSubmit(false);
    }
  };
  return <div className='flex flex-col h-full'>
    {id === 'add' ?
      <div className='flex items-center justify-between'>
        <h1>New Journey</h1>
        <NewButton onClick={() => navigate('..', { relative: 'path' })} variant={ButtonVariantEnum.SECONDARY}>BACK</NewButton>
      </div>
      :
      <div className='flex items-center justify-between'>
        <h1>{journey?.name}</h1>
        <div>
          <NewButton className='mr-4' onClick={handleSave} variant={ButtonVariantEnum.SECONDARY}>SAVE DRAFT & EXIT</NewButton>
          <NewButton onClick={handlePublish} variant={ButtonVariantEnum.PRIMARY}>PUBLISH</NewButton>
        </div>
      </div>}
    {id === 'add' ? <div className='flex flex-col items-center space-y-8 mt-14'>
      <div className='text-center'>
        <h2 className='mb-2 font-bold'>Create New Journey</h2>
        <div className='body-text-regular text-dark-gray'>Design your contacts’ journey by plotting points of interaction to communicate with them every step of the way.</div>
      </div>
      <div className='w-full'>
        <JourneyForm loadingSubmit={loadingSubmit} handleSubmit={handleSubmit} />
      </div>
    </div> :
      <div className='flex flex-col h-full'>
        <div className='flex-1'>
          <JourneyCanvas />
        </div>
        <div className='inline-flex py-6 pl-12 pr-8 mt-4 mb-2 mx-auto bg-white rounded-2.5xl'>
          <div>
            <h3 className='flex items-center mb-4 font-bold basis-full'>
              <span className='mr-2'>Rules</span>
              <InformationIcon />
            </h3>
            <div className='flex'>
              {rulesCards.map(card => <JourneyCard key={card.text} icon={card.icon} text={card.text} />)}
            </div>
          </div>
          <div className='ml-8 mr-12 -my-6 border-l-4' />
          <div>
            <h3 className='flex items-center mb-4 font-bold basis-full'>
              <span className='mr-2'>Actions</span>
              <InformationIcon />
            </h3>
            <div className='flex'>
              {actionsCards.map(card => <JourneyCard key={card.text} icon={card.icon} text={card.text} />)}
            </div>
          </div>
        </div>
      </div>}
  </div>;
};

export default JourneyDetails;