import { MessageTypeEnum } from '@shared/enums';
import { getSMSMessageLength, getSMSMessageSegmentCount } from '@shared/services';
import { CompositeDecorator, ContentBlock, ContentState, Editor, EditorState } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { SyntheticEvent, useEffect, useState } from 'react';
import { ErrorMessage } from './ErrorMessage';
import Tag from './Tag';
import Tags from './Tags';

interface ITextAreaTagsProps {
  value: any;
  onChange: any;
  onBlur: any;
  messageType: any;
  error: any;
  domain?: string;
  readOnly?: boolean;
  errorMessageClassName?: string;
}

const TAG_REGEX = /{{.*?}}/gm;

const tagStrategy = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  const text = contentBlock.getText();
  let matchArr, start;
  while ((matchArr = TAG_REGEX.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const compositeDecorator = new CompositeDecorator([
  {
    strategy: tagStrategy,
    component: Tag,
  },
]);

const TextAreaTags = ({ value, onChange, onBlur, messageType, error, domain, readOnly, errorMessageClassName }: ITextAreaTagsProps) => {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createWithContent(ContentState.createFromText(value ?? ''), compositeDecorator)
  );
  const onTagClick = (e: SyntheticEvent) => {
    setEditorState(
      EditorState.push(
        editorState,
        ContentState.createFromText(
          editorState.getCurrentContent().getPlainText() +
          '{{' +
          e.currentTarget.textContent?.toLocaleLowerCase() +
          '}}'
        ),
        'insert-characters'
      )
    );
  };
  useEffect(() => {
    onChange(editorState.getCurrentContent().getPlainText());
  }, [editorState.getCurrentContent()]);
  return (
    <div>
      {!readOnly && <Tags onClick={onTagClick} />}
      <label className="block text-sm font-medium text-gray-700 dark:text-slate-400">Message</label>
      <Editor
        placeholder="Enter your message here..."
        editorState={editorState}
        onChange={setEditorState}
        onBlur={onBlur}
        readOnly={readOnly}
        stripPastedStyles={true}
      />
      <div className="flex justify-between">
        <ErrorMessage show={!!error} message={error} className={errorMessageClassName} />
        <p className="mt-2 ml-auto text-sm text-gray-500 dark:text-slate-400">
          Segment count:{' '}
          {messageType?.value === MessageTypeEnum.MMS
            ? 1
            : getSMSMessageSegmentCount(
              value?.toLowerCase()?.replace(/{{link}}/gi, `${domain || 'tenletters'}/aaaaaa`)
            )}{' '}
          | Character count{' '}
          {getSMSMessageLength(
            value?.toLowerCase()?.replace(/{{link}}/gi, `${domain || 'tenletters'}/aaaaaa`)
          )}
          /{messageType?.value === MessageTypeEnum.MMS ? 1600 : 1000}
        </p>
      </div>
    </div>
  );
};

export default TextAreaTags;
