import { addMinutes, roundToNearestMinutes, subMinutes } from 'date-fns';
import { DateTime } from 'luxon';

export function getPipeStartTimestamp(dateTime: DateTime): string {
  const day = dateTime.day;
  const month = dateTime.month;

  const date = new Date();
  date.setMonth(month - 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(day)}T09:59:00.000Z`;
}

export function getPipeEndTimestamp(dateTime: DateTime): string {
  const day = dateTime.day;
  const month = dateTime.month;

  const date = new Date();
  date.setMonth(month - 1);
  date.setDate(day + 1);

  return `${date.getFullYear()}-${addLeadingZeroToNumber(date.getMonth() + 1)}-${addLeadingZeroToNumber(date.getDate())}T05:00:00.000Z`;
}

export function getSendCurrentHour(dateTime: DateTime) {
  return dateTime.hour;
}

export function getLocalDate() {
  return DateTime.local({ zone: 'America/New_York' });
}

export function roundToNextQuarterHour(date: Date): Date {
  const nearestQuarter = roundToNearestMinutes(date, { nearestTo: 15 });
  const nextQuarter = roundToNearestMinutes(addMinutes(date, 10), { nearestTo: 15 });
  return date > nearestQuarter ? nextQuarter : nearestQuarter;
};

export function getCampaignStartDate(): Date {
  return roundToNextQuarterHour(new Date());
};

export function getCampaignEndDateForStartDate(dateTime: DateTime, startDate: Date) {
  const startDateEst = DateTime.fromJSDate(startDate, { zone: 'America/New_York' });
  const estOffset = Math.abs(dateTime.offset / 60);
  const localOffset = startDate.getTimezoneOffset() / 60;

  const date = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDateEst.day + 1, localOffset - (localOffset - estOffset), 0));

  // To skip confusion over midnight
  if (date.getHours() === 0) {
    return subMinutes(date, 1);
  }

  return date;
};

function addLeadingZeroToNumber(data: number): string {
  const str = data?.toString();
  return str?.length > 1 ? str : `0${str}`;
}