import { FieldTypeEnum } from '@shared/enums';
import { convertEnumToReadableString } from 'apps/a2p-portal/src/providers/utility.provider';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IFieldTypeDropdownProps {
  value: FieldTypeEnum;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const FieldTypeDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IFieldTypeDropdownProps): JSX.Element => {
  const allowedFieldTypes = [FieldTypeEnum.STRING, FieldTypeEnum.NUMBER, FieldTypeEnum.BOOLEAN, FieldTypeEnum.DATE];
  const fieldTypes = Object.entries(FieldTypeEnum)
    .filter(([_k, value]) => allowedFieldTypes.includes(value))
    .map(([label, value]) => {
      if (value === FieldTypeEnum.STRING) {
        return {
          label: 'Text',
          value,
        };
      } else {
        return {
          label: convertEnumToReadableString(label),
          value,
        };
      }
    });
  return (
    <Autocomplete
      label="Field Type"
      value={fieldTypes.find((ft) => ft.value === value)}
      options={fieldTypes}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      search={false}
    />
  );
};

export default FieldTypeDropdown;
