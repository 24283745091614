import { IHttpPaginatedResponse, ISearchRequest, ITCRCampaignDetails } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';
import { useClientContext } from '../contexts/ClientContext';

export default function useTCRCampaigns(search: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<ITCRCampaignDetails>> {
  const { selectedClient } = useClientContext();

  return useAxios<IHttpPaginatedResponse<ITCRCampaignDetails>>({
    url: `tcr-campaigns`,
    method: 'GET',
    params: {
      skip: search.pagination.skip,
      take: search.pagination.take,
      clientId: selectedClient?.value,
      providerName: getFilterValue(search, 'providerName')
    }
  });
}
