import { useAuth0 } from '@auth0/auth0-react';
import { Auth0Role, Auth0UserDetails, IClientEntity } from '@shared/models';
import { useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useRoles from '../../hooks/useRoles';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import UserForm from './UserForm';
import { IUserForm } from './types';

interface IUserDetailsPanelProps {
  show: boolean;
  loading: boolean;
  item?: Auth0UserDetails;
  assignedClients: IClientEntity[] | undefined;
  deleteLoading: boolean;
  handleDelete: (item: Auth0UserDetails) => Promise<void>;
  closePanel: () => void;
  onSubmit: (formData: IUserForm) => Promise<void>;
}

const UserDetailsPanel = ({
  show,
  loading,
  item,
  deleteLoading,
  handleDelete,
  closePanel,
  onSubmit,
  assignedClients
}: IUserDetailsPanelProps): JSX.Element => {
  const { user: auth0User } = useAuth0();
  const { addNotification } = useNotifications();

  const [resendLoading, setResendLoading] = useState(false);
  const [resetMfaLoading, setResetMfaLoading] = useState(false);

  const deleteAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const resendEmailAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const resetMfaAllowed = useRoles([Auth0Role.A2P_SUPER_ADMIN]);

  const resendEmail = async (item: Auth0UserDetails) => {
    try {
      setResendLoading(true);
      await axiosPost('/auth0-send-user-welcome-email', { user_id: item.user_id });
      closePanel();
      addNotification({
        content: 'Email successfully sent.',
        type: NotificationType.SUCCESS,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setResendLoading(false);
    }
  };

  const resetMfa = async (item: Auth0UserDetails) => {
    try {
      setResetMfaLoading(true);
      await axiosPost('/auth0-reset-mfa', { user_id: item.user_id });
      closePanel();
      addNotification({
        content: 'Reset MFA Email successfully sent.',
        type: NotificationType.SUCCESS,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setResetMfaLoading(false);
    }
  };

  return (
    <DetailsPanel
      item={item}
      title={!!item ? 'Edit User' : 'Invite User'}
      formId="user-form"
      show={show}
      closePanel={closePanel}
      loading={loading}
      leftPanelButtonOptions={[
        {
          text: 'Remove User',
          visible: !!item && deleteAllowed,
          onClick: () => handleDelete(item!),
          variant: ButtonVariantEnum.DELETE,
          hideConfirmation: false,
          loading: deleteLoading,
        },
        {
          text: 'Resend Email',
          visible: !!item && resendEmailAllowed,
          onClick: () => resendEmail(item!),
          variant: ButtonVariantEnum.SECONDARY,
          hideConfirmation: true,
          loading: resendLoading,
        },
        {
          text: 'Reset MFA',
          visible: !!item && resetMfaAllowed,
          onClick: () => resetMfa(item!),
          variant: ButtonVariantEnum.TERTIARY,
          hideConfirmation: true,
          loading: resetMfaLoading,
        },
      ]}
    >
      <UserForm
        user={item}
        auth0User={auth0User}
        assignedClients={assignedClients}
        onSubmit={onSubmit}
      />
    </DetailsPanel>
  );
};

export default UserDetailsPanel;
