import { FieldTypeEnum } from '@shared/enums';
import { ICustomDataPointEntity } from '@shared/models';
import { RelativeDate } from '@shared/services';
import { isValid, parseISO } from 'date-fns';
import { RuleGroupTypeAny, RuleType, defaultRuleProcessorMongoDB, formatQuery } from "react-querybuilder";
import { QueryBuilderOperator } from '../components/shared/Form/QueryBuilder/types';

export function formatBackendMongoDBQuery(filter: RuleGroupTypeAny, customDataPoints: ICustomDataPointEntity[]) {
  if (filter?.rules?.length > 0) {
    return JSON.parse(formatQuery(filter, {
      format: 'mongodb',
      parseNumbers: true,
      ruleProcessor: (rule: RuleType) => processRule(rule, customDataPoints)
    }));
  }

  // All contacts
  return { $expr: true };
}

function processRule(rule: RuleType, customDataPoints: ICustomDataPointEntity[]): string {
  const field = rule.field.toLowerCase().trim();
  const customDataPoint = customDataPoints.find(cdp => cdp?.name?.toLowerCase()?.trim() === field);

  switch (customDataPoint?.fieldType) {
    case FieldTypeEnum.NUMBER:
      return JSON.stringify(getNumberRule(rule, field, customDataPoint));
    case FieldTypeEnum.STRING:
    case FieldTypeEnum.BOOLEAN:
    case FieldTypeEnum.ENUM:
      return JSON.stringify(getRegexRule(rule, field));
    case FieldTypeEnum.DATE:
      return JSON.stringify(getDateRule(rule, field, customDataPoint));
    default:
      return defaultRuleProcessorMongoDB({ ...rule, field });
  }
}

function getRegexRule(rule: RuleType, field: string): any {
  const operator = <QueryBuilderOperator>rule.operator;

  switch (operator) {
    case 'in':
    case 'contains':
      return { [field]: { $regex: `.*${rule.value}.*`, $options: 'i' } };
    case 'beginsWith':
      return { [field]: { $regex: `^${rule.value}`, $options: 'i' } };
    case 'endsWith':
      return { [field]: { $regex: `${rule.value}$`, $options: 'i' } };
    case '=':
      return { [field]: { $regex: `^${rule.value}$`, $options: 'i' } };
    default:
      return { [field]: { $regex: `^${rule.value}`, $options: 'i' } };
  }
}

function getNumberRule(rule: RuleType, field: string, customDataPoint: ICustomDataPointEntity): any {
  const personalized = customDataPoint?.personalized ?? false;
  const comparison = getNumberComparisonQuery(rule, field, customDataPoint);

  if (!personalized) {
    return { $and: [{ [field]: { $exists: true } }, comparison] };
  }

  // TODO: Might be able to speed up personalized searches using $ifNull
  // https://www.mongodb.com/docs/manual/reference/operator/aggregation/ifNull/
  return comparison;
}

function getDateRule(rule: RuleType, field: string, customDataPoint: ICustomDataPointEntity): any {
  const operator = <QueryBuilderOperator>rule?.operator;

  const defaultValue = customDataPoint?.personalized ? parseIsoString(customDataPoint?.defaultValue) : null;
  const castedField = { $convert: { input: `$${field}`, to: 'string', onError: defaultValue } };

  // The backend specifically looks for this property '_relativeDate'
  // See: contacts-search-relative-date.service.ts

  switch (operator) {
    case '<':
      return { $expr: { $lt: [castedField, parseIsoString(rule.value)] } };
    case '<=':
      return { $expr: { $lte: [castedField, parseIsoString(rule.value)] } };
    case '>':
      return { $expr: { $gt: [castedField, parseIsoString(rule.value)] } };
    case '>=':
      return { $expr: { $gte: [castedField, parseIsoString(rule.value)] } };
    case '=':
      return { $expr: { $eq: [castedField, parseIsoString(rule.value)] } };
    case '!=':
      return { $expr: { $ne: [castedField, parseIsoString(rule.value)] } };
    case 'gteDaysAgo':
      return { $expr: { $and: [{ $ne: [castedField, null] }, { $gte: [castedField, { _relativeDate: <RelativeDate>{ days: -parseInt(rule?.value ?? 0) } }] }] } };
    case 'lteDaysAgo':
      return { $expr: { $and: [{ $ne: [castedField, null] }, { $lte: [castedField, { _relativeDate: <RelativeDate>{ days: -parseInt(rule?.value ?? 0) } }] }] } };
    case 'gteDaysFromNow':
      return { $expr: { $and: [{ $ne: [castedField, null] }, { $gte: [castedField, { _relativeDate: <RelativeDate>{ days: parseInt(rule?.value ?? 0) } }] }] } };
    case 'lteDaysFromNow':
      return { $expr: { $and: [{ $ne: [castedField, null] }, { $lte: [castedField, { _relativeDate: <RelativeDate>{ days: parseInt(rule?.value ?? 0) } }] }] } };
    case 'between': {
      const fromValue = parseIsoString(rule?.value?.[0]);
      const toValue = parseIsoString(rule?.value?.[1]);

      return {
        $and: [
          { $expr: { $gte: [castedField, fromValue] } },
          { $expr: { $lte: [castedField, toValue] } }
        ]
      };
    }

    default:
      return { $expr: false };
  }
}

function getNumberComparisonQuery(rule: RuleType, field: string, customDataPoint: ICustomDataPointEntity) {
  const operator = <QueryBuilderOperator>rule.operator;

  const defaultValue = customDataPoint?.personalized ? parseNumber(customDataPoint?.defaultValue) : null;
  const castedField = { $convert: { input: `$${field}`, to: 'decimal', onError: defaultValue } };

  switch (operator) {
    case '<':
      return { $expr: { $lt: [castedField, parseInt(rule.value)] } };
    case '<=':
      return { $expr: { $lte: [castedField, parseInt(rule.value)] } };
    case '>':
      return { $expr: { $gt: [castedField, parseInt(rule.value)] } };
    case '>=':
      return { $expr: { $gte: [castedField, parseInt(rule.value)] } };
    case '=':
      return { $expr: { $eq: [castedField, parseInt(rule.value)] } };
    case '!=':
      return { $expr: { $ne: [castedField, parseInt(rule.value)] } };
    case 'between': {
      const fromValue = parseInt(rule?.value?.[0]);
      const toValue = parseInt(rule?.value?.[1]);

      return {
        $and: [
          { $expr: { $gte: [castedField, fromValue] } },
          { $expr: { $lte: [castedField, toValue] } }
        ]
      };
    }
    default:
      return { $expr: false };
  }
}

function parseNumber(value: any) {
  try {
    return value ? parseInt(value) : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}

function parseIsoString(value: any) {
  try {
    const parsed = parseISO(value);
    return parsed && isValid(parsed) ? parsed.toISOString() : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}