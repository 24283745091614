import { XIcon } from '@heroicons/react/solid';

const Tag = (props: any) => {
  const isLink = props?.decoratedText?.toLowerCase()?.trim() === '{{link}}';
  return (
    <span
      className={`inline-flex rounded-full items-center py-1 px-2 text-sm font-medium ${isLink ? 'bg-cyan-100' : 'bg-emerald-100'} ${isLink ? 'text-cyan-700' : 'text-emerald-700'} shadow`}
    >
      {props.children}
      {!!props.onRemove && (
        <button
          type="button"
          className={`flex-shrink-0 ml-1.5 h-4 w-4 rounded-full inline-flex items-center justify-center ${isLink ? 'text-cyan-400' : 'text-emerald-400'} ${isLink ? 'hover:bg-cyan-200' : 'hover:bg-emerald-200'} ${isLink ? 'hover:text-cyan-500' : 'hover:text-emerald-500'} focus:outline-none ${isLink ? 'focus:bg-cyan-500' : 'focus:bg-emerald-500'} focus:text-white`}
          onClick={() => {
            !!props.onRemove && props.onRemove();
          }}
        >
          <>
            <span className="sr-only">Remove filter</span>
            <XIcon />
          </>
        </button>
      )}
    </span>
  );
};

export default Tag;
