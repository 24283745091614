import { ButtonVariantEnum } from '../Buttons/types';

export enum DetailsPanelSize {
  Small,
  Medium,
  Large,
}

export interface IButtonOptions {
  text: string;
  visible?: boolean;
  variant?: ButtonVariantEnum;
  loading?: boolean;
  hideConfirmation?: boolean;
  onClick?: () => void;
}
