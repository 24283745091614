import { ActionWithRulesAndAddersProps, RuleGroupTypeIC, RuleOrGroupArray } from 'react-querybuilder';
import { Button } from '../../Buttons/Button';
import { ButtonVariantEnum } from '../../Buttons/types';

export interface ICustomAddGroupButtonProps extends ActionWithRulesAndAddersProps {
  query: RuleGroupTypeIC;
  maxRules?: number;
  rules?: RuleOrGroupArray;
  disabled?: boolean;
  handleOnClick(e: any, context?: any): void;
  level: number;
}

export const CustomAddGroupButton = (props: ICustomAddGroupButtonProps) => {
  return (
    <Button
      disabled={props.level > 0 || props.query.rules.length >= 5 || props.disabled}
      variant={ButtonVariantEnum.SECONDARY}
      onClick={props.handleOnClick}
    >
      Add Query
    </Button>
  );
};
