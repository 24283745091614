import { LogoutOptions, User } from '@auth0/auth0-react';
import { Auth0AppMetadata, Auth0CreateUserRequest, Auth0GetUserDetailsRequest, Auth0OrganizationName, Auth0Role, Auth0UpdateUserRequest, Auth0User, Auth0UserDetails, Auth0UserMembership, IHttpResponse } from '@shared/models';
import { axiosPost } from '../authAxios';
import { IUserForm } from '../components/AdminSettings/types';
import { getAuth0Organization, getSubDomainFromOrg } from './sub-domain.service';

export type Auth0Logout = (options?: LogoutOptions | undefined) => void;

export const auth0Scope = "openid profile email";
export const auth0Audience = "a2p";

export interface Auth0LogoutRequest {
  auth0Logout: Auth0Logout;
  auth0User: User | undefined;
}

export function logout({ auth0Logout, auth0User }: Auth0LogoutRequest) {
  const org_id = auth0User?.org_id ?? undefined;
  auth0Logout({ returnTo: `${window.location.origin}?organization=${org_id}` });
}

export function getUserMemberships(auth0User: User | undefined): Auth0UserMembership[] {
  return auth0User?.app_metadata?.memberships ?? [];
}

export async function getUserDetails(request: Auth0GetUserDetailsRequest) {
  const response = await axiosPost<IHttpResponse<Auth0UserDetails>>('/auth0-get-user-details', request);
  return response.data;
}

export async function createUser(form: IUserForm) {
  const request: Auth0CreateUserRequest = {
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        organization: form.organization.value,
        roles: [form.role.value],
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-create-user', request);
}

export async function updateUser(details: Auth0UserDetails, form: IUserForm) {
  const request: Auth0UpdateUserRequest = {
    user_id: details.user_id,
    email: form.email.trim(),
    firstName: form.firstName.trim(),
    lastName: form.lastName.trim(),
    memberships: [
      {
        organization: form.organization.value,
        roles: [form.role.value],
      },
    ],
    clientIds: form.clients.map(c => c.value)
  };

  await axiosPost<IHttpResponse<string>>('/auth0-update-user', request);
}

export function getAllowedSubDomains(memberships: Auth0UserMembership[]) {
  return memberships
    .filter((membership) => Object.values(Auth0OrganizationName).includes(membership.organization))
    .flatMap((membership) => getSubDomainFromOrg(membership.organization))
    .filter(v => v);
}

export function isAdmin(user?: User) {
  const organization = getAuth0Organization();
  const app_metadata = user?.app_metadata as Auth0AppMetadata;
  const membership = app_metadata?.memberships.find((membership) => membership?.organization === organization);
  return membership?.roles?.some((role) => [Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]?.includes(role)) ?? false;
}

export function getAppRoles(roles: Auth0Role[] = []) {
  return roles?.filter((role: Auth0Role) => Object.values(Auth0Role).includes(role)) ?? [];
}

export function getUserRole(user?: Auth0UserDetails | Auth0User) {
  const app_metadata: Auth0AppMetadata = <any>user?.app_metadata;
  const membership = app_metadata?.memberships.find(m => m.organization === getAuth0Organization());
  return getAppRoles(membership?.roles)?.[0];
}
