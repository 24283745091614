import { IAudienceEntity } from '@shared/models';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import { AudienceForm } from './AudienceForm';
import { IAudienceForm } from './types';

interface AudienceDetailsPanel {
  show: boolean;
  loading: boolean;
  selectedItem: IAudienceEntity | undefined;
  deleteLoading: boolean;
  onClosePanel: () => void;
  handleSubmit: (formData: IAudienceForm) => void;
  handleDelete?: () => Promise<void>;
}

export const AudienceDetailPanel = ({
  show,
  loading,
  selectedItem,
  onClosePanel,
  handleSubmit,
  handleDelete,
  deleteLoading,
}: AudienceDetailsPanel) => {
  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: 'Delete',
      onClick: handleDelete,
      visible: !selectedItem?.readOnly,
      variant: ButtonVariantEnum.DELETE,
      loading: deleteLoading,
    },
  ];
  return (
    <DetailsPanel
      item={selectedItem}
      size={DetailsPanelSize.Large}
      show={show}
      isReadOnly={selectedItem?.readOnly}
      loading={loading}
      formId="audience-form"
      closePanel={onClosePanel}
      title={!!selectedItem ? 'Edit Audience' : 'Add Audience'}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <AudienceForm selectedItem={selectedItem} handleSubmit={handleSubmit} />
    </DetailsPanel>
  );
};
