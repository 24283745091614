import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ICreateTestNumberRequest,
  IHttpResponse,
  ISearchFilter,
  ISearchRequest,
  ITestNumbersViewRecord
} from '@shared/models';
import useAxios from 'axios-hooks';
import { useState } from 'react';
import { axiosDelete } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { useNotifications } from '../../contexts/NotificationContext';
import useTestNumbers from '../../hooks/useTestNumbers';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import HealthCheckNumberDetailsPanel from './HealthCheckNumberDetailsPanel';
import {
  IHealthCheckNumberForm,
  defaultTestNumberTableOptions,
  getColumns,
} from './types';

const HealthCheckNumbers = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const { selectedClientId } = useClientContext();
  const [searchText, setSearchText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedItem, setSelectedItem] = useState<ITestNumbersViewRecord>();
  const [tableOptions, setTableOptions] = useState(defaultTestNumberTableOptions);
  const { addNotification } = useNotifications();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [{ data, loading, error }, refetch] = useTestNumbers(tableOptions);

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  const [{ data: createData, loading: createLoading, error: createError }, createTestNumber] = useAxios<
    IHttpResponse<string>
  >(
    {
      url: 'test-numbers',
      method: 'POST',
    },
    { manual: true }
  );

  const healthCheckNumbersNumbers: ITestNumbersViewRecord[] = data?.records ?? [];

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  const columns = getColumns(
    {
      name: (item: ITestNumbersViewRecord) => {
        openDetailsPanel(item);
      },
    },
    {
      number: (item: ITestNumbersViewRecord) => {
        const numberAsString = item.phoneNumber.toString();
        return (
          <>{`(${numberAsString.substring(0, 3)}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(6)}`}</>
        );
      },
    }
  );

  const openDetailsPanel = (item?: ITestNumbersViewRecord) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const handleSubmit = async (formData: IHealthCheckNumberForm) => {
    const request: ICreateTestNumberRequest = {
      name: formData.name,
      phoneNumber: formData.number!,
      clientId: selectedClientId ?? ''
    };

    try {
      await createTestNumber({ data: { ...request, } });

      closeDetailsPanel();

      addNotification({
        header: 'Health Check Number saved successfully',
      });

      handleRefetch();
    } catch (error) {
      console.error(error);
      if (error instanceof Error && error.message.includes('500')) {
        setErrorMessage('Unable to create health check numbers');
      } else {
        closeDetailsPanel();
      }
    }
  };

  const handleDelete = async () => {
    if (!selectedItem?.id) {
      return;
    }

    setDeleteLoading(true);

    try {
      await axiosDelete(`/test-numbers/${selectedItem.id}`);

      closeDetailsPanel();

      handleRefetch();

      addNotification({ header: 'Health Check Number deleted successfully' });
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const filterColumns: IColumn[] = columns.filter((col) => ['name', 'clientName'].includes(col.fieldName));

  const handleSearch = (overrideText?: string) => {
    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.STRING,
      fieldName: 'name',
      operator: FilterOperatorEnum.CONTAINS,
      value: searchText,
    };

    if (overrideText !== null && overrideText !== undefined) {
      setSearchText(overrideText);
      searchFilter.value = overrideText;
    }

    const newSearchOptions = { ...defaultTestNumberTableOptions };

    const nonSearchFilters = tableOptions.filters.filter((filter) => filter.fieldName !== 'name');

    newSearchOptions.filters = searchFilter.value ? [...nonSearchFilters, searchFilter] : [...nonSearchFilters];

    setTableOptions(newSearchOptions);
  };

  return (
    <>

      <div className="flex justify-between pb-2">
        <h2 id="test-number-title" className="pb-2 dark:text-white">
          Test Numbers
        </h2>
        <Button
          id="test-number-add"
          className="self-end"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add Test Number
        </Button>
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={healthCheckNumbersNumbers}
        count={data?.totalCount ?? 0}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        filterColumns={filterColumns}
        onRefresh={handleRefetch}
      />

      <HealthCheckNumberDetailsPanel
        show={showDetailsPanel}
        loading={createLoading}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        leftPanelButtonOptions={[
          {
            text: 'Delete',
            visible: !!selectedItem?.id,
            variant: ButtonVariantEnum.DELETE,
            loading: deleteLoading,
            onClick: handleDelete,
          },
        ]}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default HealthCheckNumbers;
