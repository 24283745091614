import { EntityName } from '@shared/enums';
import {
  FilterDataTypeEnum,
  FilterOperatorEnum,
  ISearchRequest,
  ITCRCampaignDetails,
  SearchSortDirectionEnum
} from '@shared/models';
import * as yup from 'yup';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const defaultTCRCampaignTableOptions = {
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export function defaultSystemNumberDropdownOptions(selectedItem: ITCRCampaignDetails | undefined): ISearchRequest {
  return {
    entity: EntityName.LOCAL_SYSTEM_NUMBERS,
    filters: [
      {
        dataType: FilterDataTypeEnum.STRING,
        fieldName: 'tcrCampaignId',
        operator: FilterOperatorEnum.EQUALS,
        value: selectedItem?.id,
      },
      {
        dataType: FilterDataTypeEnum.BOOLEAN,
        fieldName: 'isActive',
        operator: FilterOperatorEnum.EQUALS,
        value: true,
      },
    ],
    pagination: {
      skip: 0,
      take: 50,
    },
    sort: {
      fieldName: 'createdAt',
      sortDirection: SearchSortDirectionEnum.DESC,
    },
  };
}

export const getColumns = (columnClickEvents: IColumnClickConfig): IColumn[] => {
  return [
    {
      fieldName: 'tcrCampaignId',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'TCR Id',
      isRowHeader: true,
      onColumnClick: columnClickEvents['tcrCampaignId'],
    },
    {
      fieldName: 'description',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'Description',
    },
    {
      fieldName: 'isActive',
      fieldType: FilterDataTypeEnum.BOOLEAN,
      headerName: 'Enabled',
    },
    {
      fieldName: 'providerName',
      fieldType: FilterDataTypeEnum.STRING,
      headerName: 'Provider',
    }
  ];
};

export interface ITCRCampaignForm {
  providerId: string;
}

export const tcrCampaignFormSchema: yup.SchemaOf<ITCRCampaignForm> = yup.object().shape({
  providerId: yup.string().defined('Required')
});

export const tcrCampaignDropdownOptions: IFilterDropdownOptions = {
  providerName: [],
};
