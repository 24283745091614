import { Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

interface CardProps {
  // action: Function;
  children?: React.ReactNode;
  title: string | React.ReactNode;
  subtitle?: string;
  small?: boolean;
}

const Card = ({ title, subtitle, children, small }: CardProps) => {
  const [showPanel, setShowPanel] = useState(false);
  return (
    <div className="mt-4 mb-2 bg-white shadow dark:border-2 dark:border-slate-800 dark:bg-slate-700 sm:rounded-lg">
      <div
        className={`${small ? 'px-3 py-2' : 'px-4 py-3 sm:px-6'}`}
        onClick={() => {
          setShowPanel(!showPanel);
        }}
      >
        <h3
          className={`${small ? 'text-sm' : 'text-lg'
            } font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer`}
        >
          {title}
        </h3>
        {!!subtitle && <p className="max-w-2xl mt-1 text-sm text-gray-500 dark:text-slate-400">{subtitle}</p>}
      </div>
      <Transition
        show={showPanel}
        as={Fragment}
        enter="ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="px-4 py-3 space-y-4 border-t border-gray-200 dark:border-slate-800">{children}</div>
      </Transition>
    </div>
  );
};

export default Card;
