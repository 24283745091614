import { useSuppressions } from 'apps/a2p-portal/src/hooks/useSuppressions';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface ISuppressionDropdownProps {
  value: IDropdownValue[];
  onChange: (newValue: IDropdownValue[] | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const SuppressionDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: ISuppressionDropdownProps): JSX.Element => {
  const [{ data: suppressionData, loading: suppressionLoading, error: suppressionError }] = useSuppressions();

  const suppressions: IDropdownValue[] =
    suppressionData?.records.map((suppression) => ({
      label: suppression.name ?? '',
      value: suppression.id ?? '',
    })) ?? [];

  return (
    <Autocomplete
      label="Suppression"
      value={value}
      options={suppressions}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      multiple
    />
  );
};

export default SuppressionDropdown;
