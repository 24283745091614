import { ITCRCampaignDetails } from '@shared/models';
import { Formik } from 'formik';
import { useClientContext } from '../../contexts/ClientContext';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ProviderDropdown from '../shared/Form/Dropdowns/ProviderDropdown';
import { ITCRCampaignForm, tcrCampaignFormSchema } from './types';

export interface ITCRCampaignFormProps {
  providers: IDropdownValue[];
  item?: ITCRCampaignDetails;
  onSubmit: (values: any) => void;
}

const TCRCampaignForm = ({ providers, item, onSubmit }: ITCRCampaignFormProps): JSX.Element => {
  const isReadOnly: boolean = !!item;
  const { selectedClientId } = useClientContext();
  const initialFormState: ITCRCampaignForm = {
    providerId: item?.providerId ?? ''
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={tcrCampaignFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleBlur, handleSubmit, handleChange, setFieldValue, setFieldTouched }) => (
          <>
            <form id="tcr-campaign-form" onSubmit={handleSubmit} className="space-y-2">
              <>
                <ProviderDropdown
                  value={values.providerId ?? ''}
                  onChange={(newValue) => {
                    setFieldValue('providerId', newValue?.value ?? '');
                  }}
                  disabled={isReadOnly}
                  onBlur={() => {
                    setFieldTouched('providerId', true);
                  }}
                  showError={!!(touched.providerId && errors.providerId)}
                  errorMessage={errors?.providerId}
                />
              </>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default TCRCampaignForm;
