import { Option, ValueSelectorProps } from 'react-querybuilder';
import { Dropdown } from '../Dropdown';

export const CustomCombinatorSelector = (props: ValueSelectorProps) => {
  const selectedOption = (props.options as Option[]).find((o) => o.name === props.value);
  return (
    <div className="w-28">
      <Dropdown
        value={{
          value: selectedOption?.name,
          label: selectedOption?.label ?? '',
        }}
        options={(props.options as Option[]).map((o) => {
          return {
            value: o.name,
            label: o.label,
          };
        })}
        onChange={(selectedOption) => props.handleOnChange(selectedOption.value)}
        disabled={props.disabled}
      />
    </div>
  );
};
