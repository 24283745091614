import { CheckCircleIcon, ClockIcon, MinusCircleIcon, XCircleIcon } from '@heroicons/react/solid';
import { ICampaignDetails, IHttpResponse, ISearchRequest, UpdateCampaignRequest } from '@shared/models';
import { useState } from 'react';
import { axiosDelete, axiosGet, axiosPost } from '../../authAxios';
import useCampaigns from '../../hooks/useCampaigns';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { Table } from '../shared/Table/Table';
import CampaignDeliveredProgressBar from './CampaignDeliveredProgressBar';
import CampaignDetailsPanel from './CampaignDetailsPanel';
import { defaultCampaignsTableOptions, getAllColumns, getCampaignStatusEnumOptions, getMessageTypeEnumOptions } from './types';

const AllCampaigns = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [campaignCreating, setCampaignCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ICampaignDetails>();
  const [tableOptions, setTableOptions] = useState({
    ...defaultCampaignsTableOptions,
  } as ISearchRequest);

  const [{ data: campaignData, loading: campaignLoading }, refetchCampaigns] = useCampaigns({
    ...tableOptions,
  });

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
    setCampaignCreating(false);
  };

  const handleCampaignDelete = async (): Promise<void> => {
    if (!selectedItem?.id) {
      return;
    }

    setDeleteLoading(true);

    try {
      await axiosDelete(`/campaigns/${selectedItem.id}`);
      closeDetailsPanel();
    } catch (error: any) {
      console.error(error);
      setErrorMessage(error.toString());
    } finally {
      setDeleteLoading(false);
      await refetchCampaigns();
    }
  };

  const handleCampaignActiveToggle = async (): Promise<void> => {
    if (!selectedItem?.id) {
      return;
    }

    setUpdateLoading(true);

    try {
      const request: UpdateCampaignRequest = { isActive: !selectedItem?.isActive };
      await axiosPost(`/campaigns/${selectedItem.id}`, request);

      await refetchCampaigns();

      closeDetailsPanel();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateLoading(false);
      refetchCampaigns();
    }
  };

  const openDetailsPanel = async (item?: any) => {
    if (item) {
      const {
        data: campaign,
      } = await axiosGet<IHttpResponse<ICampaignDetails>>(`/campaigns/${item.id}`);
      setSelectedItem({
        ...campaign,
        startsAt: new Date(campaign.startsAt as any),
        endsAt: new Date(campaign.endsAt as any),
      });
    }

    setShowDetailsPanel(true);
  };

  const columns = getAllColumns(
    {
      name: (item: ICampaignDetails) => {
        openDetailsPanel(item);
      },
    },
    {
      deliveredProgress: (item: ICampaignDetails) => <CampaignDeliveredProgressBar campaign={item} />,
      healthCheck: (item: ICampaignDetails) =>
        item.sendTestMessages ? (
          item.totalTestMessagesFailed > 0 ? (
            <span title="One or more test messages failed to deliver" className="select-none">
              <XCircleIcon className="w-5 h-5 text-red-400" />
            </span>
          ) : item.totalTestMessagesSent === item.totalTestMessagesDelivered && item.totalTestMessagesSent !== 0 ? (
            <span title="All test messages delivered" className="select-none">
              <CheckCircleIcon className="w-5 h-5 text-emerald-400" />
            </span>
          ) : (
            <span title="Not all test messages are delivered yet" className="select-none">
              <ClockIcon className="w-5 h-5 text-sky-400" />
            </span>
          )
        ) : (
          <span title="No health check performed" className="select-none">
            <MinusCircleIcon className="w-5 h-5 text-gray-400" />
          </span>
        ),
    }
  );

  const defaultFilterColumns = ['name', 'clientName', 'messageType', 'status'];

  const [filterColumns, setFilterColumns] = useState(
    columns.filter((col) => defaultFilterColumns.includes(col.fieldName))
  );

  const filterDropdownOptions: IFilterDropdownOptions = {
    messageType: getMessageTypeEnumOptions(),
    status: getCampaignStatusEnumOptions(),
  };

  const handleRefetch = async () => {
    try {
      await refetchCampaigns();
    } catch (error) {
    }
  };

  return (
    <>
      <h2 className="header-page">
        Campaigns
      </h2>
      <Table
        filterColumns={filterColumns}
        filter
        filterDropdownOptions={filterDropdownOptions}
        columns={columns}
        items={campaignData?.records}
        count={campaignData?.totalCount}
        loading={campaignLoading}
        skipEnumFormattingColumns={['messageType']}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        paginate
        onRefresh={handleRefetch}
      />
      <CampaignDetailsPanel
        handleSubmit={async () => { }}
        handleDelete={handleCampaignDelete}
        handleActiveToggle={handleCampaignActiveToggle}
        deleteLoading={deleteLoading}
        pauseLoading={updateLoading}
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        saveLoading={campaignCreating}
        errorMessage={errorMessage}
        hideClone
      />
    </>
  );
};

export default AllCampaigns;
