import { ErrorMessage } from './ErrorMessage';
import { subDays } from 'date-fns';
import ReactDatetimeClass from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

interface IDateTimePickerProps {
  id?: string;
  label?: string;
  value: Date;
  disabled?: boolean;
  error?: string;
  minDate?: Date;
  minHour?: number;
  maxHour?: number;
  name?: string;
  onChange: (newValue: Date) => void;
  iso?: boolean;
}

// Vite has issues with the import. This is a hack/workaround.
const DateTime = (ReactDatetimeClass as any).default ?? ReactDatetimeClass;

const DateTimePicker = ({
  id,
  label,
  value,
  error,
  disabled,
  minDate,
  minHour,
  maxHour,
  name,
  onChange,
  iso
}: IDateTimePickerProps) => {
  const constraints = {
    hours: {
      min: minHour ?? 1,
      max: maxHour ?? 23,
      step: 1,
    },
    minutes: {
      step: 1,
      min: 0,
      max: 59,
    },
  };

  const valid = (current: any) => {
    return minDate ? current.isAfter(subDays(minDate, 1)) : true;
  };

  return (
    <div id={`${id}`}>
      <>
        {label && <label className="block text-sm font-medium text-gray-700 dark:text-slate-400">{label}</label>}
        {
          <DateTime
            closeOnSelect
            value={value}
            initialViewMode={'time'}
            isValidDate={valid}
            onChange={(e: any) => {
              if (typeof e === 'string') {
                // Don't allow manual updates
              } else {
                const date = e.toDate();
                const hours = date.getHours();
                // Handle AM/PM switch
                if (hours < (minHour ?? 1)) {
                  date.setHours(minHour ?? 1);
                } else if (hours > (maxHour ?? 23)) {
                  date.setHours(maxHour ?? 23);
                }
                onChange(iso ? date.toISOString() : date);
              }
            }}
            inputProps={{
              className: `block w-full border-gray-300 dark:border-slate-800 rounded-md shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm ${label ? 'mt-1' : ''
                } ${disabled ? 'bg-gray-100 dark:bg-slate-900' : 'bg-white dark:bg-slate-700'}`,
              disabled: disabled,
              name: name ?? `dt-picker-${label}`,
              readOnly: true,
            }}
            timeConstraints={constraints}
          />
        }
      </>
      <ErrorMessage show={!!error} message={error} />
    </div>
  );
};

export default DateTimePicker;
