import { useCustomDataPoints } from 'apps/a2p-portal/src/hooks/useCustomDataPoints';
import { Button } from '../Buttons/Button';
import { ButtonVariantEnum } from '../Buttons/types';

interface ITagsProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const Tags = ({ onClick }: ITagsProps) => {
  const [{ data, loading }] = useCustomDataPoints({ personalized: true, skip: 0, take: 1000, isActive: true });
  const tags = data?.records;
  return (
    <div className="flex flex-wrap mb-2">
      <Button key={'link'} variant={ButtonVariantEnum.LINK_TAG} className="mb-2 mr-2 h-fit" type="button" onClick={onClick}>
        Link
      </Button>
      {tags?.map((tag) => {
        return (
          <Button key={tag.id} variant={ButtonVariantEnum.TAG} className="mb-2 mr-2 h-fit" type="button" onClick={onClick}>
            {tag.name}
          </Button>
        );
      })}
    </div>
  );
};

export default Tags;
