import { Auth0OrganizationName } from '@shared/models';
import { getOrganizationLabel } from 'apps/a2p-portal/src/providers/sub-domain.service';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';

interface IOrganizationDropdownProps {
  value: IDropdownValue | undefined;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const OrganizationDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IOrganizationDropdownProps): JSX.Element => {
  const organizations = Object.entries(Auth0OrganizationName).map(([label, value]) => ({
    label: getOrganizationLabel(value),
    value,
    additionalData: label,
  })) as IDropdownValue[];

  return (
    <Autocomplete
      label="Organization"
      value={organizations.find((o) => o.value === value?.value)}
      options={organizations}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default OrganizationDropdown;
