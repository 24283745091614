import { ICustomDataPointEntity, IGetAllCustomDataPointsRequest, IHttpPaginatedResponse, IHttpResponse } from '@shared/models';
import { deleteNilValues } from '@shared/services';
import { Field, RuleType } from 'react-querybuilder';
import { axiosGet } from '../authAxios';

export interface GetCustomDataPointFieldsRequest {
  rules: RuleType[];
  isActive: boolean;
  personalized?: boolean;
}

export async function getCustomDataPointFields(request: GetCustomDataPointFieldsRequest): Promise<Field[]> {
  const customDataPoints = await getCustomDataPoints(request);

  return customDataPoints?.map((cdp: ICustomDataPointEntity) => {
    const field: Field = {
      label: cdp.name,
      name: cdp.name,
      inputType: cdp.fieldType,
    };

    return field;
  }) ?? [];
}

async function getCustomDataPoints(request: GetCustomDataPointFieldsRequest) {
  const names = request?.rules?.map(rule => rule?.field)?.filter(f => !!f) ?? [];

  const cdpRequest: IGetAllCustomDataPointsRequest = deleteNilValues({
    isActive: request.isActive,
    personalized: request?.personalized,
    names: names?.join(',')
  });

  const response = await axiosGet<IHttpResponse<IHttpPaginatedResponse<ICustomDataPointEntity>>>(`/custom-data-points`, { params: cdpRequest });
  return response?.data?.records ?? [];
}
