import { addMinutes, roundToNearestMinutes } from 'date-fns';
import { SubDomain } from './sub-domain.service';

export const getUtcDateStringNoTime = (date: Date): string => {
  return date ? new Date(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`).toISOString() : '';
};

export const roundToNextQuarterHour = (date: Date): Date => {
  const nearest = roundToNearestMinutes(date, { nearestTo: 15 });

  return date > nearest ? roundToNearestMinutes(addMinutes(date, 10), { nearestTo: 15 }) : nearest;
};

export const convertEnumToReadableString = (myEnum: any): string => {
  return myEnum
    .split('_')
    .map((fragment: string) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
    .join(' ')
    .replace('A2p ', '');
};

export const convertOrganizationEnum = (myEnum: SubDomain): string => {
  return myEnum
    .split('_')
    .map((fragment: string) => fragment.charAt(0).toLocaleUpperCase() + fragment.slice(1).toLocaleLowerCase())
    .join(' ');
};

export const downloadFileFromUrl = (fileUrl: string) => {
  const downloadAnchor = document.createElement('a');

  downloadAnchor.href = fileUrl;
  downloadAnchor.download = fileUrl.split('/').pop() ?? '';
  downloadAnchor.target = '_blank';

  document.body.appendChild(downloadAnchor);
  downloadAnchor.click();
  document.body.removeChild(downloadAnchor);
};

export const getCsvRecords = (file: File): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    if (file && file.name.endsWith('.csv')) {
      const reader = new FileReader();

      reader.onload = (event: ProgressEvent<FileReader>) => {
        const buffer = event?.target?.result;

        const encoder = new TextDecoder('utf-8');

        const csvContent = encoder.decode(buffer as ArrayBuffer);

        const content = csvContent
          .replace(/\r/g, '')
          .split('\n')
          .filter((record) => record.replace(/^[\s,]+$/g, '')); // Remove empty rows and rows with empty data

        content.shift(); // Remove the headers

        resolve(content);
      };

      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Error when attempting to parse the file'));
      };

      reader.readAsArrayBuffer(file);
    }
  });
};

export const toPhoneNumberFormat = (phoneNumber: string): string => {
  if (phoneNumber.startsWith('+1')) {
    phoneNumber = phoneNumber.substring(2);
  }

  const firstSegment = `(${phoneNumber.substring(0, 3)})`;

  const secondSegment = phoneNumber.substring(3, 6);
  const thirdSegment = phoneNumber.substring(6);

  return `${firstSegment} ${secondSegment}-${thirdSegment}`;
};
