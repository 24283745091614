import { Transition } from '@headlessui/react';
import { Auth0Role, ICampaignDetails, ICampaignErrorCode, ICampaignErrorCodesResponse, IHttpResponse } from '@shared/models';
import useAxios from 'axios-hooks';
import { Fragment, useState } from 'react';
import useRoles from '../../hooks/useRoles';
import { Table } from '../shared/Table/Table';
import HealthCheckSummary from './HealthCheckSummary';
import { getErrorColumns } from './types';

interface ICampaignDetailsSummaryProps {
  selectedItem: ICampaignDetails;
}

const CampaignDetailsSummary = ({ selectedItem }: ICampaignDetailsSummaryProps) => {
  const [collapseCampaignMessage, setCollapseCampaignMessage] = useState(false);
  const [collapseA2PErrors, setCollapseA2PErrors] = useState(false);
  const [collapseProviderErrors, setCollapseProviderErrors] = useState(false);
  const allowHealthMessages = true;
  const allowProviderErrors = useRoles([Auth0Role.A2P_SUPER_ADMIN]);

  const [{ data: campaignErrorData, loading: campaignErrorLoading, error: campaignErrorError }] = useAxios<
    IHttpResponse<ICampaignErrorCodesResponse>
  >({
    url: `campaigns-errors/${selectedItem?.id}`,
    method: 'GET',
  });

  const campaignErrors: ICampaignErrorCode[] = campaignErrorData?.errorCodes ?? [];
  const internalErrors: ICampaignErrorCode[] = campaignErrorData?.internalErrorCodes ?? [];

  const campaignErrorColumns = getErrorColumns(allowHealthMessages);

  return (
    <div className="pb-2">
      <div className="w-full mt-4 border-t border-gray-300 dark:border-slate-800"></div>

      {/* <div className="mt-8 overflow-hidden bg-white shadow dark:bg-slate-700 dark:border-2 dark:border-slate-800 sm:rounded-lg">
        <div
          className="px-4 py-3 sm:px-6"
          onClick={() => {
            setCollapseCampaignMessage(!collapseCampaignMessage);
          }}
        >
          <h3 className="text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer">
            Campaign Message Summary
          </h3>
          <p className="max-w-2xl mt-1 text-sm text-gray-500 dark:text-slate-400">
            Status of all messages for this campaign
          </p>
        </div>
        <Transition
          show={collapseCampaignMessage}
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="border-t border-gray-200 dark:border-slate-800">
            <dl>
              <div className="px-4 py-5 bg-gray-50 dark:bg-slate-800 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total Scheduled</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {selectedItem.totalMessagesScheduled?.toLocaleString()}
                </dd>
              </div>
              <div className="px-4 py-5 bg-gray-50 dark:bg-slate-800 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total Sent</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {selectedItem.totalMessagesSent?.toLocaleString()}
                </dd>
              </div>
              <div className="px-4 py-5 bg-white dark:bg-slate-700 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total Delivered</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {selectedItem.totalMessagesDelivered?.toLocaleString()}
                </dd>
              </div>
              <div className="px-4 py-5 bg-gray-50 dark:bg-slate-800 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Total Failed</dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                  {selectedItem.totalMessagesFailed?.toLocaleString()}
                </dd>
              </div>
            </dl>
          </div>
        </Transition>
      </div> */}
      <>
        {selectedItem.sendTestMessages && <HealthCheckSummary campaign={selectedItem}></HealthCheckSummary>}
        {campaignErrors && campaignErrors.length > 0 && (
          <div className="mt-4 mb-2 overflow-hidden bg-white shadow sm:rounded-lg">
            <div
              className="px-4 py-3 sm:px-6"
              onClick={() => {
                setCollapseA2PErrors(!collapseA2PErrors);
              }}
            >
              <h3 className="text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer">
                Provider Errors
              </h3>
              <p className="max-w-2xl mt-1 text-sm text-gray-500">
                All errors returned from the provider for this campaign
              </p>
            </div>
            <Transition
              show={collapseA2PErrors}
              as={Fragment}
              enter="ease-in-out duration-200"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="border-t border-gray-200">
                <Table columns={campaignErrorColumns} items={campaignErrors} shimmer={false} />
              </div>
            </Transition>
          </div>
        )}

        {
          internalErrors && internalErrors.length > 0 && allowProviderErrors && (
            <div className="mt-4 mb-2 overflow-hidden bg-white shadow sm:rounded-lg">
              <div
                className="px-4 py-3 sm:px-6"
                onClick={() => {
                  setCollapseProviderErrors(!collapseProviderErrors);
                }}
              >
                <h3 className="text-lg font-medium leading-6 text-sky-600 hover:underline hover:cursor-pointer">
                  Provider Errors [Super Admin Only]
                </h3>
                <p className="max-w-2xl mt-1 text-sm text-gray-500">Errors returned directly from our provider</p>
              </div>
              <Transition
                show={collapseProviderErrors}
                as={Fragment}
                enter="ease-in-out duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="border-t border-gray-200">
                  <Table columns={campaignErrorColumns} items={internalErrors} shimmer={false} />
                </div>
              </Transition>
            </div>
          )}
      </>
    </div >
  );
};

export default CampaignDetailsSummary;
