import { ICustomDataPointEntity } from '@shared/models';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize } from '../shared/DetailsPanel/types';
import { CustomDataPointForm } from './CustomDataPointsForm';
import { ICustomDataPointForm } from './types';

interface CustomDataPointDetailsPanel {
  show: boolean;
  loading: boolean;
  selectedItem: ICustomDataPointEntity | undefined;
  deleteLoading: boolean;
  onClosePanel: () => void;
  handleSubmit: (formData: ICustomDataPointForm) => void;
  handleDelete?: () => Promise<void>;
  readOnly: boolean;
}

export const CustomDataPointDetailPanel = ({
  show,
  loading,
  selectedItem,
  onClosePanel,
  handleSubmit,
  handleDelete,
  deleteLoading,
  readOnly
}: CustomDataPointDetailsPanel) => {
  const isReadOnly = readOnly || (selectedItem?.readOnly ?? false);

  return (
    <DetailsPanel
      item={selectedItem}
      size={DetailsPanelSize.Small}
      show={show}
      loading={loading}
      formId="custom-data-point-form"
      closePanel={onClosePanel}
      title={!!selectedItem ? 'Edit Custom Data Point' : 'Add Custom Data Point'}
      isReadOnly={isReadOnly}
    >
      <CustomDataPointForm selectedItem={selectedItem} handleSubmit={handleSubmit} readOnly={isReadOnly} />
    </DetailsPanel>
  );
};
