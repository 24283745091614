import { Ref, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactFlow, { addEdge, getRectOfNodes, useEdgesState, useNodesState } from 'reactflow';

import 'reactflow/dist/style.css';
import LoadingIndicator from '../shared/LoadingIndicator';
import AudienceNode from './Nodes/AudienceNode';

const initialNodes = [
  { id: '1', position: { x: 0, y: 200 }, type: 'audience', data: { value: 1000 } },
];

const JourneyCanvas = () => {

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodeTypes = useMemo(() => ({ audience: AudienceNode }), []);
  const myRef: Ref<HTMLDivElement> = useRef(null);

  useEffect(() => { }, [myRef]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);

  const nodeArea = getRectOfNodes(nodes);
  const maxTranslateWidth = Math.max(myRef?.current?.clientWidth ?? 0, nodeArea.width + 500);
  const maxTranslateHeight = Math.max(myRef?.current?.clientHeight ?? 0, nodeArea.height + 500);

  return (
    <div ref={myRef} style={{ height: '100%', width: '100%' }} className={!myRef?.current ? 'flex items-center justify-center' : ''}>
      {myRef?.current ?
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          translateExtent={[
            [0, 0],
            [maxTranslateWidth, maxTranslateHeight]
          ]}
        />
        : <LoadingIndicator position='CENTER' size={10} />
      }
    </div>
  );
};

export default JourneyCanvas;