import { ProviderNumberTypeEnum } from '@shared/enums';
import { ISystemNumberEntity } from '@shared/models';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import SystemNumberForm from './SystemNumberForm';
import { ISystemNumberForm } from './types';

interface ISystemNumberDetailsPanel {
  show: boolean;
  loading: boolean;
  toggleLoading: boolean;
  selectedItem?: ISystemNumberEntity;
  providers: IDropdownValue[];
  onClosePanel: () => void;
  handleToggle: () => Promise<void>;
  handleSubmit: (formData: ISystemNumberForm) => Promise<void>;
  clientName: string;
}

const SystemNumberDetailsPanel = ({
  show,
  loading,
  toggleLoading,
  selectedItem,
  providers,
  onClosePanel,
  handleToggle,
  handleSubmit,
  clientName,
}: ISystemNumberDetailsPanel): JSX.Element => {
  const leftPanelButtonOptions: IButtonOptions[] = [
    {
      text: 'Disable',
      visible: !!selectedItem?.isActive && selectedItem.type === ProviderNumberTypeEnum.LOCAL,
      onClick: handleToggle,
      variant: ButtonVariantEnum.DELETE,
      loading: toggleLoading,
    },
  ];

  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'View System Number' : 'Add System Number'}
      formId="system-number-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem }}
      leftPanelButtonOptions={leftPanelButtonOptions}
    >
      <SystemNumberForm item={selectedItem} providers={providers} onSubmit={handleSubmit} clientName={clientName} />
    </DetailsPanel>
  );
};

export default SystemNumberDetailsPanel;
