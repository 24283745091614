import { IClientEntity, IHttpPaginatedResponse } from '@shared/models';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { IDropdownValue } from '../components/shared/Form/Dropdown';

export interface IClientContext {
  selectedClient?: IDropdownValue | null;
  clients: IHttpPaginatedResponse<IClientEntity> | undefined;
  loading: boolean;
  selectedClientId?: string | undefined;
  permissionsLoaded: boolean;
  setClient: Dispatch<SetStateAction<IDropdownValue | undefined>>;
  refreshClients: () => void;
}

const ClientContext = React.createContext<IClientContext>({
  selectedClient: null,
  selectedClientId: undefined,
  setClient: () => { },
  clients: undefined,
  loading: false,
  permissionsLoaded: false,
  refreshClients: () => { },
});

const ClientContextProvider = ClientContext.Provider;
const ClientContextConsumer = ClientContext.Consumer;
const useClientContext = () => useContext(ClientContext);
export { ClientContext, ClientContextConsumer, ClientContextProvider, useClientContext };

