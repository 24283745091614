import { GetAllClientsRequest, IClientEntity, IGetClientsRequest, IHttpPaginatedResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useClientsManual(request?: IGetClientsRequest): UseAxiosResult<IHttpPaginatedResponse<IClientEntity>> {
  const params: GetAllClientsRequest = {
    isActive: request?.isActive,
    skip: request?.pagination?.skip ?? 0,
    take: request?.pagination?.take ?? 25,
    sortFieldName: request?.sort?.fieldName,
    sortFieldDirection: request?.sort?.sortDirection,
  };

  return useAxios<IHttpPaginatedResponse<IClientEntity>>({
    url: `clients`,
    method: 'GET',
    params
  });
}
