import { useAuth0 } from '@auth0/auth0-react';
import { AdjustmentsIcon, BanIcon, SearchIcon, UploadIcon } from '@heroicons/react/outline';
import { Auth0Role, FilterDataTypeEnum, FilterOperatorEnum, ICustomDataPointEntity, ISearchFilter, ISearchRequest } from '@shared/models';
import { useEffect, useState } from 'react';
import { useClientContext } from '../../contexts/ClientContext';
import useContacts from '../../hooks/useContacts';
import useRoles from '../../hooks/useRoles';
import { useUserPrefs } from '../../hooks/useUserPrefs';
import UploadOptOutModal from '../OptOuts/UploadOptOutModal';
import { defaultOptOutTableOptions } from '../OptOuts/types';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { TextInput } from '../shared/Form/TextInput';
import Roles from '../shared/Roles';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import ContactsUploadModal from './ContactsUploadModal';
import CustomFieldsModal from './CustomFieldsModal';
import { defaultContactsTableOptions, getColumns, optoutFilterDropdownOptions } from './types';

const defaultFilterColumns = ['phoneNumber', 'globalStatus', 'clientStatus', 'showGlobalOptouts'];

export const Contacts = () => {
  const [showUploadContactModal, setShowUploadContactModal] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [showUploadOptoutModal, setShowUploadOptoutModal] = useState(false);

  const { selectedClientId } = useClientContext();
  const [tableOptions, setTableOptions] = useState(defaultContactsTableOptions);
  const [searchText, setSearchText] = useState('');
  const [{ data, loading }, refetch] = useContacts({ ...tableOptions, clientId: selectedClientId });
  const [cdpLoading, setCdpLoading] = useState(false);
  const allowOptoutUpload = useRoles([Auth0Role.A2P_ADMIN]);

  const [columns, setColumns] = useState(getColumns());
  const [filterColumns, setFilterColumns] = useState(
    columns.filter((col) => defaultFilterColumns.includes(col.fieldName))
  );

  const { user } = useAuth0();
  const userId = user!.sub as string;
  const [{ data: userPrefs }, refetchUserPrefs] = useUserPrefs(userId);
  const [customDataPoints, setCustomDataPoints] = useState([] as ICustomDataPointEntity[]);

  useEffect(() => {
    const newCustomDataPoints = userPrefs?.customDataPoints;
    if (newCustomDataPoints) {
      setCustomDataPoints(newCustomDataPoints);
      updateColumns(newCustomDataPoints);
    }
  }, [userPrefs]);

  useEffect(() => {
    updateColumns(customDataPoints);
  }, [customDataPoints]);

  function updateColumns(newCdp: ICustomDataPointEntity[]): void {
    const customColumns: IColumn[] = newCdp.map(cdp => ({
      headerName: cdp.name,
      fieldName: cdp.name,
      fieldType: cdp.fieldType as string as FilterDataTypeEnum
    }));
    setColumns(getColumns().concat(customColumns));
    setFilterColumns(
      columns.filter((col) => defaultFilterColumns.includes(col.fieldName))
    );
  }

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  useEffect(() => {
    refetch();
  }, [tableOptions]);

  const handleRefetch = async () => {
    try {
      refetch();
    } catch (error) { }
  };

  useEffect(() => {
    const searchText = tableOptions.filters.find((filter) => filter.fieldName === 'phoneNumber')?.value;

    handleRefetch();

    setSearchText(searchText ?? '');
  }, [tableOptions]);

  const handleSearch = () => {
    const sanitizedSearchText = searchText.replace(/[()-\s]/g, '');

    let searchFilter: ISearchFilter = {
      dataType: FilterDataTypeEnum.NUMBER,
      fieldName: 'phoneNumber',
      operator: FilterOperatorEnum.CONTAINS,
      value: sanitizedSearchText,
    };

    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'phoneNumber');

    newSearchOptions.pagination = defaultOptOutTableOptions.pagination;

    newSearchOptions.filters.push(searchFilter);

    setTableOptions(newSearchOptions);
  };

  const handleClear = () => {
    const newSearchOptions = { ...tableOptions };

    newSearchOptions.filters = newSearchOptions.filters.filter((filter) => filter.fieldName !== 'phoneNumber');

    setTableOptions(newSearchOptions);
  };

  return (
    <>
      <h2 className="pb-2 dark:text-white">
        Contacts
      </h2>
      <div className="flex pb-2">
        <div className="flex">
          <TextInput
            id="phoneNumberLookup"
            name="phoneNumberLookup"
            placeholder="Phone Number"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            onClear={handleClear}
            onEnter={handleSearch}
            leadingIcon={<SearchIcon />}
            clearable
          />
          <Button
            id="optout-search"
            variant={ButtonVariantEnum.SECONDARY}
            className="self-end ml-2"
            onClick={() => {
              handleSearch();
            }}
          >
            Search
          </Button>
        </div>

        <div className="flex ml-auto">
          <Button
            id="custom-columns-toggle"
            leadingIcon={<AdjustmentsIcon className="w-5 h-5" />}
            variant={ButtonVariantEnum.SECONDARY}
            className="self-end mr-4"
            onClick={() => setShowFieldsModal(true)}>
            Customize Grid
          </Button>
          <Roles roles={[Auth0Role.A2P_ADMIN]}>
            <Button
              id="contact-upload"
              leadingIcon={<UploadIcon className="w-5 h-5" />}
              variant={ButtonVariantEnum.SECONDARY}
              className="self-end mr-4"
              onClick={() => setShowUploadContactModal(true)}>
              Upload Contacts
            </Button>
          </Roles>
          {allowOptoutUpload && <div>
            <Button
              id="optout-upload"
              leadingIcon={<BanIcon className="w-5 h-5" />}
              variant={ButtonVariantEnum.SECONDARY}
              className="self-end"
              onClick={() => {
                setShowUploadOptoutModal(true);
              }}
            >
              Upload Optouts
            </Button>
          </div>}
        </div>
      </div>

      <Table
        filterColumns={filterColumns}
        filter
        filterDropdownOptions={optoutFilterDropdownOptions}
        loading={loading || cdpLoading}
        items={data?.records}
        count={data?.totalCount}
        columns={columns}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        paginate
        onRefresh={handleRefetch}
      />

      <ContactsUploadModal show={showUploadContactModal} setShow={setShowUploadContactModal} />

      <CustomFieldsModal
        userId={userId}
        customDataPoints={customDataPoints}
        setCustomDataPoints={setCustomDataPoints}
        setCdpLoading={setCdpLoading}
        show={showFieldsModal}
        setShow={setShowFieldsModal} />

      <UploadOptOutModal show={showUploadOptoutModal} setShow={setShowUploadOptoutModal} />
    </>
  );
};

export default Contacts;
