import { IAudienceEntity } from '../domain/audiences/audience-entity.interface';
import { ISuppressionEntity } from '../domain/suppressions/suppression-entity.interface';

export enum SocketName {
  SEGMENT_CONTACT_COUNT = 'SEGMENT_CONTACT_COUNT',
  AUDIENCE_CONTACT_COUNT = 'AUDIENCE_CONTACT_COUNT',
  SUPPRESSION_CONTACT_COUNT = 'SUPPRESSION_CONTACT_COUNT'
}

export interface SocketPayload<T> {
  name: SocketName;
  data?: T;
  access_token?: any;
}

export interface SocketData {
  user_id?: string;
}

export interface SegmentContactCountSocketData extends SocketData {
  count: number;
}

export interface AudienceContactCountSocketData extends SocketData {
  clientId: string;
  audience: IAudienceEntity;
}

export interface SuppressionContactCountSocketData extends SocketData {
  clientId: string;
  suppression: ISuppressionEntity;
}
