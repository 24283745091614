import { Handle, Position } from 'reactflow';
import { More } from '../../shared/Icons/More';
import { NodeTypeEnum } from '../types';

interface IBaseNodeProps {
  type: NodeTypeEnum;
  icon: JSX.Element;
  name: string;
  description: string;

}

const BaseNode = ({ type, icon, name, description }: IBaseNodeProps) => {
  return <>
    {type !== NodeTypeEnum.AUDIENCE && <Handle type="target" position={Position.Left} />}
    <div className='flex items-center p-3 bg-white rounded-large'>
      {icon}
      <div className='ml-4'>
        <div className='mb-px text-black body-text-bold'>{name}</div>
        <h5 className='text-medium-gray tracking-[1.15px]'>{description}</h5>
      </div>
      {type !== NodeTypeEnum.AUDIENCE ? <div className='ml-12 mr-2'><More /></div> : <div className='mr-4'>{/* Empty div for spacing */}</div>}
    </div>
    <Handle type="source" position={Position.Right} />
  </>;
};

export default BaseNode;