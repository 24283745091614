import { IDomainsEntity, IHttpPaginatedResponse, IHttpResponse } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { useClientContext } from '../contexts/ClientContext';

export default function useDomains(): UseAxiosResult<IHttpResponse<IHttpPaginatedResponse<IDomainsEntity>>> {
  const { selectedClientId } = useClientContext();

  return useAxios<IHttpResponse<IHttpPaginatedResponse<IDomainsEntity>>>({
    url: `domains`,
    method: 'GET',
    params: {
      clientId: selectedClientId
    }
  });
}
