import { ISystemNumberEntity } from '@shared/models';
import { FastField, Formik } from 'formik';
import useClientsManual from '../../hooks/useClientsManual';
import { allEnabledClientsSearchOptions } from '../Clients/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import ProviderDropdown from '../shared/Form/Dropdowns/ProviderDropdown';
import { ErrorMessage } from '../shared/Form/ErrorMessage';
import { TextInput } from '../shared/Form/TextInput';
import { ISystemNumberForm, systemNumberFormSchema } from './types';

interface ITestNumberFormProps {
  item?: ISystemNumberEntity;
  clientName?: string;
  providers: IDropdownValue[];
  onSubmit: (values: any) => void;
}

const SystemNumberForm = ({ item, clientName, providers, onSubmit }: ITestNumberFormProps): JSX.Element => {
  const initialFormState: ISystemNumberForm = {
    phoneNumber: item?.phoneNumber ?? undefined,
    providerId: item?.providerId ?? '',
    client: { value: item?.clientId ?? '', label: clientName ?? '' }
  };

  const isReadOnly: boolean = item !== null && item !== undefined;
  const [{ data: clientData, loading: clientLoading, error: clientError }, searchClients] =
    useClientsManual(allEnabledClientsSearchOptions);

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={systemNumberFormSchema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
          <>
            <form id="system-number-form" onSubmit={handleSubmit} className="space-y-2 h-full">
              <FastField
                component={TextInput}
                id="phoneNumber"
                name="phoneNumber"
                label="Number"
                type="number"
                disabled={isReadOnly}
                value={values.phoneNumber}
                error={touched.phoneNumber ? errors.phoneNumber : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <>
                {/* <ClientDropdown
                  value={values.client}
                  onChange={(event, newValue) => {
                    setFieldValue('client', newValue);
                    setFieldValue('providerId', '');
                  }}
                  onBlur={() => {
                    setFieldTouched('client');
                  }}
                  disabled={isReadOnly}
                  showError={!!(touched.client && errors.client)}
                  errorMessage={(errors?.client as any)?.value}
                /> */}
                <ClientDropdown
                  value={values.client}
                  onChange={(newValue) => {
                    setFieldValue('client', newValue);
                    setFieldValue('providerId', '');
                  }}
                  onBlur={() => {
                    setFieldTouched('client');
                  }}
                  disabled={isReadOnly}
                />
                <ErrorMessage show={!!(touched.client && errors.client)} message={errors?.client?.label} />
              </>
              <>
                <ProviderDropdown
                  value={values.providerId ?? ''}
                  onChange={(newValue: IDropdownValue | undefined) => {
                    setFieldValue('providerId', newValue?.value ?? '');
                    setFieldValue('provider', newValue);
                  }}
                  onBlur={() => {
                    setFieldTouched('providerId', true);
                    setFieldTouched('provider', true);
                  }}
                  showError={!!(touched.providerId && errors.providerId)}
                  errorMessage={errors?.providerId}
                  disabled={isReadOnly}
                />
              </>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default SystemNumberForm;
