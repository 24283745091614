import { IContactEntity, IGetAllContactsRequest, IHttpPaginatedResponse, ISearchRequest } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { getFilterValue } from '../authAxios';

const useContacts = (searchRequest: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<IContactEntity>> => {
  console.log({ searchRequest });

  const request: IGetAllContactsRequest = {
    skip: searchRequest.pagination.skip,
    take: searchRequest.pagination.take,
    clientId: searchRequest?.clientId ?? '',
    phoneNumber: getFilterValue(searchRequest, 'phoneNumber'),
    clientStatus: getFilterValue(searchRequest, 'clientStatus'),
    globalStatus: getFilterValue(searchRequest, 'globalStatus'),
    showGlobalOptouts: getFilterValue(searchRequest, 'showGlobalOptouts'),
  };

  return useAxios<IHttpPaginatedResponse<IContactEntity>>({
    method: 'GET',
    url: `contacts`,
    params: request
  });
};

export default useContacts;
