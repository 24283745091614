import { ICustomDataPointEntity } from '@shared/models';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import CustomDataPointsDropdown from '../shared/Form/Dropdowns/CustomFieldsDropdown';

const schema: yup.SchemaOf<ICustomFieldsForm> = yup.object().shape({
  customDataPoints: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
        additionalData: yup.mixed(),
      })
    )
    .min(0)
});

export interface ICustomFieldsForm {
  customDataPoints: IDropdownValue[];
}

interface ICustomFieldsFormProps {
  onSubmit?: (formValues: ICustomFieldsForm) => void;
  customDataPoints?: ICustomDataPointEntity[] | undefined;
}

const CustomFieldsForm = ({ onSubmit, customDataPoints }: ICustomFieldsFormProps): JSX.Element => {
  const initialFormState: ICustomFieldsForm = {
    customDataPoints: (customDataPoints ?? []).map(cdp => ({
      label: cdp.name,
      value: cdp.id
    }))
  };

  return (
    <>
      <Formik
        initialValues={initialFormState}
        validationSchema={schema}
        onSubmit={(values) => {
          onSubmit && onSubmit(values);
        }}
      >
        {({ values, errors, touched, handleSubmit, setFieldValue, setFieldTouched }) => (
          // Set form id so external submit button can still work
          <>
            <form id="custom-fields-form" onSubmit={handleSubmit}>
              <div className="flex flex-col space-y-4">
                <CustomDataPointsDropdown
                  value={values.customDataPoints as IDropdownValue[] ?? []}
                  onChange={(newValue) => {
                    setFieldValue('customDataPoints', newValue);
                    setFieldTouched('customDataPoints');
                  }}
                  onBlur={() => {
                    setFieldTouched('customDataPoints');
                  }}
                  showError={!!touched.customDataPoints}
                  errorMessage={errors.customDataPoints?.toString()}
                />
                <div className="flex justify-center mt-4"></div>
              </div>
              <Button
                disabled={!touched.customDataPoints}
                id="custom-fields-form"
                className="flex ml-auto"
                type="submit"
                variant={ButtonVariantEnum.SECONDARY}
              >
                Display
              </Button>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default CustomFieldsForm;
