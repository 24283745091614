import { IHttpPaginatedResponse, ISearchRequest, ITestNumbersViewRecord } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';
import { useClientContext } from '../contexts/ClientContext';

export default function useTestNumbers(request?: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<ITestNumbersViewRecord>> {
  const { selectedClientId } = useClientContext();
  return useAxios<IHttpPaginatedResponse<ITestNumbersViewRecord>>({
    url: 'test-numbers',
    method: 'GET',
    params: {
      ...(request ?? {}),
      clientId: selectedClientId
    }
  });
}
