import { ICampaignEntity } from "@shared/models";


interface ICampaignProgressBarProps {
  campaign: ICampaignEntity;
}

const CampaignDeliveredProgressBar = ({ campaign }: ICampaignProgressBarProps): JSX.Element => {
  const {
    totalMessagesSent: sent = 0,
    totalMessagesDelivered: delivered = 0,
    totalMessagesFailed: failed = 0,
  } = campaign;

  const tripWired = campaign.isActive == false && campaign.tripWired == true;

  return (
    <div>
      {/* Sent / Delivered / Failed */}
      <div
        className={`flex h-2 overflow-hidden text-xs rounded justify-end ${campaign.totalMessagesSent === 0 ? 'bg-gray-200 dark:bg-slate-400' : 'bg-sky-500'
          }`}
      >
        <>
          <div
            style={{
              width: `${Math.min(Math.ceil(((delivered ?? 0) / (sent ?? 100)) * 100), 100)}%`,
            }}
            className={`flex flex-col justify-center text-center text-white shadow-none bg-emerald-400 whitespace-nowrap`}
          ></div>
          <div
            style={{
              width: `${Math.min(Math.ceil(((failed ?? 0) / (sent ?? 100)) * 100), 100)}%`,
            }}
            className={`flex flex-col justify-center text-center text-white shadow-none bg-red-400 whitespace-nowrap`}
          ></div>
        </>
      </div>
      <span
        className="text-sm font-medium text-gray-900 truncate dark:text-slate-400"
        title="Sent / Delivered / Failed"
      >
        {campaign?.totalMessagesSent?.toLocaleString()} / {campaign?.totalMessagesDelivered?.toLocaleString()} /{' '}
        {campaign?.totalMessagesFailed?.toLocaleString()}
      </span>
    </div>
  );
};

export default CampaignDeliveredProgressBar;
