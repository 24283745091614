import { FilterDataTypeEnum } from '@shared/models';
import { convertEnumToReadableString } from 'apps/a2p-portal/src/providers/utility.provider';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { TextInput } from '../Form/TextInput';
import { EditIcon } from '../Icons/EditIcon';
import { Actions, IMenuItem } from './Actions';
import { IColumn } from './types';

interface ITableList {
  columns: IColumn[];
  items: any;
  limit: number;
  totalCount: number;
  onCellEdit?: (event, index, column) => void;
  validateRow?: (row, index, setRowEditable) => void;
  actions?: IMenuItem[];
  paginate?: boolean;
  loading?: boolean;
  shimmer?: boolean;
  onPaginate?: (page: number) => void;
}



const TableList = ({ columns, items, limit, totalCount, onPaginate, onCellEdit, validateRow, actions, paginate, loading, shimmer }: ITableList) => {
  const [rowEditable, setRowEditable] = useState<number>();
  const [page, setPage] = useState(0);
  const maxColLength = 45;
  const tableHasResults: boolean = items.length !== 0;

  useEffect(() => {
    if (!!onPaginate) {
      onPaginate(page);
    }
  }, [page]);

  const formatDataField = (item: any, col: IColumn, skipEnumFormattingColumns?, enumExceptions?, booleanDisplayValues?): string | JSX.Element => {
    const content: string = item[col.fieldName] ?? '';
    switch (col.fieldType) {
      case FilterDataTypeEnum.STRING: {
        return content.length > maxColLength ? `${content.slice(0, maxColLength - 3)}...` : content;
      }
      case FilterDataTypeEnum.NUMBER: {
        const isPopulated = content?.length > 0 || content?.toString()?.length > 0;
        return (isPopulated ? content.toLocaleString() : col.emptyValue) ?? '';
      }
      case FilterDataTypeEnum.DATE: {
        try {
          return format(new Date(content), 'Pp');
        } catch (error) {
          console.log('Unable to parse date', content);
          return col.emptyValue ?? '';
        }
      }
      case FilterDataTypeEnum.ENUM: {
        if (skipEnumFormattingColumns.find((val) => val === col.fieldName)) {
          return content;
        }
        const exception = enumExceptions.find((val) => val.fieldName === col.fieldName);
        if (exception && exception.exceptions.find((ex) => ex === content)) {
          return content.replace(/_/g, ' '); // Even when we don't want the readable string (abbreviations), we still don't want underscores
        }
        return convertEnumToReadableString(content);
      }
      case FilterDataTypeEnum.BOOLEAN: {
        const customDisplayValues = booleanDisplayValues.find((val) => val.fieldName === col.fieldName);
        const displayValues = customDisplayValues?.displayValues || { trueLabel: 'Yes', falseLabel: 'No' };

        return content ? displayValues.trueLabel : displayValues.falseLabel;
      }
      case FilterDataTypeEnum.ACTIONS: {
        return <Actions row={item} items={actions ?? []} />;
      }
      default: {
        return content;
      }
    }
  };

  const generateRowForItem = (item: any, index: number, columns: IColumn[], dataClass?: string, compact?: boolean): JSX.Element[] => {
    const defaultDataClass: string = dataClass ?? `text-sm px-3 ${compact ? 'py-2' : 'py-4'} whitespace-nowrap`;

    const rowData = columns?.map((col: IColumn) => {
      // This allows for invalid html. Maybe don't allow custom rendering on tables, but allow for lists
      if (col.renderColumn) {
        return (
          <td
            key={`item_${col.fieldName}_${index}`}
            onClick={() => {
              col.onColumnClick && col.onColumnClick(item, index);
            }}
            className={`text-gray-500 dark:text-slate-400 ${defaultDataClass} ${col.onColumnClick &&
              `hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white font-medium cursor-pointer hover:text-sky-900`
              }`}
          >
            {col.renderColumn(item)}
          </td>
        );
      }

      if (col.isRowHeader) {
        if (col.onColumnClick) {
          return (
            <td
              key={`item_${col.fieldName}_${index}`}
              onClick={() => {
                col.onColumnClick && col.onColumnClick(item, index);
              }}
              className={`${defaultDataClass} hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white font-medium cursor-pointer hover:text-sky-900`}
            >
              {item[col.fieldName]}
            </td>
          );
        }
        return (
          <td
            key={`item_${col.fieldName}_${index}`}
            className={`${defaultDataClass} text-gray-900 dark:text-white font-medium`}
          >
            {item[col.fieldName]}
          </td>
        );
      }

      return (
        index !== rowEditable || (!col.editable && col.fieldType !== FilterDataTypeEnum.EDIT) ? <td key={`item_${col.fieldName}_${index}`} className={`text-gray-500 dark:text-slate-400 ${defaultDataClass}`}>
          {(col.fieldType !== FilterDataTypeEnum.EDIT) ? formatDataField(item, col) : <div onClick={() => rowEditable === undefined && setRowEditable(index)} className={`${rowEditable !== undefined ? 'text-medium-gray' : 'text-wc-blue cursor-pointer'}`}>{<EditIcon />}</div>}
        </td> : col.fieldType !== FilterDataTypeEnum.EDIT ? <td>
          <TextInput
            type={col.editType}
            name={`item_${col.fieldName}_${index}`}
            value={item[col.fieldName]}
            className={`${item.valid > 0 ? 'border-fire' : item.valid < 0 ? 'border-turquoise' : ''}`}
            onChange={(e) => onCellEdit!(e, index, col)} />
        </td> : <td onClick={() => validateRow!(item, index, setRowEditable)} className='text-center border-r-4 cursor-pointer shadow-wc-blue text-wc-blue border-wc-blue'><h4>Validate</h4>{item.valid > 0 && <div className='pt-1 text-fire'>{col.upperBoundError}</div>}{item.valid < 0 && <div className='pt-1 text-turquoise'>{col.lowerBoundError}</div>}</td>
      );
    });

    return rowData || [];
  };
  return <><table className='table-list'>

    <thead>
      <tr>
        {columns.map(column => {
          return <th key={column.headerName} className='table-list-header'>
            {column.headerName}
          </th>;
        })}
      </tr>
    </thead>
    {!loading && tableHasResults && <tbody className='table-list-body'>
      {items.map((item, index) => <tr key={`${index}`} className='table-list-row'>{generateRowForItem(item, index, columns)}</tr>)}
    </tbody>}
    {/* Shimmer table */}
    {loading && shimmer && (
      <tbody className="dark:border-slate-800">
        {[...new Array(limit)].map((item, index) => (
          <tr
            key={`item_shimmer_${index}`}
            className={index % 2 !== 0 ? 'bg-gray-50 dark:bg-slate-800' : 'bg-white dark:bg-slate-700'}
          >
            {columns.map((col, index) => (
              <td
                className="px-3 py-4 text-sm font-medium whitespace-nowrap hover:underline text-sky-600 dark:text-slate-300 dark:hover:text-white"
                key={`item_td_shimmer_${index}`}
              >
                <span className="inline-flex items-center w-full h-4 text-sm leading-none bg-gray-200 rounded-lg dark:bg-slate-400 animate-pulse"></span>
              </td>
            ))}
          </tr>
        ))}
      </tbody>)}
  </table>
    {paginate && <div className='flex items-center justify-center'>
      <button disabled={page === 0 || loading} onClick={() => setPage(prevState => --prevState)} className={`body-text mr-4 ${page === 0 || loading ? 'text-medium-gray' : 'text-dark-gray'}`}>Prev</button>
      <div className='space-x-2'>
        {((totalCount ?? 0) / limit) < 6 ?
          [...new Array(Math.ceil((totalCount ?? 0) / limit))].map((_, index) => <button disabled={loading} onClick={() => setPage(index)} className={`px-4 py-1 rounded-lg ${index === page ? 'bg-wc-blue text-white' : 'bg-white text-black'}`}>{index + 1}</button>) :
          <>
            {[...new Array(3)].map((_, index) => <button disabled={loading} onClick={() => setPage(index)} className={`px-4 py-1 rounded-lg ${index === page ? 'bg-wc-blue text-white' : 'bg-white text-black'}`}>{index + 1}</button>)}
            <button className={`px-4 py-1 rounded-lg 'bg-white text-black`}>...</button>
            <button disabled={loading} onClick={() => setPage(Math.ceil((totalCount ?? 0) / limit))} className={`px-4 py-1 rounded-lg bg-white text-black`}>{Math.ceil((totalCount ?? 0) / limit)}</button>
          </>}
      </div>
      <button disabled={items.length < limit || loading || (page + 1) === (Math.ceil((totalCount ?? 0) / limit))} onClick={() => setPage(prevState => ++prevState)} className={`body-text ml-4 ${items.length < limit || loading ? 'text-medium-gray' : 'text-dark-gray'}`}>Next</button>
    </div>}
  </>;
};

export default TableList;