import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { IDropdownValue } from '../shared/Form/Dropdown';
import ClientDropdown from '../shared/Form/Dropdowns/ClientDropdown';
import ProviderDropdown from '../shared/Form/Dropdowns/ProviderDropdown';
import { FileUpload } from '../shared/Form/FileUpload';
import LoadingIndicator from '../shared/LoadingIndicator';

export interface ISystemNumbersUploadForm {
  client?: IDropdownValue;
  providerId: string;
  systemNumberList: any;
}

const initialFormState: ISystemNumbersUploadForm = {
  client: undefined,
  providerId: '',
  systemNumberList: undefined,
};

interface IUploadSystemNumbersFormProps {
  loading: boolean;
  providers: IDropdownValue[];
  onSubmit: (formData: ISystemNumbersUploadForm) => Promise<void>;
}

const UploadSystemNumbersForm = ({ providers, loading, onSubmit }: IUploadSystemNumbersFormProps) => {
  const validateCsvHeaders = async (file: File): Promise<boolean> => {
    try {
      const headers = await getCsvHeaders(file);

      return (
        headers.length === 3 &&
        headers[0].toLocaleLowerCase() === 'phonenumber' &&
        headers[1].toLocaleLowerCase() === 'tcr campaign id' &&
        headers[2].toLocaleLowerCase() === 'status'
      );
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const schema: yup.SchemaOf<ISystemNumbersUploadForm> = yup.object().shape({
    client: yup
      .object()
      .shape({
        label: yup.string().defined('Required'),
        value: yup.mixed().defined('Required'),
      })
      .nullable(),
    providerId: yup.string().defined('Required'),
    systemNumberList: yup
      .mixed()
      .required('Required')
      .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
        return file ? file.name?.endsWith('.csv') : false;
      })
      .test('csvHeaderValidate', 'CSV does not contain the proper headers', async (file: File): Promise<boolean> => {
        return file ? await validateCsvHeaders(file) : false;
      }),
  });

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit && onSubmit(values);
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched }) => (
        <form id="upload-system-numbers" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <ClientDropdown
              value={values.client}
              onChange={(newValue) => {
                setFieldValue('client', newValue);
              }}
              onBlur={() => {
                setFieldTouched('client');
              }}
              errorMessage={(errors?.client as any)?.value as string}
            />
            <ProviderDropdown
              value={values.providerId ?? ''}
              onChange={(newValue) => {
                setFieldValue('providerId', newValue?.value ?? '');
              }}
              onBlur={() => {
                setFieldTouched('providerId');
              }}
              showError={!!(touched.providerId && errors.providerId)}
              errorMessage={(errors?.providerId as any)?.value as string}
            />
            <FileUpload
              id="system-number-upload-file-selector"
              name="systemNumberList"
              label="System Numbers"
              placeholder="Upload a .csv file"
              value={values.systemNumberList}
              error={touched.systemNumberList && (errors.systemNumberList as any)}
              onBlur={handleBlur}
              onChange={(fileUploadEvent: any) => {
                const file = fileUploadEvent.target.files[0];
                setFieldTouched('systemNumberList');
                setFieldValue('systemNumberList', file);
              }}
            />

            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Download the template{' '}
                <Link
                  className="font-semibold text-sky-500 hover:underline"
                  to="/sample_upload_system_numbers.csv"
                  target="_blank"
                  download
                >
                  here
                </Link>
              </p>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              id="system-number-upload-form"
              className="ml-auto"
              type="submit"
              variant={ButtonVariantEnum.SECONDARY}
            >
              {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UploadSystemNumbersForm;
