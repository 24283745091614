import { Auth0Role } from '@shared/models';
import { convertEnumToReadableString } from 'apps/a2p-portal/src/providers/utility.provider';
import { IDropdownValue } from '../Dropdown';
import Autocomplete from './Autocomplete';
import useRoles from 'apps/a2p-portal/src/hooks/useRoles';

interface IRoleDropdownProps {
  value: IDropdownValue;
  onChange: (newValue?: IDropdownValue | undefined) => void;
  onBlur?: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
}

const RoleDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
}: IRoleDropdownProps): JSX.Element => {
  const isSuperAdmin = useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const roles = Object.entries(Auth0Role)
    .filter(role => isSuperAdmin ? role : (role[1] !== Auth0Role.A2P_SUPER_ADMIN))
    .map(([label, value]) => ({
      label: convertEnumToReadableString(label),
      value,
    })) as IDropdownValue[];
  return (
    <Autocomplete
      label="Role"
      value={roles.find((r) => r.value === value?.value)}
      options={roles}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
    />
  );
};

export default RoleDropdown;
