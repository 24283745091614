import {
  IHttpPaginatedResponse,
  ISearchRequest,
  ISuppressionEntity
} from '@shared/models';
import useAxios from 'axios-hooks';
import { useClientContext } from '../contexts/ClientContext';

export function useSuppressions(request?: ISearchRequest) {
  const { selectedClientId } = useClientContext();

  return useAxios<IHttpPaginatedResponse<ISuppressionEntity>>({
    url: `suppressions`,
    method: 'GET',
    params: {
      clientId: selectedClientId,
      skip: request?.pagination?.skip ?? 0,
      take: request?.pagination?.take ?? 1000
    }
  });
}
