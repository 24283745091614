import { useAuth0 } from '@auth0/auth0-react';
import {
  IHttpResponse,
  ISearchRequest,
  ISystemNumberCreationRequest,
  ISystemNumberEntity,
  ISystemNumberListView,
  IToggleSystemNumberRequest
} from '@shared/models';
import { isNil } from '@shared/services';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { axiosPost } from '../../authAxios';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useProviders from '../../hooks/useProviders';
import useSystemNumbers from '../../hooks/useSystemNumbers';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { Table } from '../shared/Table/Table';
import SystemNumberDetailsPanel from './SystemNumberDetailsPanel';
import UploadSystemNumbersModal from './UploadSystemNumbersModal';
import { ISystemNumberForm, defaultSystemNumberTableOptions, getColumns, systemNumberDropdownOptions } from './types';

const SystemNumbers = (): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<ISystemNumberEntity>();
  const [showUpload, setShowUpload] = useState(false);
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableOptions, setTableOptions] = useState(defaultSystemNumberTableOptions);
  const { addNotification } = useNotifications();
  const { user } = useAuth0();

  const [{ data, loading, error }, refetch] = useSystemNumbers(tableOptions);
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const [{ loading: toggleLoading }, toggleSystemNumber] = useAxios<IHttpResponse<void>>(
    {
      url: 'system-numbers-toggle',
      method: 'POST',
    },
    { manual: true }
  );

  const providerOptions: IDropdownValue[] =
    providerData?.map((provider) => ({
      label: provider.name,
      value: provider.name,
    })) ?? [];

  const providers: IDropdownValue[] =
    providerData?.map((provider) => ({
      label: provider.name,
      value: provider.id,
    })) ?? [];

  const systemNumbers: ISystemNumberListView[] = data?.records ?? [];
  const totalCount: number = data?.totalCount ?? 0;

  systemNumberDropdownOptions.providerName = providerOptions;

  const columns = getColumns(
    {
      phoneNumber: (item: any) => {
        openDetailsPanel(item);
      },
    },
    {
      phoneNumber: (item: any) => {
        const numberAsString = item.phoneNumber.toString();
        return (
          <span>{`(${numberAsString.substring(0, 3)}) ${numberAsString.substring(3, 6)}-${numberAsString.substring(
            6
          )}`}</span>
        );
      },
    }
  );

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    const filters = searchOptions.filters.filter((f) => !isNil(f.value));
    setTableOptions({ ...searchOptions, filters });
  };

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  useEffect(() => {
    handleRefetch();
  }, [tableOptions]);

  const openDetailsPanel = (item?: any) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const handleToggle = async () => {
    try {
      const request: IToggleSystemNumberRequest = {
        id: selectedItem!.id!,
        phoneNumbers: [selectedItem!.phoneNumber],
        type: selectedItem!.type,
        isActive: !selectedItem!.isActive,
        providerId: selectedItem!.providerId,
      };

      await toggleSystemNumber({
        data: request,
      });

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: `System Number ${!selectedItem?.isActive ? 'enabled' : 'disabled'} successfully.`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (formData: ISystemNumberForm): Promise<void> => {
    try {
      setIsLoading(true);
      const request: ISystemNumberCreationRequest = {
        phoneNumber: formData.phoneNumber!,
        clientId: formData.client?.value,
        providerId: formData.providerId
      };

      await axiosPost('system-numbers', request);

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'System Number created successfully!',
      });
    } catch (error) {
      console.error(error);
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to save the System Number.',
        type: NotificationType.FAILURE,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const handleExport = async (tableOptions: ISearchRequest) => {
  //   try {
  //     if (!user?.email) {
  //       throw new Error('User email needs to be set');
  //     }

  //     const exportRequest: IExportRequest = {
  //       userEmail: user.email,
  //       fileName: `System_Numbers_Export_${new Date().toISOString()}.csv`,
  //       ...tableOptions,
  //       pagination: { skip: 0, take: 100000000 },
  //       columns: columns.map((col) => ({ displayName: col.headerName, columnName: col.fieldName })),
  //     };

  //     const triggerPayload: ISQSSendRequest<IExportRequest> = {
  //       messageId: uuid(),
  //       queueName: SQSQueueNameEnum.EXPORTS,
  //       payload: exportRequest,
  //     };

  //     await axiosPost<IHttpResponse<string>>('/utility-send-to-sqs', triggerPayload);

  //     addNotification({
  //       header: 'Grid exported successfully!',
  //       content: 'You should receive an email in a few minutes with the requested data.',
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const defaultFilterColumns = columns.filter((col) => ['clientId', 'phoneNumber', 'tcrCampaignId', 'type', 'provider', 'isActive'].includes(col.fieldName));
  return (
    <>
      <h2 className="pb-2 dark:text-white">System Numbers</h2>

      <div className="flex justify-end pb-2 space-x-2">
        {/* <Button
          id="optout-upload"
          leadingIcon={<UploadIcon className="w-5 h-5" />}
          variant={ButtonVariantEnum.SECONDARY}
          className="self-end"
          onClick={() => {
            setShowUpload(true);
          }}
        >
          Upload Local Numbers
        </Button> */}

        {/* <Button
          id="system-number-add"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add Toll-Free Number
        </Button> */}
      </div>

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={systemNumbers}
        count={totalCount}
        paginate
        filter
        sort
        filterColumns={defaultFilterColumns}
        // onExport={handleExport}
        filterDropdownOptions={systemNumberDropdownOptions}
        skipEnumFormattingColumns={['messageType']}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        onRefresh={handleRefetch}
      />

      <SystemNumberDetailsPanel
        show={showDetailsPanel}
        loading={isLoading}
        toggleLoading={toggleLoading}
        selectedItem={selectedItem}
        onClosePanel={closeDetailsPanel}
        handleSubmit={handleSubmit}
        handleToggle={handleToggle}
        providers={providers}
        clientName={systemNumbers.find((sysNumber) => sysNumber.id === selectedItem?.id)?.clientName ?? ''}
      />

      <UploadSystemNumbersModal show={showUpload} setShow={setShowUpload} onUpload={handleRefetch} />
    </>
  );
};

export default SystemNumbers;
