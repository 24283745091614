import { EntityName } from "@shared/enums";
import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from "@shared/models";
import { isAdmin } from '@shared/services';
import * as yup from 'yup';
import { userTypeDropdownValues } from "../SystemNumbers/types";
import { IDropdownValue } from "../shared/Form/Dropdown";
import { dropdownSchema } from "../shared/Schema/yup";
import { IFilterDropdownOptions } from "../shared/Table/FilterForm";
import { IColumn, IColumnClickConfig } from "../shared/Table/types";

export const defaultUsersTableOptions: ISearchRequest = {
  entity: EntityName.USERS,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'given_name',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export const getUsersColumns = (columnClickEvents: IColumnClickConfig, columnRenders: any): IColumn[] => {
  const columns = [
    {
      headerName: 'Email',
      fieldName: 'email',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['email']
    },
    {
      headerName: 'Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      renderColumn: columnRenders['name']
    },
    {
      headerName: 'Role',
      fieldName: 'role',
      fieldType: FilterDataTypeEnum.ENUM,
      sortable: false
    }
  ];

  return columns;
};

export const usersFilterDropdownOptions: IFilterDropdownOptions = {
  type: userTypeDropdownValues,
};

export interface IUserForm {
  email: string,
  firstName: string,
  lastName: string,
  blocked: boolean,
  organization: IDropdownValue,
  role: IDropdownValue;
  clients: IDropdownValue[];
}

export const userFormScema: yup.SchemaOf<IUserForm> = yup.object().shape({
  email: yup.string().trim().email('Invalid email format').defined('Required'),
  firstName: yup.string().trim().defined('Required'),
  lastName: yup.string().trim().defined('Required'),
  blocked: yup.boolean().required('Required'),
  organization: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
      additionalData: yup.mixed()
    }).required('Required'),
  role: yup
    .object()
    .shape({
      label: yup.string().defined('Required'),
      value: yup.mixed().defined('Required'),
      additionalData: yup.mixed()
    }).required('Required'),
  clients: yup.array().of(dropdownSchema)
    .when('role', {
      is: (role: IDropdownValue) => !isAdmin(role?.value),
      then: yup.array().of(dropdownSchema).min(1, 'Select at least one client').required("Must select at least one client"),
      otherwise: yup.array().of(dropdownSchema).optional(),
    })
});
