import { User, useAuth0 } from '@auth0/auth0-react';
import { Disclosure } from '@headlessui/react';
import {
  BeakerIcon,
  ChartPieIcon,
  CollectionIcon,
  HashtagIcon,
  IdentificationIcon,
  LogoutIcon,
  MenuIcon,
  OfficeBuildingIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  SpeakerphoneIcon,
  UserAddIcon,
  UserIcon,
  UserRemoveIcon,
  UsersIcon
} from '@heroicons/react/outline';
import { Auth0Role } from '@shared/models';
import { useMembership } from 'apps/a2p-portal/src/contexts/MembershipContext';
import { SidebarContext } from 'apps/a2p-portal/src/contexts/SidebarContext';
import useRoles from 'apps/a2p-portal/src/hooks/useRoles';
import { getAllowedSubDomains, getUserMemberships, logout } from 'apps/a2p-portal/src/providers/auth0.provider';
import {
  getSubDomain,
  getSubDomainLabel,
  goToSubDomain
} from 'apps/a2p-portal/src/providers/sub-domain.service';
import { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IDropdownValue } from '../Form/Dropdown';
import SubDomainDropdown from '../Form/Dropdowns/SubDomainDropdown';
import { JourneysIcon } from '../Icons/JourneysIcon';
import Festive from '../Other/Festive';
import { getFestiveAssetName, getFestiveStyle } from '../Other/types';
import Shimmer, { ShimmerType } from '../Shimmer';

interface INavItem {
  name: string;
  icon?: any; // (props: SVGProps<SVGSVGElement>) => JSX.Element;
  href?: string;
  children?: INavItem[];
}

const LeftNavContent = (): JSX.Element => {
  const location = useLocation();
  const { devMode, sidebarOpen, setDevMode, setSidebarOpen, activeUser } = useContext(SidebarContext);
  const { logout: auth0Logout, user: auth0User, isLoading } = useAuth0();
  const { isLoaded, membership } = useMembership();
  const subDomain = getSubDomain();
  const [subDomainDropdownValue, setSubDomainDropdownValue] = useState({
    label: getSubDomainLabel(subDomain),
    value: subDomain,
  } as IDropdownValue);
  const adminControlsAllowed = useRoles([Auth0Role.A2P_ADMIN, Auth0Role.A2P_SUPER_ADMIN]);
  const usersAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const tcrCampaignsAllowed = useRoles([Auth0Role.A2P_ADMIN]);
  const journeysAllowed = useRoles([Auth0Role.A2P_SUPER_ADMIN]);
  const allCampaignsAllowed = useRoles([Auth0Role.A2P_SUPER_ADMIN, Auth0Role.A2P_ADMIN]);
  const [showVersion, setShowVersion] = useState(false);

  const getNavbarItems = (): INavItem[] => {
    const navigation: INavItem[] = [
      ...allCampaignsAllowed ? [{
        name: 'All Campaigns',
        href: '/all-campaigns',
        icon: SpeakerphoneIcon,
      }] : [],
      {
        name: 'Campaigns',
        href: '/campaigns',
        icon: SpeakerphoneIcon,
      },
      ...journeysAllowed ? [{
        name: 'Journeys',
        href: '/journeys',
        icon: JourneysIcon
      }] : [],
      {
        name: 'Contacts',
        href: '/contacts',
        icon: UsersIcon,
      },
      {
        name: 'Clients',
        href: '/clients',
        icon: OfficeBuildingIcon,
      },
      {
        name: 'Test Numbers',
        href: '/test-numbers',
        icon: BeakerIcon
      },
      {
        name: 'Segments',
        icon: ChartPieIcon,
        children: [
          {
            name: 'Audiences',
            href: '/audiences',
            icon: UserAddIcon,
          },
          {
            name: 'Suppressions',
            href: '/suppressions',
            icon: UserRemoveIcon,
          },
          {
            name: 'Custom Data Points',
            href: '/custom-data-points',
            icon: IdentificationIcon,
          },
        ],
      },
    ];

    if (adminControlsAllowed) {
      const adminControls = [
        {
          name: 'System Numbers',
          href: '/system-numbers',
          icon: HashtagIcon,
        },
      ];
      if (usersAllowed) {
        adminControls.push({
          name: 'Users',
          href: '/users',
          icon: UsersIcon,
        });
      }
      if (tcrCampaignsAllowed) {
        adminControls.push({
          name: 'TCR Campaigns',
          href: '/tcr-campaigns',
          icon: CollectionIcon,
        });
      }
      navigation.push({
        name: 'Admin Controls',
        icon: ShieldCheckIcon,
        children: adminControls,
      });
    }

    return navigation;
  };

  const getFullName = (user?: User) => {
    const firstName = activeUser?.firstName ?? user?.given_name ?? user?.app_metadata?.firstName;
    const lastName = activeUser?.lastName ?? user?.family_name ?? user?.app_metadata?.lastName;
    return [firstName?.trim(), lastName?.trim()].filter((n) => n).join(' ');
  };

  const navItems: INavItem[] = getNavbarItems();

  return (
    <div
      id="nav-content"
      className={`flex flex-col flex-grow sm:pt-5 pb-4 overflow-hidden bg-slate-700 dark:bg-slate-800 ${devMode ? getFestiveStyle() : 'bg-gray-100'
        }`}
    >
      {devMode && <Festive />}
      {/* Icon here to collapse */}
      <div className="flex px-2">
        <button
          type="button"
          className="inline-flex p-2 text-white rounded-md hover:bg-gray-100 hover:text-sky-800 dark:hover:text-slate-900"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </button>
        <div className="inline-flex items-center flex-shrink-0 pl-6 pr-3">
          <img
            id="a2p-logo"
            className="w-auto h-10"
            src={`/${getFestiveAssetName()}`}
            alt="Iris Logo"
            onAuxClick={event => event?.button === 1 && setDevMode(!devMode)}
            onDoubleClick={() => {
              setShowVersion(!showVersion);
            }}
          />
        </div>
        {showVersion && <div className='text-white inline-flex items-center'>{`v${membership?.version ?? 'N/A'}`}</div>}
      </div>
      <div className="flex flex-col flex-grow mt-5">
        <nav
          className={`flex-1 px-2 space-y-1 bg-slate-700 dark:bg-slate-800 ${devMode ? getFestiveStyle() : 'bg-gray-100'
            }`}
          aria-label="Sidebar"
        >
          {!isLoaded ? (
            <Shimmer type={ShimmerType.LIST} rows={5} />
          ) : (
            <>
              {(getAllowedSubDomains(getUserMemberships(auth0User)).length ?? 0) > 1 && (
                <SubDomainDropdown
                  auth0User={auth0User}
                  value={subDomainDropdownValue}
                  onChange={(v) => {
                    if (!!v) {
                      setSubDomainDropdownValue(v!);
                      goToSubDomain(v.value);
                    }
                  }}
                />
              )}
              {navItems.map((item) =>
                !item.children ? (
                  <Link
                    id={`nav-${item.name.toLocaleLowerCase().split(' ').join('-')}`}
                    key={item.name}
                    to={item.href || '/'}
                    className={`link-primary
                            ${location.pathname.includes(item.href ?? 'undefined') ? 'link-active' : 'link-hover'}
                          `}
                  >
                    <item.icon
                      className={`icon-nav
                              ${item.href === location.pathname && 'icon-nav-selected'}
                            `}
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ) : (
                  <Disclosure as="div" key={item.name} className="space-y-1">
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`link-primary
                            ${item.href === location.pathname ? 'link-active' : 'link-hover'}
                          `}
                        >
                          <item.icon
                            className={`icon-nav
                              ${item.href === location.pathname && 'icon-nav-selected'}
                            `}
                            aria-hidden="true"
                          />
                          <div className="flex w-full">
                            <span>{item.name}</span>
                            <svg
                              className={`
                          ${open && 'rotate-90'
                                } ml-3 flex-shrink-0 h-5 w-5 transform transition-colors ease-in-out duration-150 `}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                            >
                              <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                            </svg>
                          </div>
                        </Disclosure.Button>
                        <Disclosure.Panel className="space-y-1">
                          {item.children?.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href || '/'}
                              className={`group link-group 
                            ${subItem.href === location.pathname ? 'link-active' : 'link-hover'}
                          `}
                            >
                              <subItem.icon
                                className={`icon-nav
                              ${subItem.href === location.pathname && 'nav-icon-selected'}
                            `}
                                aria-hidden="true"
                              />
                              {subItem.name}
                            </Link>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                )
              )}
            </>
          )}
        </nav>
      </div>
      <div className="flex flex-col flex-shrink-0 px-2 text-white">
        {getFullName(auth0User).length > 0 && (
          <div className="flex items-center flex-shrink-0 block w-full px-2 py-2 overflow-x-hidden overflow-y-hidden rounded-md select-none group">
            <div>
              <UserIcon className="w-6 h-6" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">{`${getFullName(auth0User)}`}</p>
            </div>
          </div>
        )}
        <button
          onClick={() => window.open('https://iristexts.freshdesk.com/support/home', '_blank')}
          className="link-primary link-hover"
        >
          <div className="flex items-center">
            <div>
              <QuestionMarkCircleIcon className="w-6 h-6 " aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium">Support</p>
            </div>
          </div>
        </button>
        <button
          className="link-primary link-hover"
          onClick={() => {
            logout({ auth0Logout, auth0User });
          }}
        >
          <div className="flex items-center">
            <div>
              <LogoutIcon className="w-6 h-6 " aria-hidden="true" />
            </div>
            <div className="ml-3 ">
              <p className="text-sm font-medium ">Sign Out</p>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default LeftNavContent;
