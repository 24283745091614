import { useAuth0 } from '@auth0/auth0-react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { isAdmin } from '../../providers/auth0.provider';
import { getCsvHeaders } from '../../providers/validation.provider';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import Checkbox from '../shared/Form/Checkbox';
import { FileUpload } from '../shared/Form/FileUpload';
import LoadingIndicator from '../shared/LoadingIndicator';

export interface IOptOutUploadForm {
  optouts: any;
  isGlobal: boolean | undefined;
}

const initialFormState: IOptOutUploadForm = {
  optouts: undefined,
  isGlobal: false,
};

interface IUploadOptOutFormProps {
  loading: boolean;
  onSubmit: (formData: IOptOutUploadForm) => Promise<void>;
}

const UploadOptOutForm = ({ loading, onSubmit }: IUploadOptOutFormProps) => {
  const { user } = useAuth0();

  const validateCsvHeaders = async (file: File): Promise<boolean> => {
    try {
      const headers = await getCsvHeaders(file);

      return headers?.length >= 1
        && headers?.length <= 5
        && !!headers?.find(header => header.toLowerCase() === 'phonenumber');
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const schema: yup.SchemaOf<IOptOutUploadForm> = yup.object().shape({
    optouts: yup
      .mixed()
      .required('Required')
      .test('csvValidate', 'Uploaded file must be .csv', (file: File): boolean => {
        return file ? file.name?.endsWith('.csv') : false;
      })
      .test('csvHeaderValidate', 'CSV does not contain the proper headers', async (file: File): Promise<boolean> => {
        return file ? await validateCsvHeaders(file) : false;
      }),
    isGlobal: yup.boolean().optional(),
  });

  return (
    <Formik
      initialValues={initialFormState}
      validationSchema={schema}
      onSubmit={(values) => {
        onSubmit && onSubmit(values);
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue, setFieldTouched, handleChange }) => (
        <form id="upload-opt-out" onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-2">
            <FileUpload
              id="optout-upload-file-selector"
              name="optouts"
              label="Opt-outs"
              placeholder="Upload a .csv file"
              value={values.optouts}
              error={touched.optouts && (errors.optouts as any)}
              onBlur={handleBlur}
              onChange={(fileUploadEvent: any) => {
                const file = fileUploadEvent.target.files[0];
                setFieldTouched('optouts');
                setFieldValue('optouts', file);
              }}
            />

            {isAdmin(user) && (
              <Checkbox
                id="isGlobal"
                name="isGlobal"
                label="Global"
                disabled={!isAdmin(user)}
                checked={values.isGlobal}
                onChange={handleChange}
              />
            )}

            <div className="flex flex-col mt-2">
              <div className="text-sm text-gray-500 py-2">Each row must have a 10-digit number. eg "5552224444".</div>
              <div className="text-sm text-gray-500">
                Download the template{' '}
                <Link
                  className="font-semibold text-sky-500 hover:underline"
                  to="/sample_upload_optouts.csv"
                  target="_blank"
                  download
                >
                  here
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <Button
              id="optout-upload-form"
              className="ml-auto"
              type="submit"
              variant={ButtonVariantEnum.SECONDARY}
              disabled={loading}
            >
              {loading ? <LoadingIndicator position="CENTER" /> : 'Upload'}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UploadOptOutForm;
