export const DropdownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7.56165L1.95652 5.60512L7.5 11.1486L13.0435 5.60512L15 7.56165L7.5 15.0616L0 7.56165Z"
      fill="#C1C1C1"
    />
  </svg>
);
