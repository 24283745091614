import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { ISearchRequest } from '@shared/models';
import { isEqual } from 'lodash';
import { Fragment, useEffect, useRef, useState } from 'react';
import { DropdownIcon } from '../../Icons/DropdownIcon';
import LoadingIndicator from '../../LoadingIndicator';
import { ErrorMessage } from '../ErrorMessage';

interface IDropdownCommonProps {
  error?: string;
  top?: boolean;
  showError?: boolean;
  label?: string;
  disabled?: boolean;
  loading?: boolean;
  onBlur?: Function;
  options?: IDropdownValue[];
  placeholder?: string;
  searchOptions?: ISearchRequest;
  refreshOptions?: (searchvalue: string) => Promise<void>;
  debounceTime?: number;
  full?: boolean;
  search?: boolean;
  labelColor?: string;
}

type IDropdownValueProps =
  | {
    multiple?: false;
    selectAll?: false;
    value?: IDropdownValue | undefined;
    onChange: (selectedValue: IDropdownValue | undefined) => void;
  }
  | {
    multiple: true;
    selectAll?: boolean;
    value?: (IDropdownValue | undefined)[];
    onChange: (selectedValue: IDropdownValue[] | undefined) => void;
  };

export interface IDropdownValue {
  label: string;
  value: any;
  additionalData?: any;
}

type IDropdownProps = IDropdownCommonProps & IDropdownValueProps;

const NewAutocomplete = ({
  options,
  value: selectedOption,
  label,
  disabled,
  loading,
  onChange,
  placeholder,
  error,
  showError,
  onBlur,
  refreshOptions,
  debounceTime,
  multiple = false,
  selectAll = false,
  top = false,
  search = true,
  full,
  labelColor,
}: IDropdownProps): JSX.Element => {
  const debounce = useRef<any>();
  const [query, setQuery] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [_options, setOptions] = useState(options ?? []);
  useEffect(() => {
    if (!!refreshOptions) {
      if (query.length > 1) {
        clearTimeout(debounce.current);
        debounce.current = setTimeout(async () => {
          await refreshOptions(query); // Wait for the query, we don't want to show old options
          setShowOptions(true);
        }, debounceTime ?? 300);
      }
    } else {
      setOptions(() =>
        !!options
          ? options.filter((option) =>
            option.label.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, ''))
          )
          : []
      );
    }
  }, [query]);

  useEffect(() => {
    if (!refreshOptions && _options!.length > 0 && !isEqual(options, _options) && query.length <= 0) {
      setOptions(options!);
    }
  }, [options]);

  const removeOption = (id: string) => {
    const change = onChange as (selectedValue: IDropdownValue[] | undefined) => void;
    change((selectedOption as IDropdownValue[])?.filter((option) => option.value !== id));
  };

  const onChangeSelectAll = (selectedValue: IDropdownValue[] | undefined) => {
    if (selectedValue?.find((value) => value.value === 'select-all')) {
      _onChange(options);
    } else {
      _onChange(selectedValue);
    }
  };

  const _onChange = (selectedValue: IDropdownValue[] | undefined) => {
    const change = onChange as (selectedValue: IDropdownValue[] | undefined) => void;
    setQuery('');
    change(selectedValue);
    setShowOptions(false);
  };

  const _onBlur = () => {
    if ((!selectedOption || multiple) && !!refreshOptions) {
      setQuery('');
    }
    onBlur && onBlur();
  };

  return (
    <div onBlur={_onBlur} className={`${full ? 'w-full' : ''}`}>
      <>
        <Combobox
          value={selectedOption}
          onChange={selectAll ? onChangeSelectAll : _onChange}
          multiple={multiple}
          disabled={disabled}
        >
          {({ open }) => (
            <>
              {!!label && (
                <Combobox.Label as="h5" className={`uppercase mb-5 ${labelColor ?? 'text-medium-gray'}`}>
                  {label}
                </Combobox.Label>
              )}
              <div className="relative">
                <div
                  className={`${!search && 'cursor-pointer'} flex relative flex-wrap gap-2 w-full pr-10 text-left ${disabled ? 'bg-gray-100 dark:bg-slate-900' : 'bg-white dark:bg-slate-700'
                    } rounded-md shadow-sm focus:outline-none sm:text-sm`}
                >
                  {multiple && (selectedOption as IDropdownValue[])?.length > 0 && (
                    <span className="flex flex-wrap gap-2">
                      {(selectedOption as IDropdownValue[]).map((option) => {
                        return (
                          <span
                            key={option?.value}
                            className={`flex items-center gap-1 rounded ${disabled ? 'border border-slate-100' : ''
                              } bg-blue-50 dark:bg-slate-800 dark:border-slate-800 px-2 py-0.5 `}
                          >
                            <span>{option?.label}</span>
                            {!disabled && (
                              <XIcon
                                onClick={() => {
                                  removeOption(option?.value);
                                }}
                                className="w-4 h-4 cursor-pointer"
                              />
                            )}
                          </span>
                        );
                      })}
                    </span>
                  )}
                  <Combobox.Input
                    className={`${!search && 'cursor-pointer'
                      } input pr-0 focus:shadow-none hover:shadow-none active:shadow-none ${disabled ? 'bg-gray-100' : 'bg-white'
                      }`}
                    displayValue={(option: IDropdownValue) => {
                      if (!multiple) {
                        return option?.label;
                      } else {
                        return query;
                      }
                    }}
                    onChange={(event) => {
                      setQuery(event.target.value);
                    }}
                    placeholder={placeholder ?? (!!refreshOptions ? `Search for a ${label}` : 'Select an option')}
                    onBlur={(event: any) => {
                      if (event.target.value === '' && !multiple) {
                        onChange(undefined);
                      }
                      // Did we click the button or an option to blur this? If so, don't call blur or the button will open it again
                      if (event.relatedTarget?.id?.includes('headlessui-combobox-button')) {
                        return;
                      }
                      if (event.relatedTarget?.id?.includes('headlessui-combobox-option')) {
                        return;
                      }
                      setShowOptions(false);
                    }}
                    onFocus={(e: any) => {
                      if (!refreshOptions && !showOptions) {
                        setShowOptions(true);
                      }
                    }}
                    onClick={(e: any) => {
                      if (!refreshOptions && !showOptions) {
                        setShowOptions(true);
                      }
                    }}
                    autoComplete={'off'} // https://bit.ly/3Jw8xGn
                    readOnly={loading || !search}
                    disabled={!search}
                  />
                  {loading ? (
                    <div className="flex items-center">
                      <LoadingIndicator />
                    </div>
                  ) : (
                    !refreshOptions && (
                      <Combobox.Button
                        onClick={(e) => {
                          e.preventDefault(); // Headless clicks the button a bunch. Stop them!
                          setShowOptions((prevState) => {
                            return !prevState;
                          });
                        }}
                        className="absolute inset-y-0 right-0 flex items-center pl-3 pr-4"
                      >
                        <DropdownIcon />
                      </Combobox.Button>
                    )
                  )}
                </div>
                {((options?.length ?? 0) > 0 || _options.length > 0) && (
                  <Transition
                    show={showOptions && !disabled}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Combobox.Options
                      static
                      className={`absolute z-20 w-full py-1 mt-1 overflow-auto ${top && 'mb-1 bottom-full'
                        } text-base bg-white dark:bg-slate-700 dark:text-slate-400 rounded-md shadow-lg max-h-60 ring-black ring-opacity-5 focus:outline-none sm:text-sm`}
                    >
                      {selectAll && (
                        <Combobox.Option
                          key={'select-all'}
                          className={({ active }) => `cursor-pointer select-none relative py-2 pl-3 pr-9 ${active ? 'text-white bg-sky-600 dark:bg-slate-800' : 'text-gray-900 dark:text-slate-400'
                            } 
                    `}
                          value={{ label: 'Select All', value: 'select-all' }}
                        >
                          {({ selected, active }) => (
                            <>
                              <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                {'Select All'}
                              </span>

                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-sky-600'
                                    }`}
                                >
                                  <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Combobox.Option>
                      )}
                      {(!!refreshOptions || (_options.length === 0 && options!.length > 0) ? options! : _options)
                        .filter((option) =>
                          Array.isArray(selectedOption)
                            ? !selectedOption.some((selectedOption) => selectedOption?.value === option?.value)
                            : true
                        )
                        .map((option) => (
                          <Combobox.Option
                            key={option.value}
                            className={({ active }) => `cursor-pointer select-none relative py-2 pl-3 pr-9 ${active ? 'text-white dark:bg-slate-800 bg-sky-600' : 'text-gray-900 dark:text-slate-400'
                              } 
                    `}
                            value={option}
                          >
                            {({ selected, active }) => (
                              <>
                                <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>
                                  {option.label}
                                </span>

                                {selected ? (
                                  <span
                                    className={`absolute inset-y-0 right-0 flex items-center pr-4 ${active ? 'text-white' : 'text-sky-600'
                                      }`}
                                  >
                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                    </Combobox.Options>
                  </Transition>
                )}
              </div>
            </>
          )}
        </Combobox>
        <ErrorMessage show={showError ?? false} message={error} />
      </>
    </div>
  );
};

export default NewAutocomplete;
