import { useAudiences } from 'apps/a2p-portal/src/hooks/useAudiences';
import { IDropdownValue } from '../Dropdown';
import NewAutocomplete from './NewAutocomplete';

interface IAudienceDropdownProps {
  value: IDropdownValue[] | IDropdownValue | undefined;
  onChange: (newValue: IDropdownValue[] | IDropdownValue | undefined) => void;
  onBlur: () => void;
  disabled?: boolean;
  errorMessage?: string | undefined;
  showError?: boolean;
  multiple?: boolean;
}

const AudienceDropdown = ({
  value,
  onChange,
  onBlur,
  disabled,
  errorMessage,
  showError,
  multiple
}: IAudienceDropdownProps): JSX.Element => {
  const [{ data: audienceData, loading: audienceLoading, error: audienceError }] = useAudiences();

  const audiences: IDropdownValue[] =
    audienceData?.records.map((audience) => ({
      label: audience.name ?? '',
      value: audience.id ?? '',
    })) ?? [];

  return (
    <NewAutocomplete
      label="Audience"
      value={value as any}
      options={audiences}
      onChange={onChange}
      onBlur={onBlur}
      showError={showError}
      error={errorMessage}
      disabled={disabled}
      multiple={multiple}
    />
  );
};

export default AudienceDropdown; ``;
