import { useClientContext } from 'apps/a2p-portal/src/contexts/ClientContext';
import { IDropdownValue } from '../Form/Dropdown';
import ClientDropdown from '../Form/Dropdowns/ClientDropdown';
import { UserGroupIcon } from '@heroicons/react/solid';

export const Header = () => {
  const { selectedClient, setClient } = useClientContext();
  return (
    <div className="flex px-8 items-center justify-end h-14 shadow-[inset_0_0_10px_0_rgba(0,0,0,0.05)] bg-slate-700">
      <ClientDropdown
        value={selectedClient as IDropdownValue}
        onChange={(newValue) => {
          setClient(newValue as IDropdownValue);
        }}
        placeholder="Select a Client"
        headIcon={<UserGroupIcon />}
      />
    </div>
  );
};
