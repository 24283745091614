export const EndJourney = () => <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" viewBox="0 0 35 30" fill="none">
  <g clipPath="url(#clip0_245_7908)">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.2865 19.3238C27.1293 19.3238 25.2691 20.5363 24.3781 22.3055C24.0576 22.9385 23.448 23.4418 22.7211 23.4418H10.8408C6.14336 23.3808 2.35261 19.6517 2.35261 15.0534C2.35261 10.455 6.14336 6.71833 10.8486 6.66495H18.8443V4.3772H10.8486C4.85373 4.43058 0.0078125 9.18909 0.0078125 15.0534C0.0078125 20.9176 4.84591 25.6685 10.8408 25.7295H22.6429C23.3854 25.7295 24.0029 26.2557 24.3155 26.9115C25.1831 28.7341 27.0824 30 29.2787 30C32.3035 30 34.7499 27.6131 34.7499 24.6619C34.7499 21.7107 32.3035 19.3238 29.2787 19.3238H29.2865ZM29.2865 27.7122C27.5592 27.7122 26.1601 26.3472 26.1601 24.6619C26.1601 22.9766 27.5592 21.6116 29.2865 21.6116C31.0138 21.6116 32.4129 22.9766 32.4129 24.6619C32.4129 26.3472 31.0138 27.7122 29.2865 27.7122Z" fill="#2B70EE" />
    <path d="M23.3748 -0.00220343L21.7168 1.61548L33.334 12.95L34.992 11.3324L23.3748 -0.00220343Z" fill="#2B70EE" />
    <path d="M33.3389 -0.00753086L21.7217 11.327L23.3797 12.9447L34.9969 1.61015L33.3389 -0.00753086Z" fill="#2B70EE" />
  </g>
  <defs>
    <clipPath id="clip0_245_7908">
      <rect width="35" height="30" fill="white" />
    </clipPath>
  </defs>
</svg>;