interface ISliderProps {
  label?: string;
  checked: boolean;
  onChange: () => void;
  id: string;
  name: string;
  disabled?: boolean;
}

export const Slider = ({ id, name, label, checked, onChange, disabled }: ISliderProps) => {
  return (
    <div className="flex">
      <div className="flex items-center flex-shrink-0 mr-2">{label}: </div>
      <label className="switch">
        <input id={id} name={name} type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
        <div className="slider round"></div>
        <div className="absolute left-15">{checked ? 'Enabled' : 'Disabled'}</div>
      </label>
    </div>
  );
};
