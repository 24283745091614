import { GetSuppressionContactCountRequest, ICreateSuppressionRequest, IHttpResponse, ISuppressionEntity, IUpdateSuppressionRequest, SocketName, SuppressionContactCountSocketData } from '@shared/models';
import { isFinite } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../../authAxios';
import { useClientContext } from '../../contexts/ClientContext';
import { SocketContext } from '../../contexts/SocketContext';
import { useCustomDataPoints } from '../../hooks/useCustomDataPoints';
import { useSuppressions } from '../../hooks/useSuppressions';
import { formatBackendMongoDBQuery } from '../../providers/mongodb.provider';
import { SocketSubscription } from '../../providers/socket.provider';
import { Button } from '../shared/Buttons/Button';
import LoadingIndicator from '../shared/LoadingIndicator';
import { Table } from '../shared/Table/Table';
import { SuppressionDetailPanel } from './SuppressionDetailPanel';
import { ISuppressionForm, defaultSuppressionTableOptions, getColumns } from './types';

export const Suppressions = () => {
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ISuppressionEntity>();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const { selectedClientId } = useClientContext();
  const [loadingIds, setLoadingIds] = useState<string[]>([]);
  const [tableOptions, setTableOptions] = useState(defaultSuppressionTableOptions);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [{ data: suppressions, loading }, getSuppressions] = useSuppressions(tableOptions);
  const [{ data: customDataPoints }, getCustomDataPoints] = useCustomDataPoints({ skip: 0, take: 10000, isActive: true });

  const socketManager = useContext(SocketContext);

  const closeDetailsPanel = () => {
    setShowDetailsPanel(false);
    setSelectedItem(undefined);
  };

  const handleSubmit = async (formData: ISuppressionForm) => {
    setLoadingSubmit(true);
    const customDataPointRecords = customDataPoints?.records ?? [];
    try {
      let response;
      if (!!selectedItem && selectedItem.id) {
        const createRequest: ICreateSuppressionRequest = {
          clientId: selectedClientId ?? '',
          name: formData.name,
          frontendFilter: formData.frontendFilter,
          backendFilter: formatBackendMongoDBQuery(formData.frontendFilter, customDataPointRecords),
        };

        response = await axiosPut(`/suppressions/${selectedItem.id}`, createRequest);
      } else {
        const updateRequest: IUpdateSuppressionRequest = {
          clientId: selectedClientId ?? '',
          name: formData.name,
          frontendFilter: formData.frontendFilter,
          backendFilter: formatBackendMongoDBQuery(formData.frontendFilter, customDataPointRecords),
        };

        response = await axiosPost('/suppressions', updateRequest);
      }
      if (response?.status === 200) {
        closeDetailsPanel();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingSubmit(false);
      handleRefetch();
    }
  };

  const columns = getColumns(
    {
      count: (item: ISuppressionEntity) => loadingIds?.includes(item?.id) ? <LoadingIndicator /> : (item.count ? item.count : '-'),
      frontendFilter: (item: any) => JSON.stringify(item.filters),
    },
    {
      name: (item: ISuppressionEntity) => {
        setSelectedItem(item);
        setShowDetailsPanel(true);
      },
    },
    [{
      label: 'Calculate Volume',
      action: async (row: ISuppressionEntity) => {
        await calculateContactCount(row);
      }
    }]
  );

  const handleDelete = async () => {
    setDeleteLoading(true);
    try {
      const response = await axiosDelete(`/suppressions/${selectedItem?.id}`);
      if (response?.status === 200 && response?.data) {
        closeDetailsPanel();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setDeleteLoading(false);
      handleRefetch();
    }
  };

  const calculateContactCount = async (row: ISuppressionEntity) => {
    try {
      const record = getRecordById(row.id);

      if (record) {
        record.isCalculating = true;
        addLoadingId(record.id);
      }

      const request: GetSuppressionContactCountRequest = {
        clientId: selectedClientId!,
        suppressionId: row?.id ?? ''
      };

      socketManager?.sendMessage<GetSuppressionContactCountRequest>(SocketName.SUPPRESSION_CONTACT_COUNT, request);
    } catch (error) {
      console.log(error);
    }
  };

  const updateContactCount: SocketSubscription<SuppressionContactCountSocketData> = async payload => {
    const data = payload?.data?.suppression;
    const record = getRecordById(data?.id);

    if (record) {
      record.count = isFinite(data?.count) ? data?.count! : NaN;
      record.isCalculating = false;
      removeLoadingId(record.id);
    }
  };

  const getRecordById = (id?: string) => {
    return id
      ? suppressions?.records?.find(record => record.id === id)
      : undefined;
  };

  useEffect(() => {
    const unsubscribeContactCount = socketManager!.subscribe(SocketName.SUPPRESSION_CONTACT_COUNT, updateContactCount);

    return () => {
      unsubscribeContactCount();
    };
  }, []);

  const handleRefetch = async () => {
    try {
      setTableOptions({ ...(tableOptions ?? {}), clientId: selectedClientId });
      await getSuppressions();
    } catch (error) { }
  };

  useEffect(() => {
    addAllLoadingIds();

    const interval = setInterval(async () => {
      const calculatingSuppressions = suppressions?.records?.filter(record => record.isCalculating) ?? [];
      addAllLoadingIds();

      for (const suppression of calculatingSuppressions) {
        refreshSuppression(suppression);
      }
    }, 15_000);

    return () => clearInterval(interval);
  }, [suppressions]);

  const refreshSuppression = async (suppression: ISuppressionEntity) => {
    try {
      const record = suppressions?.records?.find(record => record.id === suppression.id);

      if (record) {
        const response = await axiosGet<IHttpResponse<ISuppressionEntity>>(`/suppressions/${record.id}`);
        const suppression = response?.data;

        if (suppression) {
          Object.assign(record, suppression);
        }

        if (!suppression?.isCalculating) {
          removeLoadingId(suppression.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addLoadingId = (id: string) => {
    setLoadingIds(ids => [...ids, id]);
  };

  const removeLoadingId = (id: string) => {
    setLoadingIds(ids => ids.filter(x => x !== id));
  };

  const addAllLoadingIds = () => {
    setLoadingIds(suppressions?.records?.filter(s => s.isCalculating)?.map(s => s?.id)?.filter(v => !!v) ?? []);
  };

  return (
    <>
      <h2 className="header-page">
        Suppressions
        <Button onClick={() => setShowDetailsPanel(true)}>Add Suppression</Button>
      </h2>
      <Table
        items={suppressions?.records}
        count={suppressions?.totalCount}
        columns={columns}
        loading={loading}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={(request) => setTableOptions(request)}
        paginate
        onRefresh={handleRefetch}
      />
      <SuppressionDetailPanel
        show={showDetailsPanel}
        onClosePanel={closeDetailsPanel}
        selectedItem={selectedItem}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        loading={loadingSubmit}
        deleteLoading={deleteLoading}
      />
    </>
  );
};
