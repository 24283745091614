import { Auth0OrganizationAppID, Auth0OrganizationID, Auth0OrganizationName } from '@shared/models';

export enum SubDomain {
  LOCAL = 'localhost:4200',
  A2P_DEV = 'dev',
  A2P_DEV2 = 'dev2',
  A2P_STAGING = 'staging',
  A2P_STAGING2 = 'staging2',
  A2P_DEMO = 'demo',
  A2P_TV = 'targetedvictory'
}

export interface Auth0Config {
  label: string;
  clientId: Auth0OrganizationAppID;
  organization: Auth0OrganizationName;
  organizationId: Auth0OrganizationID;
  apiUrl: string;
  websocketUrl: string;
  redirectUrl: string;
}

export function getSubDomainFromOrg(organization?: Auth0OrganizationName) {
  return Object.values(SubDomain).find((subDomain) => organization?.endsWith(subDomain)) ?? undefined;
}

export function getOrganizationLabel(organization: Auth0OrganizationName) {
  return getConfigByDomain(getSubDomainFromOrg(organization)).label;
}

export function getSubDomainLabel(domain?: SubDomain) {
  return getConfigByDomain(domain).label;
}

export function getAuth0Organization() {
  return getConfigByDomain().organization;
}

export function getAuth0Config() {
  return getConfigByDomain();
}

export function getWebSocketUrl() {
  return getConfigByDomain().websocketUrl;
}

export function getApiUrl() {
  return getConfigByDomain().apiUrl;
}

export function goToSubDomain(domain: SubDomain) {
  const url = Auth0ConfigMap.get(domain)?.redirectUrl;
  window.location.replace(url + window?.location?.pathname);
}

export function getSubDomain() {
  return <SubDomain>window?.location?.host?.split('.')?.[0];
}

function getConfigByDomain(domain?: SubDomain) {
  return <Auth0Config>Auth0ConfigMap.get(domain ?? getSubDomain());
}

// TODO: Move this to Config DB
const Auth0ConfigMap = createAuth0ConfigMap();

function createAuth0ConfigMap() {
  const map: Map<SubDomain, Auth0Config> = new Map();

  map.set(SubDomain.A2P_DEV, {
    label: 'DEV',
    clientId: Auth0OrganizationAppID.A2P_DEV,
    organization: Auth0OrganizationName.A2P_DEV,
    organizationId: Auth0OrganizationID.A2P_DEV,
    apiUrl: `https://api.dev.iristexts.com`,
    websocketUrl: 'wss://ws.dev.iristexts.com',
    redirectUrl: `https://dev.iristexts.com`,
  });

  map.set(SubDomain.A2P_DEV2, {
    label: 'DEV2',
    clientId: Auth0OrganizationAppID.A2P_DEV,
    organization: Auth0OrganizationName.A2P_DEV2,
    organizationId: Auth0OrganizationID.A2P_DEV2,
    apiUrl: `https://api.dev.iristexts.com`,
    websocketUrl: 'wss://ws.dev.iristexts.com',
    redirectUrl: `https://dev2.iristexts.com`,
  });

  map.set(SubDomain.A2P_STAGING, {
    label: 'STAGING',
    clientId: Auth0OrganizationAppID.A2P_STAGING,
    organization: Auth0OrganizationName.A2P_STAGING,
    organizationId: Auth0OrganizationID.A2P_STAGING,
    apiUrl: `https://api.staging.iristexts.com`,
    websocketUrl: 'wss://ws.staging.iristexts.com',
    redirectUrl: `https://staging.iristexts.com`,
  });

  map.set(SubDomain.A2P_STAGING2, {
    label: 'STAGING2',
    clientId: Auth0OrganizationAppID.A2P_STAGING,
    organization: Auth0OrganizationName.A2P_STAGING2,
    organizationId: Auth0OrganizationID.A2P_STAGING2,
    apiUrl: `https://api.staging.iristexts.com`,
    websocketUrl: 'wss://ws.staging.iristexts.com',
    redirectUrl: `https://staging2.iristexts.com`,
  });

  map.set(SubDomain.A2P_DEMO, {
    label: 'DEMO',
    clientId: Auth0OrganizationAppID.A2P_SHARED,
    organization: Auth0OrganizationName.A2P_DEMO,
    organizationId: Auth0OrganizationID.A2P_DEMO,
    apiUrl: `https://api.shared.iristexts.com`,
    websocketUrl: 'wss://ws.shared.iristexts.com',
    redirectUrl: `https://demo.iristexts.com`,
  });

  map.set(SubDomain.A2P_TV, {
    label: 'Targeted Victory',
    clientId: Auth0OrganizationAppID.A2P_TARGETEDVICTORY,
    organization: Auth0OrganizationName.A2P_TARGETEDVICTORY,
    organizationId: Auth0OrganizationID.A2P_TARGETEDVICTORY,
    apiUrl: `https://api.targetedvictory.iristexts.com`,
    websocketUrl: 'wss://ws.targetedvictory.iristexts.com',
    redirectUrl: `https://targetedvictory.iristexts.com`,
  });

  map.set(SubDomain.LOCAL, {
    ...(<Auth0Config>map.get(SubDomain.A2P_DEV)),
    apiUrl: `http://localhost:3000/dev`,
    websocketUrl: 'ws://localhost:3001',
    redirectUrl: 'http://localhost:4200',
  });

  return map;
}
