import { ITestNumbersViewRecord } from '@shared/models';
import { DetailsPanel } from '../shared/DetailsPanel/DetailsPanel';
import { DetailsPanelSize, IButtonOptions } from '../shared/DetailsPanel/types';
import HealthCheckNumberForm from './HealthCheckNumberForm';
import { IHealthCheckNumberForm } from './types';

interface ITestNumberDetailsPanel {
  show: boolean;
  loading: boolean;
  selectedItem?: ITestNumbersViewRecord;
  leftPanelButtonOptions: IButtonOptions[];
  errorMessage?: string;
  onClosePanel: () => void;
  handleSubmit: (formData: IHealthCheckNumberForm) => Promise<void>;
}

const HealthCheckNumberDetailsPanel = ({
  show,
  loading,
  selectedItem,
  leftPanelButtonOptions,
  errorMessage,
  onClosePanel,
  handleSubmit,
}: ITestNumberDetailsPanel): JSX.Element => {
  return (
    <DetailsPanel
      item={selectedItem}
      title={selectedItem ? 'View Test Number' : 'Add Test Number'}
      formId="test-number-form"
      size={DetailsPanelSize.Medium}
      show={show}
      closePanel={onClosePanel}
      loading={loading}
      primaryButtonOptions={{ text: 'Save', visible: !selectedItem }}
      leftPanelButtonOptions={leftPanelButtonOptions}
      errorMessage={errorMessage}
    >
      <HealthCheckNumberForm item={selectedItem} onSubmit={handleSubmit} />
    </DetailsPanel>
  );
};

export default HealthCheckNumberDetailsPanel;
