import { IHttpPaginatedResponse, ISearchRequest, ISystemNumberListView } from '@shared/models';
import useAxios, { UseAxiosResult } from 'axios-hooks';

export default function useSystemNumbers(request: ISearchRequest): UseAxiosResult<IHttpPaginatedResponse<ISystemNumberListView>> {

  return useAxios<IHttpPaginatedResponse<ISystemNumberListView>>({
    url: 'system-numbers',
    method: 'GET',
    params: request
  });
}
