import { ActionWithRulesProps } from 'react-querybuilder';
import { Button } from '../../Buttons/Button';
import { ButtonVariantEnum } from '../../Buttons/types';

export const CustomRemoveButton = (props: ActionWithRulesProps) => {
  return (
    <Button className="ml-0" variant={ButtonVariantEnum.DELETE} onClick={props.handleOnClick} disabled={props.disabled}>
      X
    </Button>
  );
};
