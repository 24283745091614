export const enum S3BucketEnum {
  REPORTS = 'reports',
  EXPORTS = 'exports',
  MMS = 'mms-uploads',
  OPTOUTS = 'optouts',
  OPTOUTS_EXTERNAL = 'optout-uploads-external',
  CONTACTS = 'contact-uploads',
  CRM_CONTACTS = 'crm-contact-uploads',
  SUPPRESSIONS = 'suppression-uploads',
  LINK_ORDERS = 'link-orders',
  GENERATED_LINKS = 'generated-links',
  LINK_CLICKS = 'blink-click-data',
  ARCHIVE_S3 = 's3-bucket-archive',
  MONGODB_UPLOAD = 'mongodb-upload'
};
