import {
  Auth0Role,
  IHttpResponse,
  ISearchRequest,
  ITCRCampaignCreationRequest,
  ITCRCampaignDetails,
} from '@shared/models';
import useAxios from 'axios-hooks';
import { useEffect, useState } from 'react';
import { useClientContext } from '../../contexts/ClientContext';
import { NotificationType, useNotifications } from '../../contexts/NotificationContext';
import useProviders from '../../hooks/useProviders';
import useRoles from '../../hooks/useRoles';
import useTCRCampaigns from '../../hooks/useTCRCampaigns';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { Table } from '../shared/Table/Table';
import { IColumn } from '../shared/Table/types';
import TCRCampaignDetailsPanel from './TCRCampaignDetailsPanel';
import { ITCRCampaignForm, defaultTCRCampaignTableOptions, getColumns, tcrCampaignDropdownOptions } from './types';

const TCRCampaigns = (): JSX.Element => {
  const [selectedItem, setSelectedItem] = useState<ITCRCampaignDetails>();
  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const { addNotification } = useNotifications();
  const { selectedClientId } = useClientContext();
  const [tableOptions, setTableOptions] = useState({
    ...defaultTCRCampaignTableOptions,
    clientId: selectedClientId ?? '',
  } as ISearchRequest);

  const [{ data, loading, error }, refetch] = useTCRCampaigns(tableOptions);
  const [{ data: providerData, loading: providerLoading, error: providerError }] = useProviders();

  const canEdit = useRoles([Auth0Role.A2P_SUPER_ADMIN]);

  const [{ data: createData, loading: createLoading, error: createError }, createTCRCampaign] = useAxios<
    IHttpResponse<void>
  >(
    {
      url: 'tcr-campaigns',
      method: 'POST',
    },
    { manual: true }
  );

  const [{ loading: toggleLoading }, toggleTCRCampaign] = useAxios<IHttpResponse<void>>(
    {
      url: 'tcr-campaigns-toggle',
      method: 'POST',
    },
    { manual: true }
  );

  const tcrCampaigns: ITCRCampaignDetails[] =
    data?.records.map((res) => ({
      ...res,
      activeNumbers: parseInt(res.activeNumbers! as any),
      providerName: providerData?.find(p => p.id === res.providerId)?.name ?? ''
    })) ?? [];

  const totalCount: number = data?.totalCount ?? 0;

  const providerOptions: IDropdownValue[] =
    providerData?.map((provider) => ({
      label: provider.name ?? '',
      value: provider.name ?? '',
    })) ?? [];

  tcrCampaignDropdownOptions.providerName = providerOptions;

  const handleSearchOptionChange = (searchOptions: ISearchRequest) => {
    setTableOptions(searchOptions);
  };

  useEffect(() => {
    handleRefetch();
  }, [tableOptions]);

  const columns = getColumns({
    tcrCampaignId: (item: ITCRCampaignDetails) => {
      canEdit && openDetailsPanel(item);
    },
  });

  const handleRefetch = async () => {
    try {
      await refetch();
    } catch (error) { }
  };

  const handleToggle = async () => {
    try {
      await toggleTCRCampaign({ data: { id: selectedItem?.id, isActive: !selectedItem?.isActive } });

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: `TCR Campaign ${!selectedItem?.isActive ? 'enabled' : 'disabled'} successfully.`,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openDetailsPanel = (item?: ITCRCampaignDetails) => {
    if (item) {
      setSelectedItem(item);
    }
    setShowDetailsPanel(true);
  };

  const closeDetailsPanel = () => {
    setSelectedItem(undefined);
    setShowDetailsPanel(false);
  };

  const handleSubmit = async (formData: ITCRCampaignForm): Promise<void> => {
    try {
      await createTCRCampaign({
        data: {
          clientId: selectedClientId,
          providerId: formData.providerId,
        } as ITCRCampaignCreationRequest,
      });

      await handleRefetch();

      closeDetailsPanel();

      addNotification({
        header: 'TCR Campaign created successfully!',
      });
    } catch (error) {
      console.error(error);
      addNotification({
        header: 'Error',
        content: 'An unexpected error occurred when attempting to save the TCR Campaign.',
        type: NotificationType.FAILURE,
      });
    }
  };

  const filterColumns: IColumn[] = columns.filter((col) =>
    ['clientName', 'providerName'].includes(col.fieldName)
  );

  return (
    <>
      <h2 className="pb-2 dark:text-white">TCR Campaigns</h2>

      {/* <div className="flex justify-end pb-2 space-x-2">
        <Button
          id="system-number-add"
          variant={ButtonVariantEnum.SECONDARY}
          onClick={() => {
            setShowDetailsPanel(true);
          }}
        >
          Add
        </Button>
      </div> */}

      <Table
        shimmer
        loading={loading}
        columns={columns}
        items={tcrCampaigns}
        count={totalCount}
        tableSearchOptions={tableOptions}
        onSearchOptionChange={handleSearchOptionChange}
        paginate
        filter
        filterColumns={filterColumns}
        filterDropdownOptions={tcrCampaignDropdownOptions}
        onRefresh={handleRefetch}
      />

      <TCRCampaignDetailsPanel
        show={showDetailsPanel}
        loading={createLoading}
        toggleLoading={toggleLoading}
        selectedItem={selectedItem}
        errorMessage={createError?.response?.data.message}
        onClosePanel={closeDetailsPanel}
        handleSubmit={handleSubmit}
        handleToggle={handleToggle}
      />
    </>
  );
};

export default TCRCampaigns;
