import { ContactAuditSourceEnum, EntityName, S3BucketEnum } from '@shared/enums';
import { FilterDataTypeEnum, ISearchRequest, ISignedUrlRequest, SearchSortDirectionEnum } from '@shared/models';
import { IDropdownValue } from '../shared/Form/Dropdown';
import { IFilterDropdownOptions } from '../shared/Table/FilterForm';
import { IColumn } from '../shared/Table/types';

export const defaultOptOutTableOptions: ISearchRequest = {
  entity: EntityName.ALL_OPTOUTS_VIEW,
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'optedOutDate',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

// export const columns: IColumn[] = [
export const getColumns = (columnRenders: any): IColumn[] => {
  return [
    {
      headerName: 'Phone Number',
      fieldName: 'phoneNumber',
      isRowHeader: true,
      fieldType: FilterDataTypeEnum.NUMBER,
      renderColumn: columnRenders['phoneNumber'],
    },
    {
      headerName: 'Global',
      fieldName: 'isGlobal',
      fieldType: FilterDataTypeEnum.BOOLEAN,
    },
    {
      headerName: 'Optout Date',
      fieldName: 'date',
      fieldType: FilterDataTypeEnum.DATE,
    },
  ];
};

export const optoutFilterDropdownOptions: IFilterDropdownOptions = {
  source: getSourceFilterOptions(),
  clientName: [],
};

function getSourceFilterOptions(): IDropdownValue[] {
  return Object.values(ContactAuditSourceEnum)
    .filter((source) => !Number.isInteger(source))
    .map((source) => ({
      label: getSourceLabel(source),
      value: source,
    }));
}

function getSourceLabel(source: ContactAuditSourceEnum) {
  switch (source) {
    case ContactAuditSourceEnum.LOOKUP_AUTOMATIC:
      return 'Lookup Automatic';
    case ContactAuditSourceEnum.REPLY_AUTOMATIC:
      return 'Reply Automatic';
    case ContactAuditSourceEnum.CALLBACK_AUTOMATIC:
      return 'Callback Automatic';
    case ContactAuditSourceEnum.CRM:
      return 'CRM';
    case ContactAuditSourceEnum.MANUAL:
      return 'Manual';
    case ContactAuditSourceEnum.AUTO_OPTIN:
      return 'Auto Optin';
  }
}

export const s3UrlRequest: ISignedUrlRequest = {
  bucket: S3BucketEnum.OPTOUTS,
  key: '',
  action: 'putObject',
  contentType: 'text/csv',
};
