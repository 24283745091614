import Card from '../shared/DetailsPanel/DetailsPanelCard';
import { TextInput } from '../shared/Form/TextInput';
import { TextArea } from '../shared/Form/TextArea';
import { IKeyword } from './types';
import { v4 as uuid } from 'uuid';
import { Button } from '../shared/Buttons/Button';
import { MinusIcon, PlusIcon, PlusSmIcon } from '@heroicons/react/solid';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import { KeywordTypeEnum } from '@shared/enums/keyword-type-enum';
import { ErrorMessage } from '../shared/Form/ErrorMessage';
import { FormikErrors, FormikTouched } from 'formik';
import { IClientForm } from './ClientForm';

interface IKeywordsProps {
  stopKeywords: IKeyword[] | undefined;
  joinKeywords: IKeyword[] | undefined;
  helpKeywords: IKeyword[] | undefined;
  setFieldValue: any;
  setFieldTouched: any;
  touched: FormikTouched<IClientForm>;
  errors: any;
  readOnly: boolean;
}

export const Keywords = ({ stopKeywords, joinKeywords, helpKeywords, setFieldValue, touched, errors, readOnly }: IKeywordsProps) => {
  return (
    <>
      <span className="pt-4 ml-1 text-sm font-medium text-gray-700">Keywords</span>
      <Card small title="Stop">
        {stopKeywords?.map((stop, index) => {
          return (
            <div key={index} className="flex">
              <TextInput label="Word" value={stop.word} name="word" disabled />
              <TextArea
                className="ml-4 grow"
                label="Reply"
                rows={1}
                value={stop.reply}
                name="reply"
                onChange={(newValue) => {
                  const stops = [...stopKeywords];
                  stops[index] = {
                    ...stops[index],
                    reply: newValue.target.value,
                  };
                  setFieldValue('stopKeywords', stops);
                }}
                disabled={readOnly}
              />
            </div>
          );
        })}
      </Card >
      <Card small title='Join'>
        {joinKeywords?.map((join, index) => {
          return (
            <div key={index} className="flex flex-wrap">
              <TextInput label="Word" value={join.word} name="word" disabled={join.isDefault || readOnly} onChange={(newValue) => {
                const joins = [...joinKeywords];
                joins[index] = {
                  ...joins[index],
                  word: newValue.target.value
                };
                setFieldValue('joinKeywords', joins);
              }} />
              <TextArea
                className="ml-4 grow"
                label="Reply"
                value={join.reply}
                name="reply"
                rows={1}
                onChange={(newValue) => {
                  const joins = [...joinKeywords];
                  joins[index] = {
                    ...joins[index],
                    reply: newValue.target.value,
                  };
                  setFieldValue('joinKeywords', joins);
                }}
                disabled={readOnly}
              />
              {!join.existing && <Button disabled={readOnly} type='button' className='px-2 py-1 mb-auto mt-7' variant={ButtonVariantEnum.DELETE} onClick={() => {
                const joins = joinKeywords.filter(jk => jk.id !== join.id);
                setFieldValue('joinKeywords', joins);
              }}><MinusIcon className='w-5 h-5' /></Button>}
              <div className='w-full'>
                <ErrorMessage show={(touched.joinKeywords ?? false) && !!errors.joinKeywords} message={!!errors?.joinKeywords ? errors.joinKeywords[index]?.word?.toString() : null} />
                <ErrorMessage show={(touched.joinKeywords ?? false) && !!errors.joinKeywords} message={!!errors?.joinKeywords ? errors.joinKeywords[index]?.reply?.toString() : null} />
              </div>
            </div>
          );
        })}
        <div className='flex justify-end'>
          <Button disabled={readOnly} type='button' className='px-2 py-1' variant={ButtonVariantEnum.PRIMARY} onClick={() => {
            const joins = [...(joinKeywords ?? []), {
              id: uuid(),
              type: KeywordTypeEnum.JOIN,
              word: 'join',
              reply: 'TBD',
            }];
            setFieldValue('joinKeywords', joins);
          }}><PlusIcon className='w-5 h-5' /></Button>
        </div>
      </Card>
      <Card small title="Help">
        {helpKeywords?.map((help, index) => {
          return (
            <div key={index} className="flex">
              <TextInput label="Word" value={help.word} name="word" disabled />
              <TextArea
                className="ml-4 grow"
                label="Reply"
                value={help.reply}
                name="reply"
                rows={1}
                onChange={(newValue) => {
                  const helps = [...helpKeywords];
                  helps[index] = {
                    ...helps[index],
                    reply: newValue.target.value,
                  };
                  setFieldValue('helpKeywords', helps);
                }}
                disabled={readOnly}
              />
            </div>
          );
        })}
      </Card>
    </>
  );
};
