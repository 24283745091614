import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { ICustomDataPointEntity, IUserCustomDataPointsRequest } from '@shared/models';
import { Fragment } from 'react';
import { axiosPost } from '../../authAxios';
import { Button } from '../shared/Buttons/Button';
import { ButtonVariantEnum } from '../shared/Buttons/types';
import CustomFieldsForm, { ICustomFieldsForm } from './CustomFieldsForm';

interface ICustomFieldsModalProps {
  show: boolean;
  setShow: any;
  userId: string;
  customDataPoints: ICustomDataPointEntity[];
  setCustomDataPoints: any;
  setCdpLoading: any;
}

const CustomFieldsModal = ({ show, userId, customDataPoints, setCustomDataPoints, setShow, setCdpLoading }: ICustomFieldsModalProps) => {

  const saveCustomDataPoints = (formData: ICustomFieldsForm) => {
    setCdpLoading(true);

    axiosPost(`custom-data-points-prefs`, {
      userId,
      customDataPointIds: formData.customDataPoints.map(option => option.value)
    } as IUserCustomDataPointsRequest)
      .then(savedCustomData => {
        if (savedCustomData?.data) {
          setCustomDataPoints(savedCustomData.data);
        }
      })
      .finally(() => {
        setCdpLoading(false);
      });

    // close modal immediately
    setShow(false);
  };

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75 dark:text-slate-400" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl dark:bg-slate-700 rounded-2xl">
              <Button
                className="absolute top-0 right-0 rounded-tr-2xl hover:bg-gray-100 dark:hover:bg-slate-800"
                variant={ButtonVariantEnum.TEXT_DEFAULT}
                leadingIcon={<XIcon className="w-5 h-5 text-gray-300" />}
                onClick={() => {
                  setShow(false);
                }}
              />

              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
                <span id="upload-optouts-modal-title">Customize Grid</span>
              </Dialog.Title>
              <div className="mt-2">
                <CustomFieldsForm onSubmit={saveCustomDataPoints} customDataPoints={customDataPoints} />
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CustomFieldsModal;
