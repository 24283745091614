import { ICustomDataPointEntity, IGetAllCustomDataPointsRequest, IHttpPaginatedResponse } from '@shared/models';
import useAxios from 'axios-hooks';

export function useCustomDataPoints(request?: IGetAllCustomDataPointsRequest) {
  return useAxios<IHttpPaginatedResponse<ICustomDataPointEntity>>({
    method: 'GET',
    url: `custom-data-points`,
    params: <IGetAllCustomDataPointsRequest>{
      skip: request?.skip ?? 0,
      take: request?.take ?? 25,
      personalized: request?.personalized ?? undefined,
      isActive: request?.isActive ?? undefined,
    }
  });
}
