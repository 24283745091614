import { FilterDataTypeEnum, ISearchRequest, SearchSortDirectionEnum } from '@shared/models';
import { RuleGroupTypeIC } from 'react-querybuilder';
import * as yup from 'yup';
import { IMenuItem } from '../shared/Table/Actions';
import { IColumn, IColumnClickConfig } from '../shared/Table/types';

export const getColumns = (columnRenders: any, columnClickEvents: IColumnClickConfig, actionItems: IMenuItem[]): IColumn[] => {
  return [
    {
      headerName: 'Name',
      fieldName: 'name',
      fieldType: FilterDataTypeEnum.STRING,
      isRowHeader: true,
      onColumnClick: columnClickEvents['name'],
    },
    {
      headerName: 'Contacts Count',
      fieldName: 'count',
      renderColumn: columnRenders['count'],
      fieldType: FilterDataTypeEnum.NUMBER,
      emptyValue: '-'
    },
    {
      headerName: 'Last Calculated',
      fieldName: 'lastCalculatedAt',
      fieldType: FilterDataTypeEnum.DATE,
      emptyValue: '-'
    },
    {
      headerName: '',
      fieldName: 'actions',
      fieldType: FilterDataTypeEnum.ACTIONS,
      action: {
        items: actionItems
      }
    }
  ];
};

export const defaultAudiencesTableOptions: ISearchRequest = {
  clientId: '',
  filters: [],
  pagination: {
    skip: 0,
    take: 25,
  },
  sort: {
    fieldName: 'createdAt',
    sortDirection: SearchSortDirectionEnum.DESC,
  },
};

export interface IAudienceForm {
  name: string;
  frontendFilter: RuleGroupTypeIC;
}

export const audienceFormSchema: yup.SchemaOf<IAudienceForm> = yup.object().shape({
  name: yup.string().defined('Required'),
  frontendFilter: yup.object().shape({
    path: yup.array(),
    id: yup.string(),
    disabled: yup.boolean(),
    not: yup.boolean(),
    rules: yup.array().min(1, 'Audience must have at least one rule.'),
    combinatorPreceding: yup.string(),
  }),
});
